<template>
  <div>
    <div class="app-hamburger-container">
      <div 
        class="hamburger"
        @click="mobileMenu = !mobileMenu" 
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu chat-menu d-xl-none"
        >
          <line x1="3" y1="12" x2="21" y2="12" />
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="18" x2="21" y2="18" />
        </svg>
      </div>
    </div>

    <div class="app-container">
      <div class="app-note-container">
        <div 
          class="app-note-overlay" 
          :class="{'app-note-overlay-show': mobileMenu}"
        ></div>

        <!-- Options container -->
        <div 
          class="tab-title pt-4 pt-lg-0" 
          :class="{'note-menu-show': mobileMenu}" 
          style="border: none;"
        >
          <div class="row">
            <div class="col-md-12 col-sm-12 col-12">
              <ul 
                id="pills-tab3" 
                class="nav nav-pills d-block" 
                role="tablist"
              >
                <li class="nav-item">
                  <BaseButton 
                    v-if="userAccess.allow_announcement_create"
                    class="button--success" 
                    text="Buat Pengumuman"
                    @button-click="addAnnouncement"
                  >
                    <template #icon>
                      <span class="mr-1">
                        <svg 
                          style="width:24px;height:24px" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            fill="currentColor" 
                            d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" 
                          />
                        </svg>
                      </span>
                    </template>
                  </BaseButton>
                </li>
                <li  
                  class="nav-item mt-1" 
                  :class="{'nav-item--active': params.important === 1}"
                  @click="changeImportant"
                >
                  <a 
                    id="note-fav" 
                    class="nav-link list-actions d-flex align-items-center justify-content-center" 
                    style="border: none;"
                  >
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24" 
                      class="m-0"
                    >
                      <path 
                        fill="currentColor" 
                        d="M6.5,17H15L18.5,12L15,7H6.5L10,12L6.5,17M15,19H3L7.5,12L3,5H15C15.69,5 16.23,5.3 16.64,5.86L21,12L16.64,18.14C16.23,18.7 15.69,19 15,19Z" 
                      />
                    </svg>
                    <span>Penting</span>
                  </a>
                </li>
              </ul>

              <hr />

              <p class="group-section">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-tag"
                >
                  <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"/>
                  <line x1="7" y1="7" x2="7" y2="7" />
                </svg> 
                Tags
              </p>

              <ul 
                id="pills-tab" 
                class="nav nav-pills d-block group-list" 
                role="tablist"
              >
                <li 
                  v-for="tag in availableTags"
                  :key="tag.id"
                  class="nav-item p-2 mb-1"
                  :class="{'nav-item--active': params.tag === tag.value}"
                  @click="changeTag(tag.value)"
                >
                  <div class="d-flex flex-row align-items-center">
                    <div :class="`mr-3 tags tags--${tag.value}`"></div>
                    <span class="text-small">{{ tag.label }}</span>
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <!-- Options container -->

        <!-- Notes container -->
        <div 
          id="ct" 
          class="h-100 note-container note-grid"
        >
          <template v-if="!data">
            <div 
              v-for="item in 9" 
              :key="item.id" 
              class="note-item all-notes"
            >
              <div class="h-100 w-100 d-flex flex-column">
                <PuSkeleton 
                  height="25px" 
                  class="w-100 mb-3"
                />
                <PuSkeleton 
                  height="20px" 
                  class="w-100 mb-3"
                />
                <PuSkeleton 
                  height="60px" 
                  class="w-100 mb-3"
                />
              </div>
            </div>
            <div>
              <PuSkeleton 
                height="25px" 
                class="w-100"
              />
            </div>
          </template>

          <template v-else-if="data && data.data.length">
            <div 
              v-for="item in data.data" 
              :key="item.id" 
              class="note-item all-notes position-relative"
            >
              <AnnouncementItem 
                :title="item.title" 
                :date="`${$_convertDate(item.created.split(' ')[0])} ${item.created.split(' ')[1]}`" 
                :content="content(item.content)"
                :tags="item.tag"
                :important="item.is_important"
                :published="item.is_published"
                @toggle-important="toggleImportant(item.id, item.is_important)"
                @edit-announcement="editAnnouncement(item.id)"
                @delete-item="removeAnnouncement(item.id)"
                @click.native="announcementDetail(item.id)"
              />
                <div 
                  v-if="announcementLoadingState === item.id" 
                  class="announcement-loader position-absolute w-100"
                ></div>
            </div>
            <div>
              <BasePagination
                :firstPage="pagination.firstPage"
                :previousPage="pagination.previousPage"
                :currentPage="pagination.currentPage"
                :nextPage="pagination.nextPage"
                :lastPage="pagination.lastPage"
                :totalRecords="data.recordsTotal"
                @first-page="announcementList(pagination.firstPage)"
                @previous-page="announcementList(pagination.previousPage)"
                @next-page="announcementList(pagination.nextPage)"
                @last-page="announcementList(pagination.lastPage)"
              />
            </div>
          </template>

          <template v-else-if="data && !data.data.length">
            <div class="h-100 w-100 d-flex align-items-center justify-content-center">
              <p class="font-weight-bold">Belum ada pengumuman disimpan.</p>
            </div>
          </template>
          
        </div>
        <!-- Notes container -->
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseButton from '@/components/base/BaseButton'
import BasePagination from '@/components/base/BasePagination'
import AnnouncementItem from '@/components/announcement/AnnouncementItem'

export default {
  name: 'AnnouncementContent',
  components: {
    BaseButton,
    BasePagination,
    AnnouncementItem
  },
  data() {
    return {
      mobileMenu: false,
      announcementLoadingState: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('announcement', ['params']),
    ...mapState('announcement', ['data', 'detail', 'availableTags', 'pagination'])
  },
  created() {
    this.getAnnouncement({
      page: 1,
      tag: this.params.tag,
      important: this.params.important,
      token: this.authorization
    })
    this.getAnnouncementTags(this.authorization)
  },
  methods: {
    ...mapActions('announcement', ['createAnnouncement', 'getAnnouncement', 'getAnnouncementTags', 'getAnnouncementDetail', 'patchAnnouncementImportant', 'deleteAnnouncement']),
    ...mapMutations('announcement', ['newAnnouncement', 'setData', 'setTag', 'setImportant', 'setModalEdit']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    content(value) {
        let string
        let charLength = 60

        if (value.length >= charLength) {
          string = `${value.substring(0, charLength)}...`
        } else {
          string = value
        }
        return string
    },
    addAnnouncement() {
      this.newAnnouncement()
      this.setModalEdit(true)
      this.TOGGLE_MODAL()
    },
    announcementList(page) {
      this.getAnnouncement({
        page: page,
        tag: this.params.tag,
        important: this.params.important,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behavior: 'smooth' }))
    },
    changeTag(tag) {
      this.setTag(tag)
      this.announcementList(1)
    },
    toggleImportant(id, importantState) {
      this.announcementLoadingState = id

      let data = {
        id: id,
        important: null
      }

      if (importantState === 0) {

        data.important = 1
        this.patchAnnouncementImportant({
          data,
          token: this.authorization
        })
        .then(res => {
          this.announcementLoadingState = ''
          this.setData(null)
          this.announcementList(1)
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.announcementLoadingState = ''          
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      } else if (importantState === 1) {

        data.important = 0
        this.patchAnnouncementImportant({
          data,
          token: this.authorization
        })
        .then(res => {
          this.announcementLoadingState = ''          
          this.setData(null)
          this.announcementList(1)
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.announcementLoadingState = ''          
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    },
    changeImportant() {
      if (this.params.important === 0) {
        this.setImportant(1)
        this.announcementList(1)
      } else {
        this.setImportant(0)
        this.announcementList(1)
      }
    },
    announcementDetail(id) {
      this.togglePageLoader()

      this.getAnnouncementDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.setModalEdit(false)
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    editAnnouncement(id) {
      this.togglePageLoader()

      this.getAnnouncementDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.setModalEdit(true)
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    removeAnnouncement(id) {
      this.announcementLoadingState = id

      this.deleteAnnouncement({
        id,
        token: this.authorization
      })
      .then(res => {
        this.announcementLoadingState = ''        
        this.setData(null)
        this.announcementList(1)
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.announcementLoadingState = ''          
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.text-small
  font-size: 12px
  font-weight: 700
  letter-spacing: 1px

.nav-item
  cursor: pointer
  border: none
  border-radius: 5px

  &:hover
    background-color: #E0E6ED

  &--active
    background-color: #DBDAE7

.note-item
  max-width: 32% !important

  &:hover
    background: #DBDAE7
    cursor: pointer
  
.tags
  margin: 0 2px
  height: 10px
  width: 10px
  border: 1.5px solid
  border-radius: 50%

  &--all
    border-color: #02BFBF
    background-color: #CEFCFC

  &--office
    border-color: #00A416
    background-color: #CEFCD3

  &--pool
    border-color: #FF0000
    background-color: #FFA7A7

  &--site
    border-color: #9CA522
    background-color: #F4F8BE

  &--tug_boat
    border-color: #2800FF
    background-color: #C3B8FF
  
@keyframes slidein 
  from 
    transform: scaleX(0)
  to   
    transform: scaleX(1)

.announcement-loader
  animation: 1s linear infinite running slidein
  height: 10px 
  background-color: #2A58CC 
  bottom: 0 
  left: 0
  border-radius: 0 0 6px 6px

@media screen and (max-width: 2132px) 
  .note-item
    max-width: 32% !important

@media screen and (max-width: 1199px) 
  .note-item
    max-width: 48% !important

@media screen and (max-width: 767px) 
  .note-item
    max-width: 47% !important

@media screen and (max-width: 575px) 
  .note-item
    max-width: 100% !important
    margin-left: 0
</style>
