<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        <div class="widget-content">

          <!-- Form / component title -->
          <div class="header custom-header--main">
            <div class="header-body align-middle">
              <h4 class="mb-1 mt-3 text-white">Unggah Kehadiran Manual</h4>
            </div>
          </div>
          <!-- Form / component title -->

          <div class="w-content d-flex flex-column">
            <div>
              <ol class="text-left">
                <li>File unggah adalah microsoft excel ( xls / xlsx ) dengan maksimum 10.0000 baris.</li>
                <li>File unggah harus berdasaran dari template yang disediakan, template dapat diunduh dan nama file tidak boleh diubah.</li>
                <li>Format data pada kolom tanggal harus sesuai dengan format tanggal "Y-m-d" / "2022-03-14".</li>
                <li>Tidak di perkenankan mengupload data karyawan berbeda placement dalam 1 file yang sama</li>
                <li>Sistem akan menolak otomatis jika terdapat absensi yang sudah terisi melalui aplikasi.</li>
                <li>Alpa tidak perlu diisi apapun rownya</li>
                <li>Sakit, check in dan checkout dengan "00:00:00" dan code "sakit"</li>
                <li>Jika hadir pada hari libur / Shift off dan hadir, isi check in dan checkout, lalu untuk code isi dengan "shift_off"</li>
                <li>Jika libur nasional / public holiday karyawan hadir, isi check in dan check out, code biarkan kosong</li>
              </ol>
            </div>

            <form action="">

              <div class="row mb-3">

                <!-- Date range input -->
                <div class="col-12 col-md-6 text-left">
                  <label>Periode Tanggal</label>
                  <date-picker
                    v-model="dateRange"
                    class="w-100"
                    format="YYYY-MM-DD"
                    type="date"
                    range
                    range-separator=" sampai "
                    value-type="format"
                  />
                </div>
                <!-- Date range input -->

              </div>

              <div>
                
                <!-- Man Power Type input -->
                <div>
                  <label class="w-100 text-left">Tipe Man Power</label>
                  <v-select 
                    v-model="selectedManPower.type"
                    class="mb-4" 
                    placeholder="Tipe"
                    ref="selectManPowerType"
                    inputId="man-power-type"
                    :options="manPowerParams.type"
                    @input="manPowerSearchType"
                  >
                    <template #no-options>
                      Data tidak ditemukan
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="#888EA8" 
                          stroke-width="2" 
                          stroke-linecap="round" 
                          stroke-linejoin="round" 
                          class="feather feather-chevron-down"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </template>
                  </v-select>
                </div>
                <!-- Man Power Type input -->

                <!-- Man Power Name input -->
                <div>
                  <label class="w-100 text-left">
                    <span class="mr-1">Nama Man Power</span>
                    <span v-if="mpNameLoading">
                      <div 
                        class="spinner-border spinner-border-sm text-primary" 
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </span>
                  </label>
                  <v-select 
                    v-model="selectedManPower.name"
                    class="mb-4" 
                    placeholder="Nama"
                    ref="selectManPowerName"
                    inputId="man-power-name"                
                    :disabled="!manPowerParams.name.length"
                    :options="manPowerParams.name"
                    @input="manPowerSearchName"
                  >
                    <template #no-options>
                      Data tidak ditemukan
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="#888EA8" 
                          stroke-width="2" 
                          stroke-linecap="round" 
                          stroke-linejoin="round" 
                          class="feather feather-chevron-down"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </template>
                  </v-select>
                </div>
                <!-- Man Power Name input -->

              </div>

              <div class="w-100 mt-3 d-flex justify-content-end">

                <!-- Request button -->
                <BaseButton 
                  class="button--primary" 
                  text="Unduh Template" 
                  @button-click="downloadTemplate"
                >
                  <template #icon>
                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.37em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1408"><path fill="currentColor" d="M1280 800q0-14-9-23t-23-9h-224V416q0-13-9.5-22.5T992 384H800q-13 0-22.5 9.5T768 416v352H544q-13 0-22.5 9.5T512 800q0 14 9 23l352 352q9 9 23 9t23-9l351-351q10-12 10-24zm640 224q0 159-112.5 271.5T1536 1408H448q-185 0-316.5-131.5T0 960q0-130 70-240t188-165q-2-30-2-43q0-212 150-362T768 0q156 0 285.5 87T1242 318q71-62 166-62q106 0 181 75t75 181q0 76-41 138q130 31 213.5 135.5T1920 1024z"/></svg>
                  </template>
                </BaseButton>
                <!-- Request button -->

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
          <!-- Upload button -->
          <ManualAttendanceAdjustmentUpload />
          <!-- Upload button -->
    </div>
    <div class="col-xl-12 layout-spacing">
      <ManualAttendanceAdjustmentList />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import BaseButton from '@/components/base/BaseButton'
import ManualAttendanceAdjustmentUpload from '@/components/adjustment/attendance-adjustment/ManualAttendanceAdjustmentUpload'
import ManualAttendanceAdjustmentList from '@/components/adjustment/attendance-adjustment/ManualAttendanceAdjustmentList'

export default {
  name: 'ManualAttendanceAdjustmentContent',
  components: {
    BaseButton,
    ManualAttendanceAdjustmentUpload,
    ManualAttendanceAdjustmentList
  },
  data() {
    return {
      dateRange: [new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')],
      manPowerParams: {
        type: [],
        name: []
      },
      selectedManPower: {
        type: '',
        name: '',
        team: ''
      },
      loading: false,
      mpNameLoading: false
    }
  },
  created() {
    this.getInitalData()
  },
  methods: {
    ...mapActions('global', ['newGetEmployeeId', 'newGetManPower']),
    ...mapMutations('manualAttendanceAdjustment', ['getUploadTemplate']),
    ...mapActions('manualAttendanceAdjustment', ['getManualAdjustment']),
    shortenManPowerName(name) {
      let formattedName
      name.length >= 30 ? formattedName = `${name.split(0, 30)}...` : formattedName = name
      return formattedName
    },
    manPowerSearchType() {
      if (this.selectedManPower.type) {
        this.selectedManPower.name = ''

        this.manPowerParams.name = []

        this.manPowerList('name')
      }
    },
    manPowerSearchName() {
      if (this.selectedManPower.name) {
        this.selectedManPower.team = ''
        this.selectedManPower.group = ''

        this.manPowerParams.team = []
        this.manPowerParams.group = []

        this.manPowerList('team')
      }
    },
    manPowerSearchTeam() {
      if (this.selectedManPower.team) {
        this.selectedManPower.group = ''

        this.manPowerParams.group = []

        this.manPowerList('group')
      }
    },
    manPowerList(filterType) {
      this.manPowerOptionsLoadingState(filterType, true)

      this.newGetManPower(this.selectedManPower)
      .then(response => {
        this.manPowerOptionsLoadingState(filterType, false)
        this.manPowerParams[filterType] = response.data.data
      })
      .catch(() => this.manPowerOptionsLoadingState(filterType, false))
    },
    manPowerOptionsLoadingState(type, status) {
      switch (type) {
        case 'name':
          this.mpNameLoading = status
          break
        default:
          break
      }
    },
    formatName(reportCategory, name) { 
      let specialChars = ['~','!','@','#','$','%','^','&','*','(',')','`',';','<','>','?',',','[',']','{','}','\'','"','|']

      for (let index = 0; index < specialChars.length; index++) {
        name = name.replaceAll(specialChars[index], '').replaceAll('  ', ' ').replaceAll(' - ', ' ')
      }

      return `${reportCategory.toUpperCase()}_${name.slice(0, 30).trimEnd().replaceAll(' ', '-')}_${this.dateRange[0]}_sd_${this.dateRange[1]}`
    },
    downloadTemplate() {
      const validation = (this.selectedManPower.type && this.selectedManPower.name) && (!!this.dateRange[0] && !!this.dateRange[1])

      if (validation) {

        if (this.selectedManPower.type === null) {
          this.selectedManPower.type = ''
        } else if (this.selectedManPower.name === null) {
          this.selectedManPower.name = ''
        }

        this.getUploadTemplate({
          startDate: this.dateRange[0],
          endDate: this.dateRange[1],
          manPowerName: this.selectedManPower.name
        })

      } else {

        this.$_errorAlert('Lengkapi form terlebih dahulu!')

      }

    },
    getInitalData() {
      Promise.all([
        this.newGetManPower(this.selectedManPower),
        this.getManualAdjustment()
      ])
      .then(response => {
        this.manPowerParams['type'] = response[0].data.data
      })
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000

.custom-widget
  padding: 0
  border-radius: 6px

.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1B55E2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.task-left--custom
  background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.employee-subtitle
  font-size: 12px
  color: #4CAF50

.card 
  border: 2px solid #e0e6ed
  border-radius: 6px
  margin-bottom: 4px
  .card-body 
    p 
      color: #888ea8
      letter-spacing: 1px
      font-size: 13px
      &:not(:last-child) 
        margin-bottom: 10px
    ul 
      margin-bottom: 0
      li 
        font-size: 12px
        letter-spacing: 1px
        &:not(:last-child) 
          margin-bottom: 5px
        a 
          color: #3b3f5c
          font-size: 13px
          font-weight: 600
          &:hover 
            color: #1b55e2
            
.card-header 
  background-color: transparent
  color: #f8538d
  border-color: transparent
  border-radius: 4px
  padding: 0
  position: relative
  section 
    & > div 
      padding: 13px 19px
      cursor: pointer
      display: block
      font-size: 14px
      letter-spacing: 1px
      &.collapsed 
        color: #888ea8
      &:not(.collapsed) 
        color: #1b55e2
        border-bottom: 2px solid #e0e6ed
        font-weight: 600
      .icons 
        position: absolute
        right: 0
        top: 0
        bottom: 0
        padding: 9px
        svg 
          width: 18px

.accordion-icons .accordion-icon 
  display: inline-block
  margin-right: 10px 
  .accordion-icons .accordion-icon svg 
    color: #888ea8
    margin-right: 6px
    vertical-align: middle
    width: 20px
    height: 20px
    fill: rgba(0, 23, 55, 0.08) 

.accordion-icons div:not(.collapsed) .accordion-icon svg 
  color: #1b55e2
  fill: rgba(27, 85, 226, 0.239216) 
</style>
