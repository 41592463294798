<script>
import { mapMutations  } from 'vuex'

export default {
  name: 'Guide',
  methods: {
    ...mapMutations('styling', ['togglePageLoader']),
  },
  mounted() {
    this.togglePageLoader()
    window.location.href ='https://ptbppid.notion.site/408d2857e85c411ea4c59213da0403d0?v=ce5edb9cc144472eaf395449e7f847d5'
  }
}
</script>