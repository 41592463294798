<script>
export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    specificMarker: {
      type: Object,
      required: true
    }
  },
  mounted() {
    const { Marker, Size, Circle } = this.google.maps

    new Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: {
        url: this.marker.icon.url,
        scaledSize: new Size(30, 30)
      }
    })

    new Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.25,
      strokeWeight: 1,
      fillColor: '#B61827',
      map: this.map,
      center: this.specificMarker.position,
      radius: this.specificMarker.radius
    })
  },
  // eslint-disable-next-line vue/require-render-return
  render() {}
}
</script>