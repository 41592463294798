<template>
  <button 
    :class="`button ${classes}`" 
    :disabled="disabled" 
    :style="size === 'lg' ? 'min-width: 95px' : 'min-width: 30px'"
    @click.prevent="$emit('button-click')"
  >
    <span v-if="!loading">
      <slot name="icon">
      </slot>
      <span>{{ text }}</span>
    </span>
    <span 
      v-else-if="loading" 
      class="py-1 d-flex justify-content-center"
    >
      <span 
        class="spinner-border spinner-border-sm" 
        role="status" 
        aria-hidden="true"
      ></span>
      <span class="sr-only">Loading...</span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    classes: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: String,
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    }
  }
}
</script>

<style lang="sass" scoped>
$initial-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
$hovered-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5)
$transform-scale: scale(1.05)

.button
  padding: 3px 5px
  border-radius: 7px
  box-shadow: $initial-box-shadow
  display: inline-block
  vertical-align: middle
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.1s
  transition-property: box-shadow, transform

  &--danger
    border: 1px solid #F44336
    color: #F44336
    background-color: #FFFFFF

    &:hover
      background-color: #F44336 
      color: #FFFFFF
      box-shadow: $hovered-box-shadow
      transform: $transform-scale
  
  &--primary
    border: 1px solid #1B55E2
    color: #FFFFFF
    background-color: #1B55E2

    &:hover
      background-color: #0038BF
      box-shadow: $hovered-box-shadow
      transform: $transform-scale
  
  &--success
    border: 1px solid #009688
    color: #FFFFFF
    background-color: #009688

    &:hover
      background-color: #075A52
      box-shadow: $hovered-box-shadow
      transform: $transform-scale
  
  &--success-bordered
    border: 1px solid #009688
    color: #009688
    background-color: #FFFFFF

    &:hover
      background-color: #075A52
      color: #FFFFFF
      box-shadow: $hovered-box-shadow
      transform: $transform-scale

  &--cancel
    border: 1px solid #848484
    color: #848484
    background-color: #FFFFFF

    &:hover
      background-color: #747474
      color: #FFFFFF
      box-shadow: $hovered-box-shadow
      transform: $transform-scale

button:disabled
  cursor: not-allowed !important
  transform: none !important
  transition: none !important
  opacity: 50% !important

  &:hover
    opacity: 50% !important

@media (max-width: 576px) 
  .button

    &--danger, &--primary, &--success, &--success-bordered, &--cancel
    
      &:hover
        transform: none
</style>
