<template>
  <div class="vw-100 vh-100 d-flex flex-row justify-content-between">
    <div class="wrapper w-100 d-flex flex-column justify-content-between align-items-center">
      <div class="main w-75">

        <AuthHeader/>

        <AuthFormHeader title="Reset Password"/>

        <form v-if="!resetSuccess" action="" @submit.prevent="submitResetPassword" class="w-100 d-flex flex-column">

          <div class="w-100 d-flex flex-column">

            <div class="d-flex flex-column flex-lg-row">

              <div class="w-100 my-2 my-lg-2 mx-1 input d-flex flex-column">
                <label for="password">Masukkan password baru</label>
                <div class="custom-input mb-2" :class="{ 'custom-input--error': submitted && !$v.password.required }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" :stroke="submitted && !$v.password.required ? '#EE2A2A' : 'currentColor'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                  <input 
                    id="lockscreen-password" 
                    :type="togglePassword ? 'text' : 'password'" 
                    v-model="password">
                  <svg @click="togglePassword = !togglePassword" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" :stroke="togglePassword ? '#1B55E2' : 'currentColor'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id="toggle-password" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                </div>
                <p v-if="submitted && !$v.password.required" class="text-danger font-weight-bold">Password harus diisi</p>
              </div>

            </div>

          </div>

          <div class="mx-1 d-flex flex-row align-items-center">
            <BaseButton 
              class="button button--primary p-2" 
              text="Reset Password" 
              :loading="loading"
              @button-click="submitResetPassword"/>
          </div>

        </form>

        <div v-else-if="resetSuccess !== false" class="success-message p-3 d-flex flex-column align-items-center">
          <svg class="my-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="75px" height="75px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4l8-8l-1.41-1.42z" fill="#28a745"/></svg>
          <p class="my-2 text-success font-weight-bold">{{ resetSuccess }}</p>
        </div>

      </div>
      <AuthFooter/>
    </div>
    <AuthPicture/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import AuthHeader from '@/components/auth/AuthHeader'
import AuthFormHeader from '@/components/auth/AuthFormHeader'
import AuthFooter from '@/components/auth/AuthFooter'
import AuthPicture from '@/components/auth/AuthPicture'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'UserResetPassword',
  data() {
    return {
      password: '',
      togglePassword: false,
      loading: false,
      submitted: false,
      resetSuccess: false
    }
  },
  validations: {
    password: {required}
  },
  components: {
    AuthHeader,
    AuthFormHeader,
    AuthFooter,
    AuthPicture,
    // eslint-disable-next-line vue/no-unused-components
    BaseButton
  },
  methods: {
    ...mapActions('userAuth', ['patchResetPassword']),
    submitResetPassword() {
      this.submitted = true
      this.loading = true
      this.$v.$touch()

      if (this.$v.$invalid) {

        this.loading = false
        return

      } else {

        if (!this.$route.query.email || !this.$route.query.code) {

          this.loading = false
          this.$swal({
            position: 'center',
            icon: 'error',
            html: `<span><b>Pastikan link yang anda akses sudah benar!</b></span>`,
            showConfirmButton: false,
            timer: 3000
          })

        } else {

          let data = {
            email: this.$route.query.email,
            verification_code: this.$route.query.code,
            new_password: this.password,
            confirm_new_password: this.password
          }
  
          this.patchResetPassword(data)
          .then(res => {
            this.loading = false
            this.resetSuccess = res.data.message
            if (res.data.data.deep_link !== '') {
              if (this.checkMobile()) {
                window.open(res.data.data.deep_link)
              } else {
                return
              }
            }
          })
          .catch(err => {
            this.loading = false
            err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
          })
        }
      }
    },
    checkMobile() {
      // source: https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device
      let check = false
      // eslint-disable-next-line no-unexpected-multiline
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        // eslint-disable-next-line no-useless-escape
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        check = true;
      }
      return check
    }
  }
}
</script>

<style lang="sass" scoped>
.success-message
  background-color: #FFFFFF
  border-radius: 5px
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)

  p
    font-size: 1.2rem

.custom-label
  color: #000000
  margin: 0 5px 10px 0

label
  color: #000000

input[type="text"]:disabled
  background: none !important

.custom-input
  padding: 10px
  border: 3px solid rgba(255, 255, 255, 0)
  border-radius: 3px
  display: flex
  flex-direction: row
  align-items: center
  background-color: #F3F6F9

  &:focus-within
    border: 3px solid #1B55E2

  &:hover
    background-color: #D5E5F5

    input
      background-color: #D5E5F5          

  input
    width: 100%
    margin: 0 5px
    border: none
    background-color: #F3F6F9
  
  &--error
    border: 3px solid #EE2A2A

.input
  margin: 20px

  &__field
    height: 66px
    width: 100%
    padding: 0 20px
    border: none
    border-radius: 3px
    background-color: #F3F6F9
    
    &:focus
      border: 3px solid #1B55E2

    &:hover
      background-color: #D5E5F5

    &--error
      border: 3px solid #EE2A2A

  &__code
    font-size: 25px
    text-align: center
    background: none
    text-transform: uppercase
    margin-right: 2px
    border: none
    height: 50px
    width: 40px
    border-bottom: 2px solid black

    &--done
      border-bottom: 4px solid blue

    &--clear
      border: none
      background: none
      color: red
      font-size: 30px

      &:hover
        background-color: lightblue
        opacity: 0.75

  &__verif
    text-transform: uppercase
    margin-left: 8px
    border: none
    letter-spacing: 19px

  &__text--error
    color: #EE2A2A

.verif__dash
  margin-right: 2px
  width: 28px
  border-bottom: 2px solid black

.button
  transition: 0.2s
  text-transform: uppercase

  &--fill
    border: none
    border-radius: 7px
    background-color: #1B55E2
    color: #FFFFFF

    &:hover
      background-color: #1244BC
      box-shadow: 0 15px 20px -10px #1b55e2

  &--border
    border: 2px solid #1B55E2
    border-radius: 7px
    background-color: #FFFFFF
    color: #1B55E2

    &:hover
      background-color: #F6F6F6
      box-shadow: 0 15px 20px -10px #1b55e2
      text-decoration: none

.main
  margin-top: auto

.user-email
  font-weight: 600
  color: #0d47a1

.wrapper
  background: linear-gradient(90deg, rgba(209, 227, 255, 0.9), rgba(209, 227, 255, 0.9)), url(../../../assets/img/bpp/login-side.png) no-repeat fixed center
  background-size: cover

@media (min-width: 1200px) 
  .wrapper
    background: #ffffff
</style>