<template>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center" style="min-height: 100vh;">
    <div class="custom-card">

      <div class="card-header">
        <span><img src="@/assets/svg/user-picture.svg" width="50px" height="50px" alt="Foto profil"></span>
        <span class="ml-3">{{ userProfile.fullname }}</span>
      </div>

      <div class="card-form">
        <form @submit.prevent="login" action="">
          <label for="lockscreen-password">Password</label>
          <div class="custom-input" :class="{ 'custom-input--error': submitted && !$v.password.required }">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" :stroke="submitted && !$v.password.required ? '#EE2A2A' : 'currentColor'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
            <input 
              id="lockscreen-password" 
              :type="togglePassword ? 'text' : 'password'" 
              v-model="password">
            <svg @click="togglePassword = !togglePassword" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" :stroke="togglePassword ? '#1B55E2' : 'currentColor'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id="toggle-password" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
          </div>
          <span class="text-danger" v-if="submitted && !$v.password.required">Password harus diisi</span>
          <button @click.prevent="login">Unlock</button>
        </form>
      </div>
      
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'LockScreen',
  data() {
    return {
      submitted: false,
      togglePassword: false,
      password: ''
    }
  },
  validations: {
    password: {required}
  },
  methods: {
    login() {
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {

        return

      } else {

        let email = this.userEmail
        let password = this.password
        let auth = {email, password} 

        this.$axios.post(`${process.env.VUE_APP_API_URL}auth/`, auth, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if (localStorage.getItem('permanentData') !== null) {
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('userProfile', JSON.stringify(res.data.data))
            localStorage.setItem('userEmail', this.userEmail)
          } else {
            sessionStorage.setItem('token', res.data.data.token)
            sessionStorage.setItem('userProfile', JSON.stringify(res.data.data))
            sessionStorage.setItem('userEmail', this.userEmail)
          }
          this.$router.push('/')
        })
        .catch(err => {
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    }
  },
  computed: {
    ...mapGetters('auth', ['userProfile', 'userEmail'])
  }
}
</script>

<style lang="sass" scoped>
.custom-card
  width: 400px
  padding: 25px
  background-color: #FFFFFF
  border-radius: 15px
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06)

  .card-header
    padding: 0
    margin-bottom: 20px
    background-color: #FFFFFF
    border: none
    font-size: 1.75rem

  .card-form
    
    form
      display: flex
      flex-direction: column
      
      label
        color: #000000
        text-transform: uppercase
        font-size: 0.6rem

      .custom-input
        padding: 10px
        border-radius: 3px
        display: flex
        flex-direction: row
        align-items: center
        background-color: #F3F6F9

        &:focus-within
          border: 3px solid #1B55E2

        &:hover
          background-color: #D5E5F5

          input
            background-color: #D5E5F5          

        input
          width: 100%
          margin: 0 5px
          border: none
          background-color: #F3F6F9
        
        &--error
          border: 3px solid #EE2A2A

      button
        margin-top: 10px
        padding: 3px 0
        color: #FFFFFF
        border-radius: 7px
        background-color: #1B55E2
        border: none

        &:hover
          background-color: #1244BC
          box-shadow: 0 15px 20px -10px #1b55e2
</style>