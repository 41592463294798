<template>
  <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
    <div 
      v-if="topId && bottomId" 
      :class="topId && bottomId ? 'custom-flex' : 'custom-flex--none'"
    >
      Menampilkan data {{ topId }} sampai {{ bottomId }} dari {{ totalRecords }}
    </div>
    <div>
      <ul class="d-flex flex-row align-items-center justify-content-center pagination">
        <li 
          v-if="firstPage !== 'disabled'" 
          class="pagination__item" 
          @click="$emit('first-page')"
        >
          <div class="pagination__before">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-rewind"
            >
              <polygon points="11 19 2 12 11 5 11 19"></polygon>
              <polygon points="22 19 13 12 22 5 22 19"></polygon>
            </svg>
          </div>
        </li>
        <li 
          v-if="previousPage !== 'disabled'" 
          class="pagination__item" 
          @click="$emit('previous-page')"
        >
          <div class="pagination--disabled">{{ previousPage }}</div>
        </li>
        <li class="pagination__item">
          <div class="pagination--active">{{ currentPage }}</div>
        </li>
        <li 
          v-if="nextPage !== 'disabled'" 
          class="pagination__item" 
          @click="$emit('next-page')"
        >
          <div class="pagination--disabled">{{ nextPage }}</div>
        </li>
        <li 
          v-if="lastPage !== 'disabled'" 
          class="pagination__item" 
          @click="$emit('last-page')"
        >
          <div class="pagination__after">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-fast-forward"
            >
              <polygon points="13 19 22 12 13 5 13 19"></polygon>
              <polygon points="2 19 11 12 2 5 2 19"></polygon>
            </svg>
          </div>
        </li>
      </ul>
    </div>
    <div :class="topId && bottomId ? 'custom-flex' : 'custom-flex--none'">
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    firstPage: [Number, String],
    previousPage: [Number, String],
    currentPage: [Number, String],
    nextPage: [Number, String],
    lastPage: [Number, String],
    topId: [Number, String],
    bottomId: [Number, String],
    totalRecords: [Number, String]
  }
}
</script>

<style lang="sass" scoped>
.pagination
  margin: 3px 0 !important

  &__item
    display: flex
    align-items: center
    justify-content: center
    transition: 0.2s
    width: 32px
    height: 32px
    margin: 0 1px
    cursor: pointer
    border: none
    border-radius: 50%
    background-color: #E0E0E0
    color: #BDBDBD

    &:hover
      background-color: #A2A8FF
      color: #F8F8F8
      box-shadow: 0 15px 20px -10px #1b55e2
  
  &--active
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    border-radius: 50%
    background-color: #1B55E2
    color: #FFFFFF

.custom-flex
  color: #2196F3

@media (min-width: 768px) 
  .custom-flex
    color: #2196F3
    flex: 1

    &--none
      flex: 0  

</style>