<template>
  <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
    <div class="widget-four">

      <div class="widget-heading d-flex flex-column flex-md-row justify-content-between">
        <h4 class="text-purple-custom">Ketidakhadiran</h4>
        <v-popover 
          offset="5" 
          placement="left-start" 
          class="align-self-end align-self-md-baseline"
        >
          <button class="badge-custom">
            <svg 
              style="width:18px;height:18px" 
              viewBox="0 0 24 24"
            >
              <path 
                fill="currentColor" 
                d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" 
              />
            </svg>
            <span>
              {{ selectedMonth.label }} {{ year }}
            </span>
          </button>
          <template #popover>
            <div class="d-flex flex-column">
              <button 
                v-for="month in months" 
                :key="month.id" 
                class="dropdown-button" 
                v-close-popover
                @click="changeMonth(month.label, month.value)" 
              >
                {{ month.label }}
              </button>
            </div>
          </template>
        </v-popover>
      </div>

      <div class="widget-content">
        <div class="vistorsBrowser">
          
          <div class="browser-list">
            <div class="w-browser-details">
              <div class="w-browser-info">
                <h6 class="text-uppercase">Tidak Hadir</h6>
                <PuSkeleton 
                  v-if="!absence.absence" 
                  width="15px"
                />
                <p 
                  v-else 
                  class="mb-2 browser-count"
                >
                  {{ absence.absence.total }}
                </p>
              </div>
              <div class="w-browser-stats">
                <div class="progress">
                  <PuSkeleton v-if="!absence.absence"></PuSkeleton>
                  <div
                    v-else
                    class="progress-bar bg-danger"
                    role="progressbar"
                    :style="`width: ${absence.absence.percentage}%`"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="browser-list">
            <div class="w-browser-details">
              <div class="w-browser-info">
                <h6 class="text-uppercase">Cuti</h6>
                <PuSkeleton 
                  v-if="!absence.absence" 
                  width="15px"
                />
                <p 
                  v-else 
                  class="mb-2 browser-count"
                >
                  {{ absence.paid_leave.total }}
                </p>
              </div>
              <div class="w-browser-stats">
                <div class="progress">
                  <PuSkeleton v-if="!absence.paid_leave"/>
                  <div
                    v-else
                    class="progress-bar bg-success"
                    role="progressbar"
                    :style="`width: ${absence.paid_leave.percentage}%`"
                    aria-valuenow="65"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="browser-list">
            <div class="w-browser-details">
              <div class="w-browser-info">
                <h6 class="text-uppercase">Cuti Tidak Berbayar</h6>
                <PuSkeleton 
                  v-if="!absence.absence" 
                  width="15px"
                />
                <p 
                  v-else 
                  class="mb-2 browser-count"
                >
                  {{ absence.unpaid_leave.total }}
                </p>
              </div>
              <div class="w-browser-stats">
                <div class="progress">
                  <PuSkeleton v-if="!absence.unpaid_leave"/>
                  <div
                    v-else
                    class="progress-bar bg-purple-custom"
                    role="progressbar"
                    :style="`width: ${absence.unpaid_leave.percentage}%`"
                    aria-valuenow="15"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardAttendance',
  props: {
    date: String
  },
  data() {
    return {
      dropdown: false,
      year: new Date().toLocaleDateString('id-ID', { year: 'numeric' }),
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      },
      months: [
        {
          label: 'Januari',
          value: '1'
        },
        {
          label: 'Februari',
          value: '2'
        },
        {
          label: 'Maret',
          value: '3'
        },
        {
          label: 'April',
          value: '4'
        },
        {
          label: 'Mei',
          value: '5'
        },
        {
          label: 'Juni',
          value: '6'
        },
        {
          label: 'Juli',
          value: '7'
        },
        {
          label: 'Agustus',
          value: '8'
        },
        {
          label: 'September',
          value: '9'
        },
        {
          label: 'Oktober',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'Desember',
          value: '12'
        },
      ]
    }
  },
  computed: {
    ...mapState('dashboard', ['absence'])
  },
  methods: {
    changeMonth(label, value) {
      this.selectedMonth = {
        label: label,
        value: value
      }
      this.dropdown = false
      this.$emit('change-month', value)
    }
  }
}
</script>

<style lang="sass" scoped>
.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff
    
.bg-purple-custom
  background-color: #5C1AC3

.text-purple-custom
  color: #5C1AC3 !important
</style>
