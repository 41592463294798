<template>
  <BaseNewModal
    v-model="newModal"
    @confirm="confirm"
    @cancel="cancel"
    @close-modal="closeModal()"
  >
    <template #title>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C9.243 2 7 4.243 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V7zm4 10.723V20h-2v-2.277a1.993 1.993 0 0 1 .567-3.677A2.001 2.001 0 0 1 14 16a1.99 1.99 0 0 1-1 1.723z"/></svg>
          </p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Ubah Kata Sandi</p>
        </div>
      </div>

    </template>
    <div class="p-1">
      <form class="mb-4" @submit.prevent="setNewPassword">
        <div class="col-12">
  
          <div class="mb-3 d-flex flex-column">
            <label for="change-password-old">Kata sandi lama</label>
            <input
              id="change-password-old"
              type="password"
              class="custom-input"
              :class="{'custom-input--error': submitted && $v.changePassword.oldPassword.$error}"
              v-model="changePassword.oldPassword"
            >
            <p v-if="submitted && !$v.changePassword.oldPassword.required" class="text-danger font-weight-bold">Kata sandi harus diisi</p>
          </div>
  
          <div class="mb-3 d-flex flex-column">
            <label for="change-password-new">Kata sandi baru</label>
            <input
              id="change-password-new"
              type="password"
              class="custom-input"
              :class="{'custom-input--error': submitted && $v.changePassword.newPassword.$error}"
              v-model="changePassword.newPassword"
            >
            <p v-if="submitted && !$v.changePassword.newPassword.required" class="text-danger font-weight-bold">Kata sandi harus diisi</p>
          </div>
  
          <div class="mb-3 d-flex flex-column">
            <label for="change-password-repeat">Ulangi kata sandi baru</label>
            <input
              id="change-password-repeat"
              type="password"
              class="custom-input"
              :class="{'custom-input--error': submitted && $v.changePassword.repeatNewPassword.$error}"
              v-model="changePassword.repeatNewPassword"
            >
            <p v-if="submitted && !$v.changePassword.repeatNewPassword.required" class="mb-0 text-danger font-weight-bold">Kata sandi harus diisi</p>
            <p v-if="submitted && !$v.changePassword.repeatNewPassword.sameAsNewPassword" class="text-danger font-weight-bold">Kata sandi tidak sama</p>
          </div>
  
        </div>
      </form>

      <div class="d-flex align-items-end justify-content-end">
        <BaseButton
          class="button--primary"
          text="Simpan"
          :loading="loading"
          @button-click="setNewPassword"
        />
      </div> 
    </div>
  </BaseNewModal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
import BaseNewModal from '@/components/base/BaseNewModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'BaseHeaderChangePasswordModal',
  data() {
    return {
      changePassword: {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: ''
      },
      submitted: false,
      loading: false
    }
  },
  components: {
   BaseNewModal,
   BaseButton
  },
  validations: {
    changePassword: {
      oldPassword: {required},
      newPassword: {required},
      repeatNewPassword: {required, sameAsNewPassword: sameAs('newPassword') }
    }
  },
  computed: {
    ...mapState('styling', ['newModal'])
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL', 'toggleNewModal']),
    ...mapActions('auth', ['postChangePassword']),
    confirm() {
      this.toggleNewModal(false)
    },
    cancel(close) {
      close()
    },
    resetForm() {
      this.changePassword = {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: ''
      }
      this.submitted = false
      this.loading = false
    },
    closeModal() {
      this.resetForm()
      this.toggleNewModal(false)
    },
    setNewPassword() {
      this.loading = true
      this.submitted = true
      this.$v.changePassword.$touch()

      if (this.$v.changePassword.$invalid) {

        this.loading = false
        return

      } else {

        let data = {
          old_password: this.changePassword.oldPassword,
          new_password: this.changePassword.newPassword,
          confirm_new_password: this.changePassword.repeatNewPassword
        }
        this.postChangePassword(data)
        .then(res => {
          this.changePassword = {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: ''
          }
          this.closeModal()
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))
</style>
