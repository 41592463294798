<template>
  <BaseTable
    :columns="columns"
    :sortKey="tableParams.order[0]"
    :sortOrders="tableParams.order[1]"
    @sort-data="tableSortBy"
  >
  
    <template #tableLimit>
      <BaseLimitSelection
        v-model="tableParams.limitValue"
        :limitValue="tableParams.limitValue"
        @input="employeeList(tableParams.pagination.currentPage)"
      />
    </template>

    <template #tableSearch>
      <BaseCustomSearch 
        v-model="tableParams.globalSearch"
        :searchValue="tableParams.globalSearch" 
        @search-function="employeeList(1)"
      />
    </template>
    
    <template #tableColumnSearch>
      <tr>
        <td colspan="2"></td>
        <td>
          <input 
            v-model="tableParams.columnSearch.employee_number_formatted" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="employeeList(tableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="tableParams.columnSearch.name" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="employeeList(tableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="tableParams.columnSearch.email" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="employeeList(tableParams.pagination.currentPage)"
            > 
        </td>
        <td>
          <v-select 
            v-model="selectedPersonalia"
            style="background-color: #ffffff"
            label="label"
            placeholder="Cari.."
            append-to-body
            :options="personalia"
            @input="searchByPersonalia"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </td>
      </tr>
    </template>

    <template #tableBody>
      <tbody v-if="!data">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td><PuSkeleton/></td>
          <td>
            <PuSkeleton 
              circle 
              height="50px" 
              width="50px"
            />
          </td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </tbody>
      <tbody v-else-if="data && data.data.length">
        <tr 
          v-for="item in data.data" 
          :key="item.id"
        >
          <td 
            class="cursor-pointer"
            @click="employeeDetail(item.hash_id)" 
          >
            {{ item.no }}
            </td>
          <td 
            class="cursor-pointer"
            @click="employeeDetail(item.hash_id)" 
          >
            <img 
              alt="Foto karyawan" 
              class="small-pic"
              :src="item.photo_url"
            > 
          </td>
          <td 
            class="cursor-pointer"
            @click="employeeDetail(item.hash_id)" 
          >
            {{ item.employee_number_formatted }}
          </td>
          <td 
            class="cursor-pointer"
            @click="employeeDetail(item.hash_id)" 
          >
            {{ item.name }}
          </td>
          <td 
            class="cursor-pointer"
            @click="employeeDetail(item.hash_id)" 
          >
            <span v-if="item.email !== 'Not set'">{{ item.email }}</span>
            <span v-else>Belum diatur</span>
          </td>
          <td 
            class="cursor-pointer"
            @click="employeeDetail(item.hash_id)" 
          >
            {{ item.is_personalia === 1 ? 'Ya' : 'Tidak' }}
          </td>
          <td>
            <v-popover
              offset="5"
              placement="top"
            >
              <BaseButton
                class="button button--primary"
                text="Reset Password"
                :loading="resetLoadingState === item.employee_number_formatted"
              />
              <template #popover>
                <p class="font-weight-bold">Reset password karyawan bersangkutan?</p>
                <div class="d-flex align-items-center justify-content-center">
                  <BaseButton
                    class="button button--cancel mr-2"
                    text="Batal"
                    v-close-popover
                  />
                  <BaseButton
                    class="button button--danger"
                    text="Reset"
                    v-close-popover
                    @button-click="resetEmployeePassword(item.employee_number_formatted)"
                  />
                </div>
              </template>
            </v-popover>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="data && !data.data.length">
        <tr>
          <td 
            colspan="6" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>
    
    <template #tableFoot>
      <tr>
        <td colspan="2"></td>
        <td>
          <input 
            v-model="tableParams.columnSearch.employee_number_formatted" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="employeeList(tableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="tableParams.columnSearch.name" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="employeeList(tableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="tableParams.columnSearch.email" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="employeeList(tableParams.pagination.currentPage)"
            > 
        </td>
        <td>
          <v-select 
            v-model="selectedPersonalia"
            style="background-color: #ffffff"
            label="label"
            placeholder="Cari.."
            append-to-body
            :options="personalia"
            @input="searchByPersonalia"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </td>
        <td></td>
      </tr>
    </template>

    <template #tablePagination>
      <div 
        v-if="!data" 
        class="d-flex align-items-center justify-content-center"
      >
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
      </div>
      <BasePagination
        v-else
        :firstPage="tableParams.pagination.firstPage"
        :previousPage="tableParams.pagination.previousPage"
        :currentPage="tableParams.pagination.currentPage"
        :nextPage="tableParams.pagination.nextPage"
        :lastPage="tableParams.pagination.lastPage"
        :topId="tableParams.pagination.firstData"
        :bottomId="tableParams.pagination.lastData"
        :totalRecords="data.recordsTotal"
        @first-page="employeeList(tableParams.pagination.firstPage)"
        @previous-page="employeeList(tableParams.pagination.previousPage)"
        @next-page="employeeList(tableParams.pagination.nextPage)"
        @last-page="employeeList(tableParams.pagination.lastPage)"
      />
    </template>

  </BaseTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BasePagination from '@/components/base/BasePagination'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'EmployeeTable',
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BasePagination,
    BaseButton
  },
  data() {
    return {
      columns: [
        {
          label: 'No',
          width: '5%',
          name: 'no'
        },
        {
          label: 'Photo',
          width: '5%',
          name: 'photo_url'
        },
        {
          label: 'ID Karyawan',
          width: '15%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '30%',
          name: 'name'
        },
        {
          label: 'Email',
          width: '30%',
          name: 'email'
        },
        {
          label: 'Personalia',
          width: '10%',
          name: 'personalia'
        },
        {
          label: 'Aksi',
          width: '5%',
          name: 'action'
        }
      ],
      personalia: [
        {
          label: 'Semua',
          value: ''
        },
        {
          label: 'Ya',
          value: 1
        },
        {
          label: 'Tidak',
          value: 0
        }
      ],
      selectedPersonalia: '',
      resetLoadingState: 'false'
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('employee', ['data']),
    ...mapGetters('employee', ['tableParams'])
  },
  created() {
    this.employeeList(1)
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    ...mapActions('employee', ['getEmployee', 'getEmployeeDetail', 'patchEmployeePassword']),
    ...mapMutations('employee', ['setTableColumnParams', 'setTableOrderParams', 'setTableSortingParams', 'setPersonalia', 'toggleMainModal']),
    employeeList(page) {
      this.getEmployee({
        page: page,
        tableParams: this.tableParams,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behavior: 'smooth' }))
    },
    employeeDetail(id) {
      this.togglePageLoader()

      this.getEmployeeDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
        this.toggleMainModal()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    tableSortBy(key) {
      let sortOrders = {}
      let temporaryIndex 

      this.columns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.columns.findIndex(i => i['name'] == key)
      
      this.setTableOrderParams(key)

      if (temporaryIndex === this.tableParams.columnIndex[0]) {

        this.tableParams.order[1] === 'asc' ? this.setTableSortingParams('desc') : this.setTableSortingParams('asc')
        
      } else {

        this.setTableColumnParams(temporaryIndex)
        this.setTableSortingParams('asc')

      }

      this.employeeList(this.tableParams.pagination.currentPage)
    },
    searchByPersonalia(){
      this.selectedPersonalia ? this.setPersonalia(this.selectedPersonalia.value) : this.setPersonalia('')
      this.employeeList(this.tableParams.pagination.currentPage)
    },
    resetEmployeePassword(employeeId) {
      this.resetLoadingState = employeeId

      this.patchEmployeePassword({
        employee_number_formatted: employeeId
      })
      .then(response => this.$_successAlert(response.data.message))
      .catch(error => error.response ? this.$_errorAlert(error.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.'))
      .finally(() => this.resetLoadingState = 'false')
    }
  }
}
</script>

<style lang="sass" scoped>
.small-pic
  height: 50px
  width: 50px
  object-fit: cover
  border-radius: 50%
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.3s
  transition-property: border-radius

  &:hover
    border-radius: 5px

.cursor-pointer
  cursor: pointer

.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

.v-select::v-deep .vs__dropdown-toggle
  border: 1px solid #BDBDBD
  height: 26px
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

.v-select::v-deep .vs__search, .v-select::v-deep .vs__selected 
  margin: auto
</style>