<template>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div 
          class="br-6" 
          :class="{ 'widget-content widget-content-area': tableContainer }"
        >
          <div 
            :class="{'w-100 mb-4 d-flex flex-row align-items-center justify-content-between' : this.$slots.tableButton || tableTitle || tableSubtitle}"
          >
            <div
              class="pl-0" 
              :class="tableTitle ? 'w-50' : ''"
            >
              <h5 class="table-title mb-1">{{ tableTitle }}</h5>
              <p class="text-black">{{ tableSubtitle }}</p>
            </div>
            <slot 
              :class="tableTitle ? 'w-50' : 'w-100'" 
              name="tableButton"
            ></slot>
          </div>
          <hr 
            v-if="tableDivider" 
            class="w-100 divider"
          />
          <div>
            <slot name="tableCustomHeader"></slot>
          </div>
          <div>
            <slot name="tableCustomFiltering"></slot>
          </div>
          <div class="w-100 d-flex justify-content-between">
            <div class="d-flex">
              <slot name="tableLimit"></slot>
              <slot name="tableExport"></slot>
            </div>
            <div>
              <slot name="tableSearch"></slot>
            </div>
          </div>
          <div class="table-responsive mb-4 mt-4">
            <table 
              class="table table-hover" 
              style="width:100%"
            >
              <thead 
                ref="baseTableHead" 
                @scroll.passive="checkScroll"
              >
                <tr>
                  <th
                    v-for="column in columns" 
                    :key="column.id"
                    class="pr-4" 
                    :class="conditionalSortingClass(column)"
                    :style="`width: ${column.width}; cursor: pointer;`" 
                    @click="$emit('sort-data', column.name)">
                    {{ column.label }}
                  </th>
                </tr>
                <slot name="tableColumnSearch"></slot>
              </thead>
              <slot name="tableBody"></slot>
              <slot name="tableFoot"></slot>
            </table>
            <slot name="tablePagination"></slot>
          </div>
          <slot name="footerButtons"></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    tableTitle: String,
    tableSubtitle: String,
    columns: Array,
    tableContainer: {
      type: Boolean,
      default: true
    },
    tableDivider: {
      type: Boolean,
      default: false
    },
    sortKey: String,
    sortOrders: String
  },
  data() {
    return {
      windowy: 0,
      top: 0,
      height: 0
    }
  },
  methods: {
    checkScroll(e) {
      let elementTarget = this.$refs.baseTableHead
      // if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
      //   console.log("You've scrolled past the second div")
      // }
      if ((e.target.scrollHeight - 60) <= e.target.scrollTop) {
        alert('pass')
      }
      this.windowy = window.scrollY
      this.top = elementTarget.offsetTop
      this.height = elementTarget.offsetHeight
    },
    conditionalSortingClass(column) {
      let routePath = this.$route.path
      
      if (routePath === '/employee' ||
          routePath === '/leave-adjustment' ||
          routePath === '/approval/attendance' ||
          routePath === '/approval/permission' ||
          routePath === '/report/attendance-log' ||
          routePath === '/settings/permission' ||
          routePath === '/settings/shift' ||
          routePath === '/adjustment/attendance/edit' ||
          routePath === '/sync/attendance/validation') {

        if (this.sortKey === column.name) {

          if (this.sortOrders === 'asc') {

            return 'sorting_asc' 

          } else {

            return 'sorting_desc'

          }

        } else {

          return 'sorting'

        }

      } else {

        return ''

      }
    }
  },
  // mounted() {
  //   console.log(window.scrollY)
  //   console.log(this.$refs.baseTableHead.offsetTop)
  //   console.log(this.$refs.baseTableHead.offsetHeight)
  //   this.$refs.baseTableHead.addEventListener('scroll', this.checkScroll())
  // },
  // destroyed() {
  //   this.$refs.baseTableHead.removeEventListener('scroll', this.checkScroll())
  // }
}
</script>

<style lang="sass" scoped>
.widget-content
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07) !important

.custom-dropdown
  border-radius: 6px

.button-custom
  border-color: #009688
  background: none
  color: #009688
  border-radius: 5px

.table-title
  color: #068DD2
  font-weight: bold
  margin: 0
  border: none

.sorting 
  background-image: url('../../assets/img/sort_both.png')
  background-repeat: no-repeat
  background-position: center right

.sorting_asc 
  background-image: url('../../assets/img/sort_asc.png')
  background-repeat: no-repeat
  background-position: center right

.sorting_desc 
  background-image: url('../../assets/img/sort_desc.png')
  background-repeat: no-repeat
  background-position: center right
        

.divider
  // box-sizing: border-box
  // border-bottom: 1px solid #D5D5D5
  margin: 1rem -1rem 1rem -1rem
  // padding-right: -5rem
</style>