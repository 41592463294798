<template>
  <div>

    <!-- Table export buttons -->
    <div class="doc-button-container d-flex flex-row align-items-center">
      <div class="mr-3">
        <svg 
          id="file-excel" 
          class="doc-button" 
          xmlns="http://www.w3.org/2000/svg" 
          width="27.634" 
          height="36.845" 
          fill="#2196F3" 
          viewBox="0 0 27.634 36.845"
          @click="exportExcel"
        >
          <path 
            id="Path_685" 
            data-name="Path 685" 
            class="cls-1" 
            d="M16.12,9.787V0H1.727A1.723,1.723,0,0,0,0,1.727V35.118a1.723,1.723,0,0,0,1.727,1.727h24.18a1.723,1.723,0,0,0,1.727-1.727v-23.6H17.847A1.732,1.732,0,0,1,16.12,9.787Zm4.325,7.664L16.12,24.18l4.325,6.729a.865.865,0,0,1-.727,1.331H17.206a.867.867,0,0,1-.763-.453c-1.41-2.605-2.627-4.944-2.627-4.944-.461,1.065-.72,1.439-2.634,4.951a.855.855,0,0,1-.756.453H7.916a.865.865,0,0,1-.727-1.331l4.339-6.729L7.189,17.458a.863.863,0,0,1,.727-1.331h2.5a.867.867,0,0,1,.763.453c1.878,3.512,1.439,2.418,2.634,4.929,0,0,.439-.842,2.634-4.929a.867.867,0,0,1,.763-.453h2.5a.858.858,0,0,1,.727,1.324Zm7.189-8.679v.439H18.423V0h.439a1.726,1.726,0,0,1,1.223.5L27.13,7.556A1.722,1.722,0,0,1,27.634,8.772Z"
          />
        </svg>
      </div>
      <div class="mr-3">
        <svg 
          class="doc-button" 
          xmlns="http://www.w3.org/2000/svg" 
          width="38.136" 
          height="36.845" 
          fill="#2196F3" 
          viewBox="0 0 38.136 36.845"
          @click="exportCsv"
        >
          <g transform="translate(-5.235 -6.75)">
            <path 
              class="a" 
              d="M72.215,57.874l-7.863-5.6a.535.535,0,0,0-.559-.04.567.567,0,0,0-.292.5l0,2.65H56.433a.561.561,0,0,0-.593.513v4.874a.558.558,0,0,0,.593.509h7.145v2.671a.514.514,0,0,0,.259.484.481.481,0,0,0,.529-.041l7.851-5.635a.522.522,0,0,0,.2-.442h0A.522.522,0,0,0,72.215,57.874Z" 
              transform="translate(-29.05 -26.076)"
            />
            <path 
              class="a" 
              d="M30.292,37.335H27.527a.737.737,0,0,0-.737.737h0v1.282H9.476V19.277h7.549a.737.737,0,0,0,.737-.737V10.991h9.025V26.477h0a.736.736,0,0,0,.735.72h2.764a.736.736,0,0,0,.735-.72h0v-.016h0V9.2h0V7.487a.737.737,0,0,0-.737-.737h-14L5.235,17.8V42.858a.737.737,0,0,0,.737.737H30.289a.737.737,0,0,0,.737-.737V41.815h0V38.071a.736.736,0,0,0-.736-.736Z" 
              transform="translate(0 0)"
            />
            <path 
              class="a" 
              d="M23.265,62.759a.834.834,0,0,1,.761.572l1.026-.478a1.834,1.834,0,0,0-1.786-1.144,2.038,2.038,0,1,0,0,4.067,1.855,1.855,0,0,0,1.786-1.15l-1.026-.472a.834.834,0,0,1-.761.572.985.985,0,0,1,0-1.968Z" 
              transform="translate(-9.108 -31.549)"
            />
            <path 
              class="a" 
              d="M33.114,64.784a1.833,1.833,0,0,1-1.268-.507l-.619.884a2.59,2.59,0,0,0,1.828.625c1.055,0,1.7-.5,1.7-1.368,0-1.462-2.187-1.144-2.187-1.515,0-.1.065-.177.3-.177a1.985,1.985,0,0,1,1.155.389l.637-.843a2.594,2.594,0,0,0-1.668-.548c-1.079,0-1.633.637-1.633,1.3,0,1.55,2.193,1.167,2.193,1.545,0,.153-.212.212-.436.212Z" 
              transform="translate(-14.921 -31.557)"
            />
            <path 
              class="a" 
              d="M41.452,61.862H40.1l1.45,3.932h1.5l1.45-3.932H43.15L42.3,64.509Z" 
              transform="translate(-20.015 -31.637)"
            />
          </g>
        </svg>
      </div>
      <div class="mr-3">
        <svg 
          class="doc-button" 
          xmlns="http://www.w3.org/2000/svg" 
          width="36.217" 
          height="32.595" 
          fill="#2196F3" 
          viewBox="0 0 36.217 32.595"
          @click="printDoc" 
        >
          <g transform="translate(-2 -3)">
            <path 
              class="a" 
              d="M32.784,12.054H7.433A5.425,5.425,0,0,0,2,17.487V24.73a3.632,3.632,0,0,0,3.622,3.622H9.243v3.622A3.632,3.632,0,0,0,12.865,35.6H27.352a3.632,3.632,0,0,0,3.622-3.622V28.352H34.6a3.632,3.632,0,0,0,3.622-3.622V17.487A5.425,5.425,0,0,0,32.784,12.054ZM25.541,31.974H14.676a1.816,1.816,0,0,1-1.811-1.811V22.919H27.352v7.243A1.816,1.816,0,0,1,25.541,31.974ZM32.784,19.3A1.811,1.811,0,1,1,34.6,17.487,1.816,1.816,0,0,1,32.784,19.3ZM29.163,3H11.054A1.816,1.816,0,0,0,9.243,4.811V8.433a1.816,1.816,0,0,0,1.811,1.811H29.163a1.816,1.816,0,0,0,1.811-1.811V4.811A1.816,1.816,0,0,0,29.163,3Z" 
              transform="translate(0 0)"
            />
          </g>
        </svg>
      </div>
      <div class="pl-3 align-self-start border-left">
        <div class="field-wrapper toggle-pass d-flex align-self-start align-self-lg-center align-items-center justify-content-center">
            <p class="mb-2 mr-2 d-inline-block custom-label">Page break</p>
            <label class="switch s-primary">
              <input 
                id="toggle-password"
                type="checkbox"
                class="d-none"
                @click="isPageBreak = !isPageBreak"
              >
              <span class="slider round"></span>
            </label>
          </div>
      </div>
    </div>
    <!-- Table export buttons -->

    <!-- Table data -->
    <div 
      id="attendance-detail" 
      class="document p-3 w-100"
    >

      <!-- Table title -->
      <div class="document__title w-100 text-center">
        <h5 class="w-100 text-uppercase text-dark font-weight-bold">Laporan Rangkuman Kehadiran</h5>
        <p class="w-100">{{ params.reportType.dateRange }}</p>
      </div>
      <!-- Table title -->

      <div class="d-flex flex-row">

        <!-- Employee detail -->
        <div 
          v-if="params.reportType.title === 'Karyawan'"
          class="document__subtitle mb-5"
        >
          <p class="text-dark mb-2 font-weight-bold">Karyawan</p>
        </div>
        <!-- Employee detail -->

        <!-- Man Power detail -->
        <div 
          v-if="params.reportType.title === 'Man Power'"
          class="document__subtitle mb-5"
        >
          <p class="text-dark mb-2 font-weight-bold">Manpower</p>
          <div class="d-flex flex-row">
            <div 
              v-if="params.reportType.detail.type"
              class="mr-5 text-small"
            >
              <p class="mb-0">Tipe</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.type }}</p>
            </div>
            <div 
              v-if="params.reportType.detail.name"
              class="mr-5 text-small"
            >
              <p class="mb-0">Nama</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.name }}</p>
            </div>
            <div 
              v-if="params.reportType.detail.team"
              class="mr-5 text-small"
            >
              <p class="mb-0">Team</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.team }}</p>
            </div>
            <div 
              v-if="params.reportType.detail.group"
              class="mr-5 text-small"
            >
              <p class="mb-0">Group</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.group }}</p>
            </div>
          </div>
        </div>
        <!-- Man Power detail -->

      </div>

      <p class="text-right hide-export">*Centang pada judul kolom untuk menampilkan data saat <i>export</i> / <i>print</i> </p>
      
      <div class="document__data">
          <div>

            <!-- Attendance report -->
            <table class="table">
              <thead>
                <tr>
                  <th>ID Karyawan</th>
                  <th>Nama</th>
                  <th :class="hideColumn.shiftTarget ? 'hide-export' : ''">
                    <label for="shift-target">Target Jam Kerja</label>
                    <input 
                      id="shift-target"
                      class="hide-export"
                      type="checkbox" 
                      name="shift-target" 
                      v-model="hideColumn.shiftTarget"
                    >
                  </th>
                  <th :class="hideColumn.actualShift ? 'hide-export' : ''">
                    <label for="actual-shift">Aktual Jam Kerja</label>
                    <input 
                      id="actual-shift"
                      class="hide-export"
                      type="checkbox" 
                      name="actual-shift" 
                      v-model="hideColumn.actualShift"
                    >
                  </th>
                  <th :class="hideColumn.workday ? 'hide-export' : ''">
                    <label for="work-days">Hari Kerja</label>
                    <input 
                      id="work-days"
                      class="hide-export"
                      type="checkbox" 
                      name="work-days" 
                      v-model="hideColumn.workday"
                    >
                  </th>
                  <th :class="hideColumn.attendance ? 'hide-export' : ''">
                    <label for="attendance">Kehadiran</label>
                    <input 
                      id="attendance"
                      class="hide-export"
                      type="checkbox" 
                      name="attendance" 
                      v-model="hideColumn.attendance"
                    >
                  </th>
                  <th :class="hideColumn.lateIn ? 'hide-export' : ''">
                    <label for="late-in">Terlambat</label>
                    <input 
                      id="late-in"
                      class="hide-export"
                      type="checkbox" 
                      name="late-in" 
                      v-model="hideColumn.lateIn"
                    >
                  </th>
                  <th :class="hideColumn.earlyOut ? 'hide-export' : ''">
                    <label for="early-out">Pulang Cepat</label>
                    <input 
                      id="early-out"
                      class="hide-export"
                      type="checkbox" 
                      name="early-out" 
                      v-model="hideColumn.earlyOut"
                    >
                  </th>
                  <th :class="hideColumn.workdayOvertime ? 'hide-export' : ''">
                    <label for="workday-overtime">Lembur Hari Kerja</label>
                    <input 
                      id="workday-overtime"
                      class="hide-export"
                      type="checkbox" 
                      name="workday-overtime" 
                      v-model="hideColumn.workdayOvertime"
                    >
                  </th>
                  <th :class="hideColumn.holidayOvertime ? 'hide-export' : ''">
                    <label for="holidays-overtime">Lembur Hari Libur</label>
                    <input 
                      id="holidays-overtime"
                      class="hide-export"
                      type="checkbox" 
                      name="holidays-overtime" 
                      v-model="hideColumn.holidayOvertime"
                    >
                  </th>
                  <th :class="hideColumn.sickLeave ? 'hide-export' : ''">
                    <label for="sick-leave">Sakit</label>
                    <input 
                      id="sick-leave"
                      class="hide-export"
                      type="checkbox" 
                      name="sick-leave" 
                      v-model="hideColumn.sickLeave"
                    >
                  </th>
                  <th :class="hideColumn.paidLeave ? 'hide-export' : ''">
                    <label for="paid-leave">Cuti</label>
                    <input 
                      id="paid-leave"
                      class="hide-export"
                      type="checkbox" 
                      name="paid-leave" 
                      v-model="hideColumn.paidLeave"
                    >
                  </th>
                  <th :class="hideColumn.unpaidLeave ? 'hide-export' : ''">
                    <label for="unpaid-leave">Cuti Tidak Berbayar</label>
                    <input 
                      id="unpaid-leave"
                      class="hide-export"
                      type="checkbox" 
                      name="unpaid-leave" 
                      v-model="hideColumn.unpaidLeave"
                    >
                  </th>
                  <th :class="hideColumn.forgotCheckIn ? 'hide-export' : ''">
                    <label for="forgot-checkin">Lupa Absen Masuk</label>
                    <input 
                      id="forgot-checkin"
                      class="hide-export"
                      type="checkbox" 
                      name="forgot-checkin" 
                      v-model="hideColumn.forgotCheckIn"
                    >
                  </th>
                  <th :class="hideColumn.forgotCheckOut ? 'hide-export' : ''">
                    <label for="forgot-checkout">Lupa Absen Pulang</label>
                    <input 
                      id="forgot-checkout"
                      class="hide-export"
                      type="checkbox" 
                      name="forgot-checkout" 
                      v-model="hideColumn.forgotCheckOut"
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="report in data" 
                  :key="report.id"
                >
                  <td>{{ report.employee_number_formatted }}</td>
                  <td>{{ report.employee_name }}</td>
                  <td :class="hideColumn.shiftTarget ? 'hide-export' : ''">{{ report.target_jam_kerja }}</td>
                  <td :class="hideColumn.actualShift ? 'hide-export' : ''">{{ report.aktual_jam_kerja }}</td>
                  <td :class="hideColumn.workday ? 'hide-export' : ''">{{ report.hari_kerja }}</td>
                  <td :class="hideColumn.attendance ? 'hide-export' : ''">{{ report.kehadiran }}</td>
                  <td :class="hideColumn.lateIn ? 'hide-export' : ''">{{ timeFormatting(report.total_late_in_minutes) }}</td>
                  <td :class="hideColumn.earlyOut ? 'hide-export' : ''">{{ timeFormatting(report.total_early_out_minutes) }}</td>
                  <td :class="hideColumn.workdayOvertime ? 'hide-export' : ''">{{ timeFormatting(report.lembur_hari_kerja_in_minutes) }}</td>
                  <td :class="hideColumn.holidayOvertime ? 'hide-export' : ''">{{ timeFormatting(report.lembur_hari_libur_in_minutes) }}</td>
                  <td :class="hideColumn.sickLeave ? 'hide-export' : ''">{{ report.total_sakit }}</td>
                  <td :class="hideColumn.paidLeave ? 'hide-export' : ''">{{ report.total_cuti }}</td>
                  <td :class="hideColumn.unpaidLeave ? 'hide-export' : ''">{{ report.total_cuti_tidak_berbayar }}</td>
                  <td :class="hideColumn.forgotCheckIn ? 'hide-export' : ''">{{ report.total_lupa_absen_datang }}</td>
                  <td :class="hideColumn.forgotCheckOut ? 'hide-export' : ''">{{ report.total_lupa_absen_pulang }}</td>
                </tr>
              </tbody>
            </table>
            <!-- Attendance report -->

            <div class="data__page-break" :style="{ pageBreakAfter: isPageBreak ? 'always' : 'auto' }"></div>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- Table data -->

  </div>
</template>

<script>
import { mapState } from 'vuex'

import XLSX from 'xlsx'

export default {
  name: 'AttendanceSummaryDoc',
  data() {
    return {
      isPageBreak: false,
      hideColumn: {
        shiftTarget: true,
        actualShift: true,
        workday: true,
        attendance: true,
        lateIn: true,
        earlyOut: true,
        workdayOvertime: true,
        holidayOvertime: true,
        paidLeave: true,
        unpaidLeave: true,
        sickLeave: true,
        forgotCheckIn: true,
        forgotCheckOut: true
      }
    }
  },
  computed:{
    ...mapState('reportAttendanceSummary', ['data', 'params'])
  },
  methods: {
    printDoc() {
      window.onbeforeprint = () => document.title = this.params.fileName
      window.print()
      window.onafterprint = () => document.title = 'Datangg - Rangkuman Kehadiran'
    },
    reportSummaryCsv() {
      const hiddenColumnStatus = this.hideColumn
      let filteredData
      let filteredDataTitle = ['ID Karyawan', 'Nama']
      let stringTitle = ''

      for (const key in hiddenColumnStatus) {
        if (hiddenColumnStatus.hasOwnProperty.call(hiddenColumnStatus, key)) {
          
          filteredData = [this.data.map(report => {
            let arrayContainer = []
            let stringValue = ''

            arrayContainer.push(report.employee_number_formatted)
            arrayContainer.push(report.employee_name)

            if (hiddenColumnStatus['shiftTarget']) {
              arrayContainer.push(report.target_jam_kerja)
            }
            if (hiddenColumnStatus['actualShift']) {
              arrayContainer.push(report.aktual_jam_kerja)
            }
            if (hiddenColumnStatus['workday']) {
              arrayContainer.push(report.hari_kerja)
            }
            if (hiddenColumnStatus['attendance']) {
              arrayContainer.push(report.kehadiran)
            }
            if (hiddenColumnStatus['lateIn']) {
              arrayContainer.push(report.total_late_in_minutes)
            }
            if (hiddenColumnStatus['earlyOut']) {
              arrayContainer.push(report.total_early_out_minutes)
            }
            if (hiddenColumnStatus['workdayOvertime']) {
              arrayContainer.push(report.lembur_hari_kerja_in_minutes)
            }
            if (hiddenColumnStatus['holidayOvertime']) {
              arrayContainer.push(report.lembur_hari_libur_in_minutes)
            }
            if (hiddenColumnStatus['sickLeave']) {
              arrayContainer.push(report.total_sakit)
            }
            if (hiddenColumnStatus['paidLeave']) {
              arrayContainer.push(report.total_cuti)
            }
            if (hiddenColumnStatus['unpaidLeave']) {
              arrayContainer.push(report.total_cuti_tidak_berbayar)
            }
            if (hiddenColumnStatus['forgotCheckIn']) {
              arrayContainer.push(report.total_lupa_absen_datang)
            }
            if (hiddenColumnStatus['forgotCheckOut']) {
              arrayContainer.push(report.total_lupa_absen_pulang)
            }
            
            for (let index = 0; index < arrayContainer.length; index++) {
              stringValue += `${arrayContainer[index]},`
            }
            return [stringValue.substring(0, stringValue.length-1)]
          })]

        }
      }

      if (hiddenColumnStatus['shiftTarget']) {
        filteredDataTitle.push('Target Jam Kerja')
      }
      if (hiddenColumnStatus['actualShift']) {
        filteredDataTitle.push('Aktual Jam Kerja')
      }
      if (hiddenColumnStatus['workday']) {
        filteredDataTitle.push('Hari Kerja')
      }
      if (hiddenColumnStatus['attendance']) {
        filteredDataTitle.push('Kehadiran')
      }
      if (hiddenColumnStatus['lateIn']) {
        filteredDataTitle.push('Terlambat')
      }
      if (hiddenColumnStatus['earlyOut']) {
        filteredDataTitle.push('Pulang Cepat')
      }
      if (hiddenColumnStatus['workdayOvertime']) {
        filteredDataTitle.push('Lembur Hari Kerja')
      }
      if (hiddenColumnStatus['holidayOvertime']) {
        filteredDataTitle.push('Lembur Hari Libur')
      }
      if (hiddenColumnStatus['sickLeave']) {
        filteredDataTitle.push('Sakit')
      }
      if (hiddenColumnStatus['paidLeave']) {
        filteredDataTitle.push('Cuti')
      }
      if (hiddenColumnStatus['unpaidLeave']) {
        filteredDataTitle.push('Cuti Tidak Berbayar')
      }
      if (hiddenColumnStatus['forgotCheckIn']) {
        filteredDataTitle.push('Lupa Absen Datang')
      }
      if (hiddenColumnStatus['forgotCheckOut']) {
        filteredDataTitle.push('Lupa Absen Pulang')
      }
      for (let index = 0; index < filteredDataTitle.length; index++) {
        stringTitle += `${filteredDataTitle[index]},`
      }
      stringTitle = [stringTitle.substring(0, stringTitle.length-1)]

      filteredData.unshift([stringTitle])
      
      return filteredData
    },
    exportCsv() {
      const filteredData = this.reportSummaryCsv()

      let csvContent = `data:text/csv;charset=utf-8,`
      filteredData.flat().forEach(function(rowArray) {
        let row = rowArray.join(',')
        csvContent += row + '\r\n'
      })
      
      let encodedURI = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedURI)
      link.setAttribute('download', `${this.params.fileName}.csv`)
      document.body.appendChild(link)
      link.click()
    },
    reportSummaryExcel() {
      const hiddenColumnStatus = this.hideColumn
      let dataLength
      let filteredData
      const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const xlsHeader = []

      for (const key in hiddenColumnStatus) {
        if (hiddenColumnStatus.hasOwnProperty.call(hiddenColumnStatus, key)) {
          
          filteredData = [this.data.map(report => {
            let arrayContainer = []

            arrayContainer.push({
              title: 'ID Karyawan',
              value: report.employee_number_formatted
            })
            arrayContainer.push({
              title: 'Nama',
              value: report.employee_name
            })

            if (hiddenColumnStatus['shiftTarget']) {
              arrayContainer.push({
                title: 'Target Jam Kerja',
                value: report.target_jam_kerja
              })
            }
            if (hiddenColumnStatus['actualShift']) {
              arrayContainer.push({
                title: 'Aktual Jam Kerja',
                value: report.aktual_jam_kerja
              })
            }
            if (hiddenColumnStatus['workday']) {
              arrayContainer.push({
                title: 'Hari Kerja',
                value: report.hari_kerja
              })
            }
            if (hiddenColumnStatus['attendance']) {
              arrayContainer.push({
                title: 'Kehadiran',
                value: report.kehadiran
              })
            }
            if (hiddenColumnStatus['lateIn']) {
              arrayContainer.push({
                title: 'Terlambat',
                value: this.timeFormatting(report.total_late_in_minutes)
              })
            }
            if (hiddenColumnStatus['earlyOut']) {
              arrayContainer.push({
                title: 'Pulang Cepat',
                value: this.timeFormatting(report.total_early_out_minutes)
              })
            }
            if (hiddenColumnStatus['workdayOvertime']) {
              arrayContainer.push({
                title: 'Lembur Hari Kerja',
                value: this.timeFormatting(report.lembur_hari_kerja_in_minutes)
              })
            }
            if (hiddenColumnStatus['holidayOvertime']) {
              arrayContainer.push({
                title: 'Lembur Hari Libur',
                value: this.timeFormatting(report.lembur_hari_libur_in_minutes)
              })
            }
            if (hiddenColumnStatus['sickLeave']) {
              arrayContainer.push({
                title: 'Sakit',
                value: report.total_sakit
              })
            }
            if (hiddenColumnStatus['paidLeave']) {
              arrayContainer.push({
                title: 'Cuti',
                value: report.total_cuti
              })
            }
            if (hiddenColumnStatus['unpaidLeave']) {
              arrayContainer.push({
                title: 'Cuti Tidak Berbayar',
                value: report.total_cuti_tidak_berbayar
              })
            }
            if (hiddenColumnStatus['forgotCheckIn']) {
              arrayContainer.push({
                title: 'Lupa Absen Datang',
                value: report.total_lupa_absen_datang
              })
            }
            if (hiddenColumnStatus['forgotCheckOut']) {
              arrayContainer.push({
                title: 'Lupa Absen Pulang',
                value: report.total_lupa_absen_pulang
              })
            }

            dataLength = arrayContainer.length
            
            return arrayContainer
          })]

        }
      }

      for (let index = 0; index < dataLength; index++) {
        xlsHeader.push(alphabet[index])
        
      }

      let titleObj = {}
      for (let index = 0; index < dataLength; index++) {
        Object.assign(titleObj, {
          [xlsHeader[index]]: filteredData[0][0][index].title 
        })
      }
      let emptyTitle = {}
      for (let index = 0; index < dataLength; index++) {
        Object.assign(emptyTitle, {
          [xlsHeader[index]]: '' 
        })
      }
      let valuesArr = []
      for (let i = 0; i < filteredData[0].length; i++) {
        let valuesObj = {}
        for (let j = 0; j < dataLength; j++) {
          Object.assign(valuesObj, {
            [xlsHeader[j]]: filteredData[0][i][j].value 
          })
        }
        valuesArr.push(valuesObj)
      }
      valuesArr.unshift(titleObj)
      let xlsVal = [valuesArr]

      let worksheet = XLSX.utils.json_to_sheet(
        [],
        {
          header: xlsHeader, 
          skipHeader: true
        }
      )

      XLSX.utils.sheet_add_json(
        worksheet, 
        [{ 
          A: "Laporan Rangkuman Kehadiran",
        }], 
        {skipHeader: true, origin: -1}
      )

      XLSX.utils.sheet_add_json(
        worksheet, 
        [{ 
          A: this.params.reportType.dateRange,
        }], 
        {skipHeader: true, origin: -1}
      )

      XLSX.utils.sheet_add_json(
        worksheet, 
        [{ 
          A: "",
        }], 
        {skipHeader: true, origin: -1}
      )

      for (let i = 0; i < xlsVal.length; i++) {
        XLSX.utils.sheet_add_json(
          worksheet, 
          xlsVal[i],
          {skipHeader: true, origin: -1}
        )
        XLSX.utils.sheet_add_json(
          worksheet, 
          [emptyTitle], 
          {skipHeader: true, origin: -1}
        )
      }

      return worksheet
    },
    exportExcel() {
      let new_workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(new_workbook, this.reportSummaryExcel(), "Sheet")
      XLSX.writeFile(new_workbook, `${this.params.fileName}.xlsx`)
    },
    timeFormatting(minutes) {
      let hour = Math.floor(minutes / 60)
      let minute = Math.floor(minutes % 60)

      if (String(hour).length == 1) hour = `0${hour}`
      if (String(minute).length == 1) minute = `0${minute}`

      return `${hour}:${minute}`
    }
  }
}
</script>

<style lang="sass" scoped>
@media print 
  *
    color-adjust: exact !important

  .doc-button-container
    display: none !important

  .document
    // padding: 1cm

  .hide-export
    display: none


@media screen
  .doc-button-container
    position: fixed
    top: 0
    left: 0
    padding: 1rem
    border-radius: 0 0 5px 0
    box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.3)
    background-color: #FFFFFF

    .doc-button
      cursor: pointer

      &:hover
        fill: #11368F

@media screen, print
  .text-small
    font-size: 13px
    
  table

    thead

      tr

        th, th > *
          color: #000000 !important
          font-size: 0.8rem !important
          text-transform: none !important

    tbody

      tr

        td
          color: #212121 !important
          font-size: 0.8rem !important
          font-weight: 500 !important
    
    tfoot
      display: table-row-group !important

      tr

        th
          color: #FFFFFF !important
          font-size: 0.8rem !important
          text-transform: uppercase !important
          background-color: #2196F3 !important
          font-weight: bolder !important
</style>