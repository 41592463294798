<template>
  <div 
    class="fullpage-loader position-fixed h-100 w-100 align-items-center justify-content-center" 
      :class="pageLoader ? 'd-flex' : 'd-none'"
    >
    <div 
      class="spinner-grow text-white"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseFullLoader',
  computed: {
    ...mapState('styling', ['pageLoader'])
  }
}
</script>

<style lang="sass" scoped>
.fullpage-loader
  top: 0
  left: 0
  background-color: (rgba(0, 0, 0, .6))
  z-index: 1000000

  .spinner-grow
    width: 3rem
    height: 3rem
</style>