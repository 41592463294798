<template>
  <!--  BEGIN MAIN CONTAINER  -->
  <div 
    id="container"
    class="main-container" 
    :class="!sidebarMobileOpen ? 'sidebar-closed' : sidebarMobileOpen ? 'sbar-open' : !sidebarOpen ? 'sidebar-closed sbar-open' : ''" 
  >
    <div 
      class="overlay" 
      :class="{'show' : sidebarMobileOpen}"
      @click="TOGGLE_SIDEBAR_MOBILE(); TOGGLE_SIDEBAR();" 
    ></div>
    <div class="cs-overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <div 
      class="sidebar-wrapper sidebar-theme" 
      @mouseleave="leaveDropdown" 
      @mouseenter="toggleDropdownState($route.path)"
    >
      <nav 
        id="sidebar" 
        class="h-100 d-flex flex-column justify-content-between"
      >
        <div>

          <!-- Datangg logo -->
          <ul class="navbar-nav theme-brand flex-row text-center">
            <li class="nav-item theme-logo">
              <router-link to="/">
                <img 
                  src="@/assets/img/bpp/icon-datangg.png" 
                  class="navbar-logo" 
                  alt="Datangg logo"
                />
              </router-link>
            </li>
            <li class="nav-item theme-text">
              <router-link 
                to="/" 
                class="nav-link"
              >
                Datangg
              </router-link>
            </li>
          </ul>
          <!-- Datangg logo -->

          <ul 
            id="accordionExample"
            class="list-unstyled menu-categories" 
          >

            <!-- Dashboard / home button -->
            <li 
              class="menu" 
              :class="{'active' : $route.path === '/'}"
            >
              <router-link 
                to="/" 
                data-toggle="collapse"
                class="dropdown-toggle"
                :aria-expanded="$route.path === '/' ? 'true' : 'false'"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                    <polyline points="9 22 9 12 15 12 15 22" />
                  </svg>
                  <span>Dashboard</span>
                </div>
              </router-link>
            </li>
            <!-- Dashboard / home button -->

            <!-- Announcement button -->
            <li 
              v-if="userAccess.allow_announcement" 
              class="menu" 
              :class="{'active' : $route.path === '/announcement'}"
            >
              <router-link 
                to="/announcement" 
                data-toggle="collapse"
                class="dropdown-toggle"
                :aria-expanded="$route.path === '/annoncement' ? 'true' : 'false'"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-mail"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                    <polyline points="22,6 12,13 2,6"/>
                  </svg>
                  <span>Pengumuman</span>
                </div>
              </router-link>
            </li>
            <!-- Announcement button -->

            <!-- Employee button -->
            <li 
              v-if="userAccess.allow_employee" 
              class="menu" 
              :class="{'active' : $route.path === '/employee'}"
            >
              <router-link 
                to="/employee" 
                data-toggle="collapse"
                class="dropdown-toggle"
                :aria-expanded="$route.path === '/employee' ? 'true' : 'false'"
              >
                <div>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-users"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                  <circle cx="9" cy="7" r="4"/>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                </svg>
                  <span>Karyawan</span>
                </div>
              </router-link>
            </li>
            <!-- Employee button -->

            <!-- Sync button -->
            <li 
              class="menu" 
              :class="{'active' : syncChild($route.path)}"
            >
              <router-link 
                to="/sync" 
                data-toggle="collapse"
                class="dropdown-toggle"
                :aria-expanded="syncChild($route.path) ? 'true' : 'false'"
              >
                <div>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-refresh-cw"
                >
                  <polyline points="23 4 23 10 17 10"></polyline>
                  <polyline points="1 20 1 14 7 14"></polyline>
                  <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                </svg>
                  <span>Sync</span>
                </div>
              </router-link>
            </li>
            <!-- Sync button -->

            <!-- Leave Adjustment button -->
            <!-- <li
              v-if="userAccess.allow_adjustment" 
              class="menu"
              :class="{'active' : $route.path === '/leave-adjustment' || $route.path === `/leave-adjustment/${$route.params.leaveAdjustmentId}`}"
            >
              <router-link 
                to="/leave-adjustment" 
                data-toggle="collapse"
                class="dropdown-toggle"
                :aria-expanded="$route.path === '/leave-adjustment' ? 'true' : 'false'"
              >
                <div>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="feather feather-archive"
                  >
                    <polyline points="21 8 21 21 3 21 3 8"></polyline>
                    <rect x="1" y="3" width="22" height="5"></rect>
                    <line x1="10" y1="12" x2="14" y2="12"></line>
                  </svg>
                  <span>Penyesuaian Cuti</span>
                </div>
              </router-link>
            </li> -->
            <!-- Leave Adjustment button -->

            <!-- Adjustment dropdown -->
            <li 
              v-if="userAccess.allow_adjustment || userAccess.allow_attendance_adjustment"
              class="menu" 
              :class="{'active' : adjustmentChild($route.path)}"
            >
              <a
                data-toggle="collapse"
                :aria-expanded="sidebarDropdown.adjustment ? 
                'true' : 'false'"
                :class="sidebarDropdown.adjustment ? 'dropdown-toggle' : 'dropdown-toggle collapsed'"
                @click="eachDropdownState('adjustment')"
              >
                <div>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="feather feather-archive"
                  >
                    <polyline points="21 8 21 21 3 21 3 8"></polyline>
                    <rect x="1" y="3" width="22" height="5"></rect>
                    <line x1="10" y1="12" x2="14" y2="12"></line>
                  </svg>
                  <span>Penyesuaian</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </a>

              <ul
                id="adjustment"
                data-parent="#accordionExample"
                :class="sidebarDropdown.adjustment ? 'collapse submenu list-unstyled show' : 'collapse submenu list-unstyled'"
              >
                <li
                  v-if="userAccess.allow_adjustment"
                  :class="{'active' : $route.path === '/adjustment/leave' || $route.path === `/adjustment/leave/${$route.params.leaveAdjustmentId}`}"
                >
                  <router-link to="/adjustment/leave">Cuti Manual</router-link>
                </li>
                <li
                  v-if="userAccess.allow_adjustment"
                  :class="{'active' : $route.path === '/adjustment/leave-balance'}"
                >
                  <router-link to="/adjustment/leave-balance">Saldo Cuti</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_attendance_adjustment"
                  :class="{'active' : $route.path === '/adjustment/attendance' || $route.path === '/adjustment/attendance/edit' || $route.path === '/adjustment/attendance/list' || $route.path === `/adjustment/attendance/detail/${$route.params.attendanceAdjustmentDetailId}`}"
                >
                  <router-link to="/adjustment/attendance">Kehadiran</router-link>
                </li>
              </ul>

            </li>
            <!-- Adjustment dropdown -->

            <!-- Approval dropdown -->
            <li 
              v-if="userAccess.allow_approved_attendance || userAccess.allow_rejected_attendance || userAccess.allow_permission"
              class="menu" 
              :class="{'active' : $route.path === '/approval/attendance' || $route.path === '/approval/permission'}"
            >
              <a
                data-toggle="collapse"
                :aria-expanded="sidebarDropdown.approval ? 
                'true' : 'false'"
                :class="sidebarDropdown.approval ? 'dropdown-toggle' : 'dropdown-toggle collapsed'"
                @click="eachDropdownState('approval')"
              >
                <div>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="feather feather-calendar"
                  >
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                    <line x1="16" y1="2" x2="16" y2="6"/>
                    <line x1="8" y1="2" x2="8" y2="6"/>
                    <line x1="3" y1="10" x2="21" y2="10"/>
                  </svg>
                  <span>Persetujuan</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </a>

              <ul
                id="approval"
                data-parent="#accordionExample"
                :class="sidebarDropdown.approval ? 'collapse submenu list-unstyled show' : 'collapse submenu list-unstyled'"
              >
                <li
                  v-if="userAccess.allow_approved_attendance || userAccess.allow_rejected_attendance"
                  :class="{'active' : $route.path === '/approval/attendance'}"
                >
                  <router-link to="/approval/attendance">Kehadiran</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_permission"
                  :class="{'active' : $route.path === '/approval/permission'}"
                >
                  <router-link to="/approval/permission">Izin</router-link>
                </li>
              </ul>

            </li>
            <!-- Approval dropdown -->

            <!-- Report dropdown -->
            <li 
              v-if="userAccess.allow_report || userAccess.allow_reset_attendance" class="menu"  
              :class="{'active': $route.path === '/report/attendance-adjustment' || attendanceDetailChild($route.path) || $route.path === '/report/attendance-log' || attendanceSummaryChild($route.path)}"
            >
              <a
                data-toggle="collapse"
                :aria-expanded="sidebarDropdown.report ? 
                'true' : 'false'"
                :class="sidebarDropdown.report ? 'dropdown-toggle' : 'dropdown-toggle collapsed'"
                @click="eachDropdownState('report')"
              >
                <div>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="feather feather-clipboard"
                  >
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"/>
                  </svg>
                  <span>Laporan</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </a>

              <ul
                id="report"
                data-parent="#accordionExample"
                :class="sidebarDropdown.report ? 'collapse submenu list-unstyled show' : 'collapse submenu list-unstyled'"
              >
                <li 
                  v-if="userAccess.allow_report" 
                  :class="{'active': attendanceDetailChild($route.path)}"
                >
                  <router-link to="/report/attendance-detail">Detail Kehadiran</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_reset_attendance" 
                  :class="{'active' : $route.path === '/report/attendance-log'}"
                >
                  <router-link to="/report/attendance-log">Riwayat Kehadiran</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_report" 
                  :class="{'active': attendanceSummaryChild($route.path)}"
                >
                  <router-link to="/report/attendance-summary">Rangkuman Kehadiran</router-link>
                </li>
                <li 
                  :class="{'active': $route.path === '/report/attendance-adjustment'}"
                >
                  <router-link to="/report/attendance-adjustment">Penyesuaian Kehadiran</router-link>
                </li>
              </ul>

            </li>
            <!-- Report dropdown -->

            <!-- Settings dropdown -->
            <li 
              v-if="userAccess.allow_setting_holiday || userAccess.allow_cuti_khusus || userAccess.allow_cuti_tambahan || userAccess.allow_setting_shift || userAccess.allow_setting_shift_pattern || userAccess.allow_system_trouble" 
              class="menu" 
              :class="{'active': $route.path === '/settings/holiday' || $route.path === '/settings/shift' || $route.path === '/settings/permission'}"
            >
              <a
                data-toggle="collapse"
                :aria-expanded="sidebarDropdown.settings ? 
                'true' : 'false'"
                :class="sidebarDropdown.settings ? 'dropdown-toggle' : 'dropdown-toggle collapsed'"
                @click="eachDropdownState('settings')"
              >
                <div>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="feather feather-settings"
                  >
                    <circle cx="12" cy="12" r="3"/>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
                  </svg>
                  <span>Pengaturan</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </a>

              <ul
                id="settings"
                data-parent="#accordionExample"
                :class="sidebarDropdown.settings ? 'collapse submenu list-unstyled show' : 'collapse submenu list-unstyled'"
              >
                <li 
                  v-if="userAccess.allow_setting_holiday" 
                  :class="{'active' : $route.path === '/settings/holiday'}"
                >
                  <router-link to="/settings/holiday">Hari Libur</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_cuti_khusus || userAccess.allow_cuti_tambahan || userAccess.allow_system_trouble" 
                  :class="{'active' : $route.path === '/settings/permission'}"
                >
                  <router-link to="/settings/permission">Izin</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_setting_shift || userAccess.allow_setting_shift_pattern" 
                  :class="{'active' : $route.path === '/settings/shift'}"
                >
                  <router-link to="/settings/shift">Jadwal Kerja</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_blacklist_app" 
                  :class="{'active' : $route.path === '/settings/blacklist-app'}"
                >
                  <router-link to="/settings/blacklist-app">Blacklist App</router-link>
                </li>
              </ul>

            </li>
            <!-- Settings dropdown -->

            <!-- System administration dropdown -->
            <li 
              v-if="userAccess.allow_user_login || userAccess.allow_user_level" 
              class="menu" 
              :class="{'active' : $route.path === '/system-admin/user-level' || $route.path === '/system-admin/user-login'}"
            >
              <a
                data-toggle="collapse"
                :aria-expanded="sidebarDropdown.sysadm ? 
                'true' : 'false'"
                :class="sidebarDropdown.sysadm ? 'dropdown-toggle' : 'dropdown-toggle collapsed'"
                @click="eachDropdownState('sysadm')"
              >
                <div>
                  <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-file-plus"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                  <polyline points="14 2 14 8 20 8"/>
                  <line x1="12" y1="18" x2="12" y2="12"/>
                  <line x1="9" y1="15" x2="15" y2="15"/>
                </svg>
                  <span>Sistem Administrasi</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </a>

              <ul
                id="sysadm"
                data-parent="#accordionExample"
                :class="sidebarDropdown.sysadm ? 'collapse submenu list-unstyled show' : 'collapse submenu list-unstyled'"
              >
                <li
                  v-if="userAccess.allow_user_level" 
                  :class="{'active' : $route.path === '/system-admin/user-level'}"
                >
                  <router-link to="/system-admin/user-level">User Level</router-link>
                </li>
                <li 
                  v-if="userAccess.allow_user_login" 
                  :class="{'active' : $route.path === '/system-admin/user-login'}"
                >
                  <router-link to="/system-admin/user-login">User Login</router-link>
                </li>
              </ul>

            </li>
            <!-- System administration dropdown -->

          </ul>
        </div>

        <div>
          <ul 
            id="accordionExample"
            class="list-unstyled menu-categories" 
          >

            <!-- Guide button -->
            <li 
              class="menu" 
            >
              <a
                href="https://ptbppid.notion.site/accc4f0f6e7b4f048383293b22dd1c11?v=a19b541956034746b6139bc51b3b52ec&pvs=73"
                target="_blank"
                class="dropdown-toggle"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-info"
                  >
                    <circle cx="12" cy="12" r="10"/>
                    <line x1="12" y1="16" x2="12" y2="12"/>
                    <line x1="12" y1="8" x2="12.01" y2="8"/>
                  </svg>
                  <span>Panduan</span>
                </div>
              </a>
            </li>
            <!-- Guide button -->

            <!-- Credits button -->
            <li 
              class="menu" 
              :class="{'active' : $route.path === '/credits'}"
            >
              <router-link 
                to="/credits" 
                data-toggle="collapse"
                class="dropdown-toggle"
                :aria-expanded="$route.path === '/credits' ? 'true' : 'false'"
              >
                <div>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="feather feather-pen-tool"
                  >
                    <path d="M12 19l7-7 3 3-7 7-3-3z"/>
                    <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"/>
                    <path d="M2 2l7.586 7.586"/>
                    <circle cx="11" cy="11" r="2"/>
                  </svg>
                  <span>Credits</span>
                </div>
              </router-link>
            </li>
            <!-- Credits button -->

          </ul>
        </div>

      </nav>
    </div>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div 
      id="content" 
      class="main-content d-flex flex-column justify-content-between"
    >

      <slot></slot>

      <!-- Footer -->
      <div class="layout-px-spacing mt-auto">
        <div class="footer-wrapper w-100 d-flex justify-content-between">
          <div class="footer-section">
            <p class="">Copyright © 2020 PT Berdikari Pondasi Perkasa, All rights reserved.</p>
          </div>
          <div class="footer-section">
            <p>
              <span>Coded with </span>
              <span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="15.065" 
                  height="14.337" 
                  viewBox="0 0 15.065 14.337"
                >
                  <path 
                    d="M8.533,14.987l-.947-.862C4.221,11.074,2,9.062,2,6.593A3.558,3.558,0,0,1,5.593,3a3.912,3.912,0,0,1,2.94,1.365A3.912,3.912,0,0,1,11.472,3a3.558,3.558,0,0,1,3.593,3.593c0,2.469-2.221,4.481-5.585,7.539Z" 
                    transform="translate(-1 -2)" 
                    fill="none" 
                    stroke="#000" 
                    stroke-width="2"
                  />
                </svg>
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- Footer -->

    </div>
    <!--  END CONTENT AREA  -->
    
  </div>
  <!-- END MAIN CONTAINER -->
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'BaseSidebar',
  computed: {
    ...mapState('styling', ['sidebarOpen', 'sidebarMobileOpen', 'sidebarDropdown']),
    ...mapGetters('auth', ['userAccess'])
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_SIDEBAR_MOBILE', 'TOGGLE_SIDEBAR']),
    leaveDropdown() {
      if ( !this.sidebarOpen) {
        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false
      }
    },
    toggleDropdownState(routePath) {
      let routeCheck = this.$route.path === routePath
      let sysAdm = (routePath === '/system-admin/user-level') || (routePath === '/system-admin/user-login')
      let approval = (routePath === '/approval/permission') || (routePath === '/approval/attendance')
      let report = (routePath === '/report/attendance-detail') || 
                  (routePath === '/report/attendance-detail/overtime') || 
                  (routePath === '/report/attendance-detail/permission') || 
                  (routePath === '/report/attendance-detail/undisciplined') || 
                  (routePath === '/report/attendance-log') || 
                  (routePath === '/report/attendance-summary') || 
                  (routePath === '/report/attendance-summary/overtime') || 
                  (routePath === '/report/attendance-summary/permission') || 
                  (routePath === '/report/attendance-summary/undisciplined')
      let settings = (routePath === '/settings/holiday') || (routePath === '/settings/permission') || (routePath === '/settings/shift')
      let adjustment = (routePath === '/adjustment/leave') ||
      (routePath === `/adjustment/leave/${this.$route.params.leaveAdjustmentId}`) ||
      (routePath === '/adjustment/attendance') ||
      (routePath === '/adjustment/attendance/edit') ||
      (routePath === '/adjustment/attendance/list') ||
      (routePath === `/adjustment/attendance/detail/${this.$route.params.attendanceAdjustmentDetailId}`)


      if (routeCheck && adjustment) {
        this.sidebarDropdown.adjustment = true
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false
      } else if (routeCheck && approval) {
        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = true
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false
      } else if (routeCheck && report) {
        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = true
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false
      } else if (routeCheck && settings) {
        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = true
        this.sidebarDropdown.sysadm = false
      } else if (routeCheck && sysAdm) {
        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = true
      }
    },
    eachDropdownState(dropdown) {
      if (dropdown === 'adjustment') {

        this.sidebarDropdown.adjustment = !this.sidebarDropdown.adjustment
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false

      } else if (dropdown === 'approval') {

        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = !this.sidebarDropdown.approval
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false

      } else if (dropdown === 'report') {

        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = !this.sidebarDropdown.report
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = false

      } else if (dropdown === 'settings') {

        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = !this.sidebarDropdown.settings
        this.sidebarDropdown.sysadm = false

      } else if (dropdown === 'sysadm') {

        this.sidebarDropdown.adjustment = false
        this.sidebarDropdown.approval = false
        this.sidebarDropdown.report = false
        this.sidebarDropdown.settings = false
        this.sidebarDropdown.sysadm = !this.sidebarDropdown.sysadm

      }
    },
    attendanceDetailChild(route) {
      let routeCheck = route === '/report/attendance-detail' || 
                      route === '/report/attendance-detail/overtime' || 
                      route === '/report/attendance-detail/undisciplined' || 
                      route === '/report/attendance-detail/permission'
      return routeCheck
    },
    attendanceSummaryChild(route) {
      let routeCheck = route === '/report/attendance-summary'
      return routeCheck
    },
    adjustmentChild(route) {
      let routeCheck = route === '/adjustment/leave' ||
      route === `/adjustment/leave/${this.$route.params.leaveAdjustmentId}` ||
      route === '/adjustment/attendance' ||
      route === '/adjustment/attendance/edit' ||
      route === '/adjustment/attendance/list' ||
      route === `/adjustment/attendance/detail/${this.$route.params.attendanceAdjustmentId}` ||
      route === '/adjustment/attendance/manual-upload' ||
      route === `/adjustment/attendance/manual-upload/detail/${this.$route.params.manualAdjustmentId}` ||
      route === '/adjustment/leave-balance'
      return routeCheck
    },
    syncChild(route) {
      let routeCheck = route === '/sync' ||
      route === '/sync/employee' ||
      route === '/sync/attendance' ||
      route === '/sync/attendance/validation' ||
      route === '/sync/attendance/list' ||
      route === `/sync/attendance/detail/${this.$route.params.attendanceSyncId}`
      return routeCheck
    },
  }
}
</script>

<style lang="sass" scoped>
#container
  background-color: #f1f2f3

.theme-brand
  background-color: #000000 !important

a
  text-decoration: none !important
</style>
