<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal"
  >

    <template #body>
      <div class="w-100">

        <!-- Top section -->
        <div class="modal-main__top">
          <div class="row mb-3">
            <div class="col-12 col-md-8">
              <div class="note note-blue py-2 px-3 mb-2">
                <p class="text-white font-weight-bold mb-0">Catatan</p>
                <p class="mt-2 text-light">{{ detail.note ? detail.note : 'Tidak ada catatan' }}</p>
              </div>
              <div
                v-if="rejectState || detail.status_code === 'rejected'"
                class="note note-white py-2 px-3"
              >
                <p class="text-blue font-weight-bold mb-0">Catatan Penolakan</p>
                <textarea
                  name="reject-note"
                  id="reject-note"
                  class="w-100 custom-input"
                  :disabled="detail.status_code === 'rejected'"
                  v-model="detail.rejected_note"
                ></textarea>
              </div>
            </div>
            <div class="d-md-none w-100"></div>
            <div 
              class="d-none d-md-block col-md-4"
            >
              <div
                v-if="detail.status_code === 'manual_approved' || detail.status_code === 'rejected'"
                class="mb-3"
              >
                <BaseButton 
                  class="w-100 button button--danger" 
                  text="Undo"
                  :loading="undoLoadingState" 
                  @button-click="undoRequest(detail.hash_id)"
                > 
                  <template #icon>
                    <svg 
                      class="mr-1" 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="13.323" 
                      height="15.065" 
                      viewBox="0 0 13.323 15.065"
                    >
                      <path  
                        id="Path_688" 
                        data-name="Path 688" 
                        d="M5.34,4.468h2V7.025A7,7,0,1,1,6.3,17.036l1.619-1.185a5,5,0,1,0,.826-7.384h2.591v2h-6v-6Z" 
                        transform="translate(-5.339 -4.467)" 
                        fill="currentColor"
                      />
                    </svg>
                  </template>
                </BaseButton> 
              </div>
              <div class="d-flex flex-row justify-content-around">
                <template v-if="!rejectState && detail.status_code === 'approved'">
                  <BaseButton 
                    v-if="userAccess.allow_reject_valid_attendance"
                    class="button button--danger w-100" 
                    text="Tolak"
                    @button-click="setRejectState(true)"
                  />
                </template>
                <template v-else-if="rejectState && detail.status_code === 'approved'">
                  <BaseButton
                    class="button button--cancel"
                    text="Batal"
                    @button-click="setRejectState(false)"
                  />
                  <BaseButton 
                    class="button button--danger" 
                    text="Tolak"
                    :loading="rejectLoadingState"
                    @button-click="rejectRequest(detail.hash_id)"
                  /> 
                </template>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row flex-md-column">
            <div class="w-100 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
              <div>
                <p class="mb-0 small-label">ID Karyawan</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.employee_number_formatted }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Nama</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.employee_name }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Tipe Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_type }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Tipe Lokasi Kehadiran</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.attendance_location_type }}</p>
              </div>
            </div>
            <div class="w-100 d-flex flex-column">
              <div>
                <p class="mb-0 small-label">Nama Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_name }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Tim Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_team_name }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Grup Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_group_name }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Top section -->

        <!-- Middle section -->
        <div class="modal-main__middle w-100 my-3 d-flex flex-row justify-content-center">
          <div 
            class="w-one-third text-center title"
            @click="tabsToggle('fr')" 
          >
            <span :class="tabs.faceRecognition ? 'title__text title__text--active' : 'title__text'">
              <b class="d-none d-md-inline">Face Recognition</b>
              <b class="d-inline d-md-none">FR</b>
              <sup v-if="detail.fr_is_match == 0">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="13.06" 
                  height="13.06" 
                  viewBox="0 0 13.06 13.06"
                >
                  <path 
                    d="M21.06,14.53A6.53,6.53,0,1,1,14.53,8,6.53,6.53,0,0,1,21.06,14.53Zm-6.53,1.317a1.211,1.211,0,1,0,1.211,1.211A1.211,1.211,0,0,0,14.53,15.847Zm-1.15-4.354.2,3.581a.316.316,0,0,0,.316.3h1.278a.316.316,0,0,0,.316-.3l.2-3.581a.316.316,0,0,0-.316-.333H13.7a.316.316,0,0,0-.315.333Z" 
                    transform="translate(-8 -8)" 
                    fill="#d32f2f"
                  />
                </svg>
              </sup>
            </span>
            <hr :class="tabs.faceRecognition ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div 
            @click="tabsToggle('location')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.location ? 'title__text title__text--active' : 'title__text'">
              <b>Lokasi</b>
              <sup v-if="detail.is_valid_location == 0">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="13.06" 
                  height="13.06" 
                  viewBox="0 0 13.06 13.06"
                >
                  <path 
                    d="M21.06,14.53A6.53,6.53,0,1,1,14.53,8,6.53,6.53,0,0,1,21.06,14.53Zm-6.53,1.317a1.211,1.211,0,1,0,1.211,1.211A1.211,1.211,0,0,0,14.53,15.847Zm-1.15-4.354.2,3.581a.316.316,0,0,0,.316.3h1.278a.316.316,0,0,0,.316-.3l.2-3.581a.316.316,0,0,0-.316-.333H13.7a.316.316,0,0,0-.315.333Z" 
                    transform="translate(-8 -8)" 
                    fill="#d32f2f"
                  />
                </svg>
              </sup>
            </span>
            <hr :class="tabs.location ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div 
            @click="tabsToggle('shift-detail')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.shiftDetail ? 'title__text title__text--active' : 'title__text'">
              <b class="d-none d-md-inline">Detail Shift</b>
              <b class="d-inline d-md-none">Shift</b>
              <sup v-if="detail.is_valid_shift == 0">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="13.06" 
                  height="13.06" 
                  viewBox="0 0 13.06 13.06"
                >
                  <path 
                    d="M21.06,14.53A6.53,6.53,0,1,1,14.53,8,6.53,6.53,0,0,1,21.06,14.53Zm-6.53,1.317a1.211,1.211,0,1,0,1.211,1.211A1.211,1.211,0,0,0,14.53,15.847Zm-1.15-4.354.2,3.581a.316.316,0,0,0,.316.3h1.278a.316.316,0,0,0,.316-.3l.2-3.581a.316.316,0,0,0-.316-.333H13.7a.316.316,0,0,0-.315.333Z" 
                    transform="translate(-8 -8)" 
                    fill="#d32f2f"
                  />
                </svg>
              </sup>
            </span>
            <hr :class="tabs.shiftDetail ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div 
            @click="tabsToggle('service-type')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.serviceType ? 'title__text title__text--active' : 'title__text'">
              <svg 
                style="width:20px;height:20px" 
                viewBox="0 0 24 24"
              >
                <path 
                  fill="currentColor" 
                  d="M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z" 
                />
              </svg>
              <span class="font-weight-bold">{{ detail.service_type === 'personal' ? 'Personal' : 'Lend'}}</span>
            </span>
            <hr :class="tabs.serviceType ? 'title__divider title__divider--active' : 'title__divider'">
          </div>          
        </div>
        <!-- Middle section -->

        <!-- Bottom section -->
        <div class="modal-main__bottom w-100">

          <!-- FR section -->
          <div 
            v-if="tabs.faceRecognition" 
            class="w-100"
          >
            <div class="d-flex flex-row">
              <div class="mr-3">
                <p class="mb-0 text-black">Status</p>
                <p 
                  v-if="detail.fr_is_match == 0" 
                  class="text-danger font-weight-bold"
                >
                  <span>Tidak Sama </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
                      <g transform="translate(-1.182 -1.182)">
                        <circle cx="11.818" cy="11.818" r="11.818" transform="translate(2.364 2.364)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x1="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x2="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                      </g>
                    </svg>
                  </span>
                </p>
                <p 
                  v-else-if="detail.fr_is_match == 1" 
                  class="text-success font-weight-bold"
                >
                  <span>Sama </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 25.549">
                      <g transform="translate(-1.157 -1.139)">
                        <path d="M25.517,12.851v1.067a11.6,11.6,0,1,1-6.878-10.6" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                        <path d="M25.517,4.639l-11.6,11.61-3.48-3.48" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                      </g>
                    </svg>
                  </span>
                </p>
              </div>
              <div>
                <p class="mb-0 text-black">Akurasi</p>
                <p class="text-black font-weight-bold">{{ detail.fr_accuracy }}</p>
              </div>
            </div>
            <div class="w-100 d-flex flex-row justify-content-around">
              <img 
                :src="detail.employee_photo_url" 
                alt="Foto karyawan" 
                class="fr-image"
              >
              <img 
                :src="detail.log_photo" 
                alt="Foto karyawan" 
                class="fr-image"
              >
            </div>
          </div>
          <!-- FR section -->

          <!-- Location section -->
          <div 
            v-if="tabs.location" 
            class="w-100"
          >
            <div class="d-flex flex-row">
              <div class="mr-3">
                <p class="mb-0 text-black">Status</p>
                <p 
                  v-if="detail.is_valid_location == 0" 
                  class="text-danger font-weight-bold"
                >
                  <span>Tidak Valid </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
                      <g transform="translate(-1.182 -1.182)">
                        <circle cx="11.818" cy="11.818" r="11.818" transform="translate(2.364 2.364)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x1="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x2="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                      </g>
                    </svg>
                  </span>
                </p>
                <p 
                  v-if="detail.is_valid_location == 1" 
                  class="text-success font-weight-bold"
                >
                  <span>Valid </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 25.549">
                      <g transform="translate(-1.157 -1.139)">
                        <path d="M25.517,12.851v1.067a11.6,11.6,0,1,1-6.878-10.6" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                        <path d="M25.517,4.639l-11.6,11.61-3.48-3.48" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                      </g>
                    </svg>
                  </span>
                </p>
              </div>
              <div>
                <p class="mb-0 text-black">Jarak kehadiran</p>
                <p class="text-black font-weight-bold">{{ detail.distance }}</p>
              </div>
            </div>
            <div class="w-100 d-flex flex-column">
              <div class="mb-3 gmaps-container">
                <MapsLoader
                  :mapConfig="mapConfig"
                  :apiKey="apiKey"
                >
                  <template slot-scope="{ google, map }">
                    <MapsMarker
                      v-for="marker in markers"
                      :key="marker.id"
                      :marker="marker"
                      :specificMarker="specificMarker"
                      :google="google"
                      :map="map"
                    />
                    <MapsLine
                      v-for="line in lines"
                      :key="line.id"
                      :path.sync="line.path"
                      :google="google"
                      :map="map"
                    />
                  </template>
                </MapsLoader>
              </div>
              <div class="d-flex align-items-center justify-content-end">
                <BaseButton 
                  class="button button--primary mr-3"
                  text="Lokasi Kehadiran"
                  @button-click="mapsNav('attendance-loc')"
                />
                <BaseButton 
                  class="button button--primary"
                  text="Lokasi Kehadiran ke Man Power"
                  @button-click="mapsNav('attendance-to-mp')"
                />
              </div>
            </div>
          </div>
          <!-- Location section -->

          <!-- Shift Detail section -->
          <div 
            v-if="tabs.shiftDetail" 
            class="w-100"
          >
            <div class="mb-2 w-100 d-flex flex-row justify-content-between">
              <div class="mr-3">
                <p class="mb-0 text-black">Status</p>
                <p 
                  v-if="detail.is_valid_shift == 0" 
                  class="text-danger font-weight-bold"
                >
                  <span>Tidak Valid </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
                      <g transform="translate(-1.182 -1.182)">
                        <circle cx="11.818" cy="11.818" r="11.818" transform="translate(2.364 2.364)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x1="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x2="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                      </g>
                    </svg>
                  </span>
                </p>
                <p 
                  v-if="detail.is_valid_shift == 1" 
                  class="text-success font-weight-bold"
                >
                  <span>Valid </span> 
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 25.549">
                      <g transform="translate(-1.157 -1.139)">
                        <path d="M25.517,12.851v1.067a11.6,11.6,0,1,1-6.878-10.6" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                        <path d="M25.517,4.639l-11.6,11.61-3.48-3.48" fill="none" stroke="#4caf50" stroke-linecap="round"   stroke-linejoin="round" stroke-width="2.32"/>
                      </g>
                    </svg>
                  </span>
                </p>
              </div>
              <div class="field-wrapper toggle-pass mb-2 d-flex align-items-center justify-content-center">
                <p class="mt-2 mr-2 d-inline-block custom-label">Mundurkan absen</p>
                <label class="switch s-primary">
                  <input 
                    type="checkbox"
                    id="toggle-password"
                    class="d-none"
                    :checked="detail.non_working_shift_status"
                    @change="changeNonWorkingShift(detail.hash_id)"
                  >
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="w-100 d-none d-sm-flex flex-row justify-content-around">
              <div>
                <div>
                  <p class="mb-0 text-black">Tanggal</p>
                  <p class="text-black font-weight-bold">{{ $_convertDate(detail.log_date) }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Kode Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_code }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Shift Keluar</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_out }}</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mb-0 text-black">Waktu Kehadiran</p>
                  <p class="text-black font-weight-bold">{{ detail.log_time }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_name }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Mulai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_start}}</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mb-0 text-black">Telat Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.total_late_in_minutes }} Menit</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Shift Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_in }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Selesai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_end }}</p>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex d-sm-none flex-column">
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Tanggal</p>
                  <p class="text-black font-weight-bold">{{ detail.log_date }}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Waktu Kehadiran</p>
                  <p class="text-black font-weight-bold">{{ detail.log_time }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Telat Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.total_late_in_minutes }} Menit</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Kode Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_code }}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_name }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Shift Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_in }}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Shift Keluar</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_out }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Mulai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_start}}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Selesai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_end }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Shift Detail section -->

          <!-- Service type section -->
          <div 
            v-if="tabs.serviceType" 
            class="w-100"
          >
            <div class="w-100 d-none d-sm-flex flex-row">
              <div>
                <div>
                  <p class="mb-0 text-black">Nama Karyawan</p>
                  <p class="text-black font-weight-bold">{{ detail.lend_employee_name }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">ID Karyawan</p>
                  <p class="text-black font-weight-bold">{{ detail.lend_employee_number_formatted }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Man Power Karyawan</p>
                  <p class="text-black font-weight-bold">{{ detail.lend_employee_man_power }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Service type section -->
          
        </div>
        <!-- Bottom section -->

      </div>
    </template>

    <template #footer>
      <div class="mt-3 w-100 d-flex d-md-none justify-content-end">
        <BaseButton
          v-if="userAccess.allow_reset_attendance"
          class="w-100 button button--danger" 
          text="Undo"
          :loading="undoLoadingState" 
          @button-click="undoRequest(detail.hash_id)"
        > 
          <template #icon>
            <svg 
              style="width:24px;height:24px" 
              viewBox="0 0 24 24"
            >
              <path 
                fill="currentColor" 
                d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" 
              />
            </svg>
          </template>
        </BaseButton>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { mapSettings } from '@/constants/mapSettings'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'
import MapsLoader from '@/components/google-maps/MapsLoader'
import MapsMarker from '@/components/google-maps/MapsMarker'
import MapsLine from '@/components/google-maps/MapsLine'

export default {
  name: 'AttendanceModal',
  components: {
    BaseModal,
    BaseButton,
    MapsLoader,
    MapsMarker,
    MapsLine
  },
  data() {
    return {
      tabs: {
        faceRecognition: true,
        location: false,
        shiftDetail: false,
        serviceType: false
      },
      undoLoadingState: false,
      rejectLoadingState: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('reportAttendanceLog', ['detail', 'tableParams', 'rejectState']),
    ...mapState('styling', ['modal']),
    mapConfig() {
      return {
        ...mapSettings
      }
    },
    apiKey() {
      return process.env.VUE_APP_MAPS_KEY
    },
    markers() {
      return [
        {
          id: 'a',
          position: {
            lat: parseFloat(this.detail.latitude),
            lng: parseFloat(this.detail.longitude),
          },
          icon: {
            url: require('../../../assets/img/marker-attendance.png')
          }
        },
        {
          id: 'b',
          position: {
            lat: parseFloat(this.detail.man_power_latitude),
            lng: parseFloat(this.detail.man_power_longitude),
          },
          icon: {
            url: require('../../../assets/img/marker-man-power.png')
          }
        },
      ]
    },
    specificMarker() {
      return {
        position: {
          lat: parseFloat(this.detail.man_power_latitude),
          lng: parseFloat(this.detail.man_power_longitude),
        },
        radius: parseInt(this.detail.man_power_max_radius)
      }
    },
    lines() {
      return [
        {
          id: '1',
          path: [
            this.markers[0].position, this.markers[1].position
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions('reportAttendanceLog', ['getAttendanceLog', 'patchAttendanceLogUndo', 'patchSetNonWorkingShift', 'patchUnsetNonWorkingShift', 'getAttendanceLogDetail', 'patchAttendanceLogReject']),
    ...mapMutations('reportAttendanceLog', ['setData', 'setRejectState']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    tabsToggle(item) {
      if (item === 'fr') {
        this.tabs.faceRecognition = true
        this.tabs.location = false
        this.tabs.shiftDetail = false
        this.tabs.serviceType = false
      } else if (item === 'location') {
        this.tabs.faceRecognition = false
        this.tabs.location = true
        this.tabs.shiftDetail = false
        this.tabs.serviceType = false
      } else if (item === 'shift-detail') {
        this.tabs.faceRecognition = false
        this.tabs.location = false
        this.tabs.shiftDetail = true
        this.tabs.serviceType = false
      } else if (item === 'service-type') {
        if (this.detail.service_type === 'personal') {
          return
        } else {
          this.tabs.faceRecognition = false
          this.tabs.location = false
          this.tabs.shiftDetail = false
          this.tabs.serviceType = true
        }
      }
    },
    closeModal() {
      this.tabs.faceRecognition = true
      this.tabs.location = false
      this.tabs.shiftDetail = false
      this.tabs.serviceType = false
      this.setRejectState(false)
      this.TOGGLE_MODAL()
    },
    mapsNav(whereTo) {
      if (whereTo === 'attendance-loc') {
        window.open(`https://www.google.com/maps?q=${this.detail.latitude},${this.detail.longitude}`, '_blank')
      } else if (whereTo === 'attendance-to-mp') {
        window.open(`https://www.google.com/maps/dir/${this.detail.latitude},${this.detail.longitude}/${this.detail.man_power_latitude},${this.detail.man_power_longitude}`, '_blank')
      }
    },
    undoRequest(id) {
      this.undoLoadingState = true

      this.patchAttendanceLogUndo({
        id,
        token: this.authorization
      })
      .then(res => {
        this.undoLoadingState = false
        this.$_successAlert(res.data.message)
        this.closeModal()
        this.setData(null)
        this.getAttendanceLog({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
      })
      .catch(err => {
        this.undoLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    changeNonWorkingShift(id) {
      this.patchSetNonWorkingShift({ id })
      .then(res => {
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        this.closeModal()
      })
    },
    rejectRequest(id) {
      this.rejectLoadingState = true

      this.patchAttendanceLogReject({
        id,
        note: this.detail.rejected_note,
      })
      .then(res => {
        this.rejectLoadingState = false
        this.$_successAlert(res.data.message)
        this.closeModal()
        this.setRejectState(false)
        this.setData(null)
        this.getAttendanceLog({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
      })
      .catch(err => {
        this.rejectLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.note
  border-radius: 7px
  border-width: 3px
  border-style: solid

  &-blue
    background-color: #1B55E2
    border-color: #1B55E2

  &-white
    background-color: #FFFFFF
    border-color: #1B55E2

    .text-blue
      color: #1B55E2 !important

  p:nth-child(2)
    font-size: 13px

.small-label
  font-size: 15px

.modal-main

  &__middle

    .title
    
      &:hover
        background-color: rgba(46, 61, 97, 0.1)
        cursor: pointer

      &__text

        &--active
          color: #0D2A70

      &__divider
        margin: 0

        &--active
          border-color: #0D2A70

    .w-one-third
      width: 33%

.fr-image
  height: 175px
  width: 125px
  object-fit: cover
  border-radius: 10px

.gmaps-container
  height: 400px
  
.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &[type=text], &[type=number]
      height: 34.5px

    &[disabled]
      color: #4d4d4d

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.custom-label
  color: #000000 !important

@media (min-width: 576px)
  .fr-image
    height: 350px
    width: 250px

@media (min-width: 768px)
  .fr-image
    height: 400px
    width: 250px
</style>