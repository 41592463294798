<template>
  <div id="app">
    <BaseFullLoader/>
    <BaseHeaderChangePasswordModal />
    <router-view/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import BaseFullLoader from '@/components/base/BaseFullLoader'
import BaseHeaderChangePasswordModal from './components/base/BaseHeaderChangePasswordModal.vue'

export default {
  name: 'App',
  components: {
    BaseFullLoader,
    BaseHeaderChangePasswordModal
},
  computed: {
    ...mapState('styling', ['sidebarDropdown'])
  },
  watch:{
    // eslint-disable-next-line no-unused-vars
    $route (to, from){
      this.sidebarDropdown.adjustment = false
      this.sidebarDropdown.approval = false
      this.sidebarDropdown.report = false
      this.sidebarDropdown.settings = false
      this.sidebarDropdown.sysadm = false
      this.CLOSE_MODAL()
    }
  },
  beforeCreate() {
    this.$axios.interceptors.response.use(undefined, (err) => {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (err.response.data.http_status === 401) {
          this.authLogout()
          this.$router.push('/login')
        }
        throw err 
      })
    })
  },
  methods: {
    ...mapMutations('styling', ['CLOSE_MODAL']),
    ...mapMutations('auth', ['authLogout'])
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap')

* 
  font-family: 'Quicksand', sans-serif

@import '~sweetalert2/src/variables'

$swal2-confirm-button-background-color: #1B55E2 !important
$swal2-border-radius: 17px !important

@import '~sweetalert2/src/sweetalert2'

.mx-input--error
  border: 1px solid #EE2A2A !important

.tooltip 
  &[aria-hidden='true'] 
    visibility: hidden !important
    opacity: 0 !important
    transition: opacity .15s, visibility .15s !important

  &[aria-hidden='false'] 
    visibility: visible !important
    opacity: 1 !important
    transition: opacity .15s !important
  
  &-inner
    padding: 16px !important
    max-width: 250px !important
    background-color: #FFFFFF !important

  display: block !important
  z-index: 10000 !important

.ck
  &-toolbar 
    border-top-left-radius: 5px !important
    border-top-right-radius: 5px !important

  &-editor__editable 
    min-height: 150px !important
    border-bottom-left-radius: 5px !important
    border-bottom-right-radius: 5px !important

  &-balloon-panel
    z-index: 1050 !important

.bg-semi-transparent-red
  background-color: rgba(255, 0, 0, 0.15)

  &:hover
    background-color: rgba(255, 0, 0, 0.15) !important
</style>
