<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="new-modal-container"
    content-class="new-modal-content"
    v-on="$listeners"
    :z-index-base="1031"
  >
    <span class="new-modal__title mb-5">
      <slot name="title"></slot>
    </span>
    <div class="new-modal__content">
      <slot v-bind:params="params"></slot>
    </div>
    <button class="new-modal__close" @click="close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x close"
        data-dismiss="modal"
        @click="$emit('close-modal')"
      >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: 'BaseNewModal',
  components: {
    VueFinalModal
  },
  inheritAttrs: false
}
</script>

<style lang="sass" scoped>
::v-deep .new-modal-container
  display: flex
  justify-content: center
  align-items: center

::v-deep .new-modal-content
  min-width: 350px
  position: relative
  display: flex
  flex-direction: column
  max-height: 90%
  margin: 0 1rem
  padding: 1rem
  border: 1px solid #e2e8f0
  border-radius: 0.25rem
  background: #fff

// .new-modal__title
//   margin: 0 2rem 0 0
//   font-size: 1.5rem
//   font-weight: 700

.new-modal__content
  flex-grow: 1
  overflow-y: auto

.new-modal__action
  display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  padding: 1rem 0 0

.new-modal__close
  position: absolute
  top: -12px
  right: -12px
  background: white
  border: none
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%)
  font-size: 12px
  font-weight: 600
  padding: 2px
  border-radius: 5px
  transition: .600s

  svg
    color: #2196f3

  &:hover
    box-shadow: none
    transition: .600s
    opacity: 1 !important
</style>
