<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <div class="row layout-top-spacing">

          <DashboardAttendance @change-month="loadAbsence"/>
          
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
            <BaseProgress
              headerAlignment="main"
              icon="circle-icon.svg"
              title="Terlambat"
              desc="Karyawan terlambat"
              customStyleText="text-turqoise-custom"
              @change-month="loadLateIn"
            >

              <template #dataTotal>
                <PuSkeleton 
                  v-if="!lateIn" 
                  width="15px"
                />
                <p 
                  v-else 
                  :class="`mb-2 browser-count ${customStyleText}`"
                >
                  {{ lateIn.total }}
                </p>
              </template>

              <template #dataPercentage>
                <PuSkeleton v-if="!lateIn"/>
                <div
                  v-else
                  :class="`progress-bar bg-turqoise-custom`"
                  role="progressbar"
                  :style="`width: ${lateIn.percentage}%`"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </template>

            </BaseProgress>
          </div>

          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
            <BaseProgress
              headerAlignment="main"
              icon="circle-icon1.svg"
              title="Pulang Cepat"
              desc="Karyawan pulang cepat"
              customStyleText="text-purple-custom2"
              @change-month="loadEarlyOut"
            >

              
              <template #dataTotal>
                <PuSkeleton 
                  v-if="!earlyOut" 
                  width="15px"
                />
                <p 
                  v-else 
                  :class="`mb-2 browser-count ${customStyleText}`"
                >
                  {{ earlyOut.total }}
                </p>
              </template>

              <template #dataPercentage>
                <PuSkeleton v-if="!earlyOut"/>
                <div
                  v-else
                  :class="`progress-bar bg-purple-custom2`"
                  role="progressbar"
                  :style="`width: ${earlyOut.percentage}%`"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </template>

            </BaseProgress>
          </div>

        </div>
        <div class="row">

          <div class="col-xl-3 col-lg-6 layout-spacing">
            <BaseChart/>
          </div>

          <div class="col-xl-9 col-lg-6">
            <div class="row">

              <div class="col-xl-6 col-lg-12 col-md-6 layout-spacing">
                <BaseBirthDate/>
              </div>

              <div class="col-xl-6 col-lg-12 col-md-6 layout-spacing">
                <BaseWaitingApproval
                  headerStyle="main"
                  :attendance="true"
                  :permission="true"
                  :additionalContent="true"
                  :attendanceWaitingApproval="Number(attendanceWaitingApproval.waiting_approval)"
                  :attendanceApproved="Number(attendanceWaitingApproval.manual_approval)"
                  :attendanceRejected="Number(attendanceWaitingApproval.rejected)"
                  :permissionWaitingApproval="Number(permissionWaitingApproval.waiting_approval)"
                  :permissionApproved="Number(permissionWaitingApproval.approved)"
                  :permissionRejected="Number(permissionWaitingApproval.rejected)"
                />
              </div>

              <div class="w-100"/>

              <div class="col-12 layout-spacing">
                <BaseNotValidAttendance 
                  title="Kehadiran tidak valid" 
                  @change-month="loadAttendanceValidation"
                >
                  <template #firstItem>
                    <img 
                      src="@/assets/svg/Group_73.svg" 
                      alt="Face Icon" 
                      width="55px" 
                      class="mb-2 mb-lg-0"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton>{{ attendanceValidation.face_not_match }}</PuSkeleton>
                      </span>
                      <span>Wajah</span>
                    </div>
                  </template>
                  <template #secondItem>
                    <img 
                      src="@/assets/svg/Group_64.svg" 
                      alt="Location Icon" 
                      width="55px" 
                      class="mb-2 mb-lg-0"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton>{{ attendanceValidation.out_of_location }}</PuSkeleton>  
                      </span>
                      <span>Diluar lokasi</span>
                    </div>
                  </template>
                  <template #thirdItem>
                    <img 
                      src="@/assets/svg/Group_65.svg" 
                      alt="Shift Icon" 
                      width="55px" 
                      class="mb-2 mb-lg-0"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton>{{ attendanceValidation.invalid_shift}}</PuSkeleton>
                      </span>
                      <span>Jadwal kerja tidak sesuai</span>
                    </div>
                  </template>
                </BaseNotValidAttendance>
              </div>

            </div>
          </div>
        </div>
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader.vue'
import BaseSidebar from '@/components/base/BaseSidebar.vue'
import BaseChart from '@/components/base/BaseChart.vue'
import BaseBirthDate from '@/components/base/BaseBirthDate.vue'
import DashboardAttendance from '@/components/dashboard/DashboardAttendance.vue'
import BaseProgress from '@/components/base/BaseProgress.vue'
import BaseWaitingApproval from '@/components/base/BaseWaitingApproval.vue'
import BaseNotValidAttendance from '@/components/base/BaseNotValidAttendance.vue'

export default {
  name: 'Dashboard',
  components: {
    BaseHeader,
    BaseSidebar,
    DashboardAttendance,
    BaseProgress,
    BaseChart,
    BaseBirthDate,
    BaseWaitingApproval,
    BaseNotValidAttendance
  },
  props: {
    customStyleText: String,
    customStyleBg: String
  },
  data() {
    return {
      waitingApprovalStatus: {},
      attendanceValidationStatus: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('dashboard', ['attendanceWaitingApproval', 'permissionWaitingApproval', 'attendanceValidation']),
    ...mapState('global', ['lateIn', 'earlyOut'])
  },
  created() {
    this.getAttendanceWaitingApproval({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      token: this.authorization
    })
    this.getPermissionWaitingApproval({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      token: this.authorization
    })
    this.loadAttendanceValidation(new Date().getMonth() + 1)
    this.loadAbsence(new Date().getMonth() + 1)
    this.loadLateIn(new Date().getMonth() + 1)
    this.loadEarlyOut(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('dashboard', ['getAttendanceWaitingApproval', 'getPermissionWaitingApproval', 'getAttendanceValidation', 'getAbsence']),
    ...mapActions('global', ['getLateIn', 'getEarlyOut']),
    ...mapMutations('dashboard', ['setAttendanceValidation', 'setAbsence']),
    ...mapMutations('global', ['setLateIn', 'setEarlyOut']),
    loadAttendanceValidation(month) {
      this.setAttendanceValidation({})
      let year = new Date().getFullYear()
      this.getAttendanceValidation({
        month: month,
        year: year,
        token: this.authorization
      })
    },
    loadAbsence(month) {
      this.setAbsence({})
      let year = new Date().getFullYear()
      this.getAbsence({
        month: month,
        year: year,
        token: this.authorization
      })
    },
    loadLateIn(month) {
      this.setLateIn(null)
      let year = new Date().getFullYear()
      this.getLateIn({
        month: month,
        year: year,
        token: this.authorization
      })
    },
    loadEarlyOut(month) {
      this.setEarlyOut(null)
      let year = new Date().getFullYear()
      this.getEarlyOut({
        month: month,
        year: year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
