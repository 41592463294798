<template>
  <div class="wrapper w-100 d-flex flex-column justify-content-between align-items-center">
    <div class="main w-75">
      <AuthHeader/>
      <AuthFormHeader title="Reset Password"/>
      <form action="" @submit.prevent="resetPassword" class="w-100 d-flex flex-column">

        <div class="w-100 d-flex flex-column">

          <div class="d-flex flex-column flex-lg-row">

            <!-- Password input -->
            <div class="w-100 my-1 my-lg-2 mx-1 input d-flex flex-column">
              <label for="password">Password</label>
              <input 
                type="password"
                tabindex="1"
                autofocus
                :class="$v.password.$error ? 'input__field input__field--error' : 'input__field'"
                v-model="password">
              <span class="input__text--error font-weight-bold" v-if="submitted && !$v.password.required">Password harus diisi</span>
              <span class="input__text--error font-weight-bold" v-if="submitted && !$v.password.minLength">Password setidaknya memiliki 6 karakter</span>
            </div>
            <!-- Password input -->

            <!-- Repeat password input -->
            <div class="w-100 my-1 my-lg-2 mx-1 input d-flex flex-column">
              <label for="repeat-password">Ulangi Password</label>
              <input 
                type="password" 
                tabindex="2" 
                :class="$v.repeatPassword.$error ? 'input__field input__field--error' : 'input__field'" 
                v-model="repeatPassword">
              <span class="input__text--error font-weight-bold" v-if="submitted && !$v.repeatPassword.sameAsPassword">Password harus sama</span>
            </div>
            <!-- Repeat password input -->

          </div>

          <div class="mx-1">
            <span>Cek email <span class="user-email">{{ email }}</span> lalu gunakan kode verifikasi untuk reset password</span>
          </div>

          <!-- Code verification input -->
          <div class="d-flex flex-row">
            <div class="w-50 mx-1 input d-flex flex-column">
              <label for="code">Kode Verifikasi</label>
              <div class="d-flex flex-row">
                <input 
                  type="text"
                  minlength="1"
                  maxlength="1"
                  tabindex="3"
                  ref="code1"
                  :class="verif.code1 !== '' ? 'input__code input__code--done' : 'input__code'"
                  v-model="verif.code1"
                  @paste="pasteCode"
                  @keyup="input(1)">
                <input 
                  type="text" 
                  minlength="1" 
                  maxlength="1" 
                  tabindex="4" 
                  ref="code2" 
                  :class="verif.code2 !== '' ? 'input__code input__code--done' : 'input__code'"
                  :disabled="verif.code1 === ''" 
                  v-model="verif.code2" 
                  @keyup="input(2)">
                <input 
                  type="text" 
                  minlength="1" 
                  maxlength="1" 
                  tabindex="5" 
                  ref="code3" 
                  :class="verif.code3 !== '' ? 'input__code input__code--done' : 'input__code'"
                  :disabled="verif.code2 === ''" 
                  v-model="verif.code3" 
                  @keyup="input(3)">
                <input 
                  type="text" 
                  minlength="1" 
                  maxlength="1" 
                  tabindex="6" 
                  ref="code4" 
                  :class="verif.code4 !== '' ? 'input__code input__code--done' : 'input__code'"
                  :disabled="verif.code3 === ''" 
                  v-model="verif.code4" 
                  @keyup="input(4)">
                <input 
                  type="text" 
                  minlength="1" 
                  maxlength="1" 
                  tabindex="7" 
                  ref="code5" 
                  :class="verif.code5 !== '' ? 'input__code input__code--done' : 'input__code'"
                  :disabled="verif.code4 === ''" 
                  v-model="verif.code5" 
                  @keyup="input(5)">
                <input 
                  type="text" 
                  minlength="1" 
                  maxlength="1" 
                  tabindex="8" 
                  ref="code6" 
                  :class="verif.code6 !== '' ? 'input__code input__code--done' : 'input__code'"
                  :disabled="verif.code5 === ''" 
                  v-model="verif.code6" 
                  @keyup="input(6)">
                <button
                  class="input__code--clear"
                  v-show="verif.code1 !== ''"
                  @click.prevent="clearCode">
                  ✖
                </button>
              </div>
              <span class="mt-1 input__text--error font-weight-bold" v-if="submitted && !$v.verif.code6.required">Kode verifikasi harus diisi</span>
            </div>
          </div>
          <!-- Code verification input -->

        </div>

        <div class="d-flex flex-row align-items-center">
          <button 
            type="submit"
            ref="resetButton"
            class="py-2 px-3 button button--fill"
            :disabled="loadingState"
            :style="loadingState ? 'cursor: not-allowed;':''">
            <span v-if="!loadingState">Reset Password</span>
            <div class="mx-5 loader" v-if="loadingState"></div>
          </button>
        </div>

      </form>
    </div>
    <AuthFooter/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'

import AuthHeader from '@/components/auth/AuthHeader'
import AuthFormHeader from '@/components/auth/AuthFormHeader'
import AuthFooter from '@/components/auth/AuthFooter'

export default {
  name: 'AuthResetPassword',
  data () {
    return {
      email: sessionStorage.userEmail,
      verif: {
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: ''
      },
      password: '',
      repeatPassword: '',
      submitted: false,
      loadingState: false
    }
  },
  components: {
    AuthHeader,
    AuthFormHeader,
    AuthFooter
  },
  validations: {
    email: { required, email },
    verif: {
      code6: { required }
    },
    password: { required, minLength: minLength(6) },
    repeatPassword: { required, sameAsPassword: sameAs('password') }
  },
  methods: {
    ...mapActions('auth', ['submitResetPassword']),
    pasteCode(e) {
      let allCode = e.clipboardData.getData('text')
      this.verif.code1 = allCode[0]
      this.verif.code2 = allCode[1]
      this.verif.code3 = allCode[2]
      this.verif.code4 = allCode[3]
      this.verif.code5 = allCode[4]
      this.verif.code6 = allCode[5]
      this.$refs.resetButton.focus()
    },
    clearCode() {
      this.verif.code1 = ''
      this.verif.code2 = ''
      this.verif.code3 = ''
      this.verif.code4 = ''
      this.verif.code5 = ''
      this.verif.code6 = ''
    },
    input(number) {
      const key = event.key
      if (number === 1) {

        this.$refs['code' + (number + 1)].focus() 

      } else if (number > 1 && number < 6) {

        if (key === "Backspace" || key === "Delete") {

          this.$refs['code' + (number - 1)].focus()

        } else if (key !== "Backspace" || key !== "Delete") {

          this.$refs['code' + (number + 1)].focus() 
        }        
      } else if (number === 6) {

        if (key === "Backspace" || key === "Delete") {

          this.$refs['code' + (number - 1)].focus()

        } else if (key !== "Backspace" || key !== "Delete") {

          this.$refs['code' + number].focus() 

        } 
      }
    },
    resetPassword() {
      this.loadingState = true
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {
        
        this.loadingState = false
        return

      } else {
        
        let email = this.email
        let verification_code = `${this.verif.code1}${this.verif.code2}${this.verif.code3}${this.verif.code4}${this.verif.code5}${this.verif.code6}`.toUpperCase()
        let new_password = this.password
        let confirm_new_password = this.repeatPassword
        let data = {
          email,
          verification_code,
          new_password,
          confirm_new_password
        }

        this.submitResetPassword(data)
        .then(res => {
          this.loadingState = false
          this.$_successAlert(res.data.message)
          this.$router.push('/login')
        })
        .catch(err => {
          this.loadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
        
      }

    }
  }
}
</script>

<style lang="sass" scoped>
.custom-label
  color: #000000
  margin: 0 5px 10px 0

label
  color: #000000

input[type="text"]:disabled
  background: none !important

.input
  margin: 20px

  &__field
    height: 66px
    width: 100%
    padding: 0 20px
    border: none
    border-radius: 3px
    background-color: #F3F6F9
    
    &:focus
      border: 3px solid #1B55E2

    &:hover
      background-color: #D5E5F5

    &--error
      border: 3px solid #EE2A2A

  &__code
    font-size: 25px
    text-align: center
    background: none
    text-transform: uppercase
    margin-right: 2px
    border: none
    height: 50px
    width: 40px
    border-bottom: 2px solid black

    &--done
      border-bottom: 4px solid blue

    &--clear
      border: none
      background: none
      color: red
      font-size: 30px

      &:hover
        background-color: lightblue
        opacity: 0.75

  &__verif
    text-transform: uppercase
    margin-left: 8px
    border: none
    letter-spacing: 19px

  &__text--error
    color: #EE2A2A

.verif__dash
  margin-right: 2px
  width: 28px
  border-bottom: 2px solid black

.button
  transition: 0.2s
  text-transform: uppercase

  &--fill
    border: none
    border-radius: 7px
    background-color: #1B55E2
    color: #FFFFFF

    &:hover
      background-color: #1244BC
      box-shadow: 0 15px 20px -10px #1b55e2

  &--border
    border: 2px solid #1B55E2
    border-radius: 7px
    background-color: #FFFFFF
    color: #1B55E2

    &:hover
      background-color: #F6F6F6
      box-shadow: 0 15px 20px -10px #1b55e2
      text-decoration: none

.main
  margin-top: auto

.loader 
  font-size: 10px
  text-indent: -9999em
  width: 25px
  height: 25px
  border-radius: 50%
  background: #ffffff
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  position: relative
  -webkit-animation: load3 1.4s infinite linear
  animation: load3 1.4s infinite linear
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)

.loader:before 
  width: 50%
  height: 50%
  background: #ffffff
  border-radius: 100% 0 0 0
  position: absolute
  top: 0
  left: 0
  content: ''

.loader:after 
  background: #1B55E2
  width: 75%
  height: 75%
  border-radius: 50%
  content: ''
  margin: auto
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0

@-webkit-keyframes load3 
  0% 
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  
  100% 
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
  

@keyframes load3 
  0% 
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  
  100% 
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

.user-email
  font-weight: 600
  color: #0d47a1

.wrapper
  background: linear-gradient(90deg, rgba(209, 227, 255, 0.9), rgba(209, 227, 255, 0.9)), url(../../assets/img/bpp/login-side.png) no-repeat fixed center
  background-size: cover

@media (min-width: 1200px) 
  .wrapper
    background: #ffffff
</style>