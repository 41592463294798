<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal"
  >

    <template #body>
      
      <!-- Add & update modal -->
      <form v-if="modalEdit">
        <div class="d-flex flex-column">
          
          <div class="w-100">
            <div class="w-100 mb-3 d-flex flex-row align-items-center align-items-lg-end">

              <!-- Subject Input -->
              <div class="mb-3 mr-3 mb-md-0 w-100 pr-3">
                <div class="d-flex flex-column">
                  <label for="subject">Subjek</label>
                  <input 
                    type="text"
                    class="custom-input"
                    :class="{'custom-input--error': submitted && !$v.detail.title.required}"
                    v-model="detail.title"
                  >
                </div>
                <p v-if="submitted && !$v.detail.title.required" class="mb-0 text-danger font-weight-bold">Judul pengumuman wajib diisi</p>
              </div>
              <!-- Subject Input -->

              <!-- Important toggle switch -->
              <v-popover 
                placement="top"
                class="d-none d-lg-block"
                :open="importantNote"
              >
                <div 
                  @mouseover="importantNote = true" 
                  @mouseleave="importantNote = false"
                >
                  <svg 
                    v-if="detail.is_important === 0" 
                    style="width:24px;height:24px" 
                    viewBox="0 0 24 24" 
                    class="m-0"
                    @click="toggleImportant(detail.is_important)"
                  >
                    <path fill="currentColor" d="M6.5,17H15L18.5,12L15,7H6.5L10,12L6.5,17M15,19H3L7.5,12L3,5H15C15.69,5 16.23,5.3 16.64,5.86L21,12L16.64,18.14C16.23,18.7 15.69,19 15,19Z" />
                  </svg>
                  <svg 
                    v-else-if="detail.is_important === 1" 
                    style="width:24px;height:24px" 
                    viewBox="0 0 24 24" 
                    class="m-0"
                    @click="toggleImportant(detail.is_important)"
                  >
                    <path fill="#e2a03f" d="M3.5,19L8.34,12L3.5,5H14.5C15.17,5 15.72,5.3 16.13,5.86L20.5,12L16.13,18.14C15.72,18.7 15.17,19 14.5,19H3.5Z" />
                  </svg>
                </div>
                <template #popover>
                  <span class="text-primary">Setel sebagai pengumuman penting</span>  
                </template>      
              </v-popover>
              <div class="d-lg-none">
                <svg 
                  v-if="detail.is_important === 0" 
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24" 
                  class="m-0"
                  @click="toggleImportant(detail.is_important)"
                >
                  <path fill="currentColor" d="M6.5,17H15L18.5,12L15,7H6.5L10,12L6.5,17M15,19H3L7.5,12L3,5H15C15.69,5 16.23,5.3 16.64,5.86L21,12L16.64,18.14C16.23,18.7 15.69,19 15,19Z" />
                </svg>
                <svg 
                  v-else-if="detail.is_important === 1" 
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24" 
                  class="m-0"
                  @click="toggleImportant(detail.is_important)"
                >
                  <path fill="#e2a03f" d="M3.5,19L8.34,12L3.5,5H14.5C15.17,5 15.72,5.3 16.13,5.86L20.5,12L16.13,18.14C15.72,18.7 15.17,19 14.5,19H3.5Z" />
                </svg>
              </div>
              <!-- Important toggle switch -->

            </div>

            <!-- Tags & date input -->
            <div class="w-100 mb-3 d-flex flex-column flex-md-row align-items-end">

              <!-- Tags input -->
              <div class="w-100 mb-3 mb-md-0 mr-md-1">
                <div class="d-flex flex-row flex-nowrap align-items-end tags-selector">
                  <div class="d-flex flex-row align-self-center flex-nowrap tags-text">
                    <span class="mr-1">Tags</span>
                    <svg 
                      style="width:16px;height:16px" 
                      viewBox="0 0 24 24"
                    >
                      <path fill="#ffffff" d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                    </svg>
                  </div>
                  <v-select 
                    style="background-color: #ffffff" 
                    class="w-100" 
                    label="label" 
                    multiple 
                    :options="availableTags" 
                    v-model="detail.tag" 
                    @input="tagChecker"
                  >
                    <template #no-options>
                      Data tidak ditemukan
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="#888EA8" 
                          stroke-width="2" 
                          stroke-linecap="round" 
                          stroke-linejoin="round" 
                          class="feather feather-chevron-down"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </template>
                  </v-select>
                </div>
                <p 
                  v-if="submitted && !$v.detail.tag.required" 
                  class="d-block d-md-none mb-0 text-danger font-weight-bold"
                >
                  Tag wajib diisi
                </p>
              </div>
              <!-- Tags input -->

              <!-- Publish date input -->
              <!-- <div class="w-100 ml-md-1 d-flex flex-row align-items-end">
                <div 
                  class="w-100 mr-2" 
                  :key="renderKey"
                >
                  <label class="d-block">Pilih tanggal terbit</label>
                  <date-picker 
                    class="w-100" 
                    type="datetime" 
                    value-type="YYYY-MM-DD HH:mm:ss" 
                    :default-value="rightNow" 
                    :disabled-date="notBeforeToday" 
                    :disabled-time="notBeforeNow" 
                    :show-second="false" 
                    v-model="detail.published_date"
                  />
                  <p 
                    v-if="submitted && !$v.detail.published_date.required" 
                    class="d-block d-md-none mb-0 text-danger font-weight-bold"
                  >
                    Tanggal wajib diisi
                  </p>
                </div>
                <v-popover 
                  placement="top" 
                  class="d-none d-lg-block"
                  :open="reloadNote" 
                >
                  <svg 
                    class="pointer" 
                    style="width:24px;height:24px" 
                    viewBox="0 0 24 24"
                    @mouseover="reloadNote = true" 
                    @mouseleave="reloadNote  = false" 
                    @click="reloadTime"
                  >
                    <path fill="currentColor" d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
                  </svg>
                  <template #popover>
                    <span class="text-primary">Muat ulang waktu minimum penerbitan</span>  
                  </template>      
                </v-popover>
                <svg 
                  class="pointer d-lg-none" 
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
                </svg>
              </div> -->
              <!-- Publish date input -->

            </div>
            <div class="w-100 mb-3 d-flex flex-row">
              <p class="w-50 d-none d-md-block mb-3 mb-md-0 mr-md-1 text-danger font-weight-bold">{{ submitted && !$v.detail.tag.required ? 'Tag wajib diisi' : '' }}</p>
              <p class="w-50 d-none d-md-block ml-md-1 text-danger font-weight-bold">{{ submitted && !$v.detail.published_date.required ? 'Tanggal wajib diisi' : '' }}</p>
            </div>
            <!-- Tags & date input -->

            <!-- Description input -->
            <div class="mb-3">
              <div class="d-flex flex-column">
                <label for="content">Pengumuman</label>
                <ckeditor 
                  :editor="editor"
                  :config="editorConfig"
                  v-model="detail.content"
                >
                </ckeditor>
                <!-- <textarea 
                  cols="20" 
                  rows="5" 
                  class="custom-input" 
                  v-model="detail.content"
                >
                </textarea> -->
              </div>
              <p 
                v-if="submitted && !$v.detail.content.required" 
                class="mb-0 text-danger font-weight-bold"
              >
                Deskripsi pengumuman wajib diisi
              </p>
            </div>
            <!-- Description input -->

          </div>

          <!-- Optional field -->
          <div class="w-100">
            <p class="font-weight-bold">Opsional</p>

            <div class="optional-group mb-3">
              <div class="optional-group__title ml-2 bg-white">Lampirkan Tautan</div>
              <div class="p-4">

                <!-- Url title input -->
                <div class="mb-4 d-flex flex-column">
                  <label for="subject">Judul</label>
                  <input 
                    type="text" 
                    class="custom-input" 
                    v-model="detail.url_title"
                  >
                </div>
                <!-- Url title input -->

                <!-- Url input -->
                <div class="d-flex flex-column">
                  <label for="subject">Tautan</label>
                  <input 
                    type="text" 
                    class="custom-input" 
                    v-model="detail.url"
                  >
                </div>
                <!-- Url input -->

              </div>
            </div>

            <div class="optional-group">
              <div class="optional-group__title ml-2 bg-white">Lampirkan Berkas</div>
              <div>

                <!-- File input -->
                <div 
                  v-if="!detail.attachment"
                  class="dropbox"
                >
                  <input 
                    type="file" 
                    name="uploadFieldName" 
                    class="input-file"
                    @change="fileSelected"
                  >
                  <p>
                    Geser berkas atau klik untuk mengunggah 
                  </p>
                </div>
                <!--SUCCESS-->
                <div 
                  v-else
                  class="p-4"
                >
                  <span class="file-button px-1 py-2 rounded">
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                    </svg>
                    <span class="pl-1">{{ detail.attachment[0].name}}</span>
                    <button 
                      class="file-button__clear"
                      @click="resetFile"
                    >
                      <svg 
                        style="width:24px;height:24px" 
                        viewBox="0 0 24 24"
                      >
                        <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                      </svg>
                    </button>
                  </span>
                </div>
                <!-- File input -->

              </div>
            </div>
          </div>
          <!-- Optional field -->

          <p 
            v-if="modalEdit && detail.id" 
            class="mb-0 text-black font-weight-bold text-small"
          >
            Disimpan sebagai draf pada {{ detail.created }}
          </p>
        </div>
      </form>
      <!-- Add & update modal -->
      
      <!-- Detail modal -->
      <div v-else-if="!modalEdit">
        <h4 class="mb-3 font-weight-bold">{{ detail.title }}</h4>
        <div class="d-flex justify-content-between">
          <p class="text-med mb-0">Diterbitkan pada {{ detailDate }}</p>
          <p class="text-small mb-0">
            <span class="font-weight-bold">Tags: </span>
            <span>
              {{ labels(detail.tag) }}
            </span>
          </p>
        </div>
        <hr class="mt-0">
        <div 
          class="mb-5"
          v-html="detail.content"
        >
        </div>
        <div 
          v-if="detail.url" 
          class="optional-group"
        >
          <span class="optional-group__title ml-2 bg-white">
            <span>Lampiran</span>
          </span>
          <div class="p-4">
            <div class="mb-3">
              <p class="mb-0 font-weight-bold">Tautan</p>
              <a 
                :href="`http://${detail.url}`" 
                target="_blank"
              >
                {{ detail.url_title }}
              </a>
            </div>
            <div>
              <p class="mb-0 font-weight-bold">File</p>
              <button
                @click="viewAttachment(detail.attachment)" 
                class="download-button p-1 d-flex justify-content-center align-items-center rounded"
              >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                </svg>
                <span class="pl-1">{{ fileName(detail.attachment) }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Detail modal -->

    </template>
    
    <template #footer>
      <div 
        v-if="modalEdit" 
        class="w-100 d-flex flex-column"
      >
        <div class="w-100 d-flex flex-row align-items-center justify-content-between">
          <div>
            <BaseButton
              class="button button--primary mr-3"
              text="Terbitkan" 
              :loading="publishLoadingState" 
              @button-click="publishAnnouncement(1)"
            />
            <!-- <BaseButton 
              class="button button--success" 
              text="Simpan" 
              :loading="draftLoadingState" 
              @button-click="publishAnnouncement(0)"
            /> -->
          </div>
          <v-popover 
            v-if="modalEdit && detail.id" 
            offset="5" 
            placement="top"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              stroke-width="2" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              class="feather feather-trash-2 delete-note pointer"
            >
              <polyline points="3 6 5 6 21 6" />
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
              <line x1="10" y1="11" x2="10" y2="17" />
              <line x1="14" y1="11" x2="14" y2="17" />
            </svg>
            <template #popover>
              <p class="font-weight-bold">Hapus pengumuman?</p>
              <div class="d-flex">
                <BaseButton 
                  class="button button--cancel mr-2"
                  text="Batal"
                  v-close-popover
                />
                <BaseButton 
                  class="button button--danger"
                  text="Hapus"
                  @button-click="removeAnnouncement(detail.id)"
                  v-close-popover
                />
              </div>
            </template>
          </v-popover>
        </div>
        <p 
          v-if="modalEdit && detail.id" 
          class="mt-3 mt-md-0 mb-0 text-small text-black align-self-end"
        >
          Terakhir diperbarui <span class="font-weight-bold">{{ detail.creator_user_name }}</span>  pada {{ detail.created }}
        </p>
      </div>
    </template>
    
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'AnnouncementModal',
  components: {
    BaseModal,
    BaseButton,
    ckeditor: CKEditor.component
  },
  data() {
    return {
      draftLoadingState: false,
      publishLoadingState: false,
      submitted: false,
      importantNote: false,
      reloadNote: false,
      minTime: new Date().setHours(new Date().getHours(), new Date().getMinutes() + 4, 0),
      rightNow: new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes() + 5, 0)),
      renderKey: 0,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|', 
            'bold', 'italic', 'link', '|', 
            'bulletedList', 'numberedList', '|',
            'table', '|',
            'undo', 'redo'
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapGetters('announcement', ['detail', 'params']),
    ...mapState('announcement', ['modalEdit', 'availableTags']),
    ...mapState('styling', ['modal']),
    detailDate() {
      if (this.detail.created) {
        return `${this.$_convertDate(this.detail.created.split(' ')[0])} ${this.detail.created.split(' ')[1]}`
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions('announcement', ['createAnnouncement', 'getAnnouncement', 'putAnnouncement', 'deleteAnnouncement']),
    ...mapMutations('announcement', ['setData', 'resetDetail']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.submitted = false
    },
    tagChecker() {
      let tagsOtherThanAll = this.availableTags.filter(tag => tag.value !== 'all')
      let selectedTagValue = this.detail.tag.map(item => item.value)
      let selectedTag = this.detail.tag.map(item => item)
      
      if (selectedTagValue.includes('all')) {
        this.detail.tag = [{label: 'Semua', value: 'all'}]
      } else {
        if (selectedTag.length === tagsOtherThanAll.length) this.detail.tag = [{label: 'Semua', value: 'all'}]
      }
    },
    labels(tags) {
      return tags.map(tag => tag.label).toString()
    },
    toggleImportant(state) {
      state === 1 ? this.detail.is_important = 0 : this.detail.is_important = 1 
    },
    notBeforeToday(date) {
      let today = new Date()
      return date < new Date(today.getFullYear(), today.getMonth(), today.getDate())
    },
    notBeforeNow(date) {
      return date < this.minTime
    },
    reloadTime() {
      this.detail.published_date = ''
      this.rightNow = new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes() + 5, 0))
      this.minTime = new Date().setHours(new Date().getHours(), new Date().getMinutes() + 4, 0)
      this.renderKey++
    },
    fileSelected(e) {
      this.detail.attachment = e.target.files
    },
    resetFile() {
      this.detail.attachment = ''
    },
    fileName(url) {
      let splits = url.split('announcement/')
      let fileName = splits[1]
      let lastIndexOfName = fileName.length - 1
      let firstPart = fileName.substr(0, 5)
      let lastPart = fileName.substr(lastIndexOfName - 5, lastIndexOfName)
      let shortFileName = `${firstPart}...${lastPart}`
      return shortFileName
    },
    viewAttachment(url) {
      window.open(url, '_blank')
    },
    publishAnnouncement(publishNow) {
      publishNow === 0 ? this.draftLoadingState = true : this.publishLoadingState = true
      this.submitted = true
      this.$v.detail.$touch()

      let formattedUrl = ''
      if (this.detail.url) {
        if (this.detail.url.includes('https')) {
          formattedUrl = this.detail.url.slice(8)
        } else if (this.detail.url.includes('http')) {
          formattedUrl = this.detail.url.slice(7)
        } else {
          formattedUrl = this.detail.url
        }
      }

      if (this.$v.detail.$invalid) {

        publishNow === 0 ? this.draftLoadingState = false : this.publishLoadingState = false
        return

      } else {

        let tag

        if (this.detail.tag.length > 1) {
          tag = this.detail.tag.map(item => item.value).join(', ')
        } else if (this.detail.tag.length === 1) {
          tag = this.detail.tag[0].value
        }

        // let detail = {
        //   hash_id: this.detail.id ? this.detail.id : this.detail.hash_id,
        //   title: this.detail.title,
        //   content: this.detail.content,
        //   is_published: publishNow,
        //   is_important: this.detail.is_important,
        //   published_date: this.detail.published_date,
        //   tag: tag,
        //   url_title: this.detail.url_title,
        //   url: formattedUrl
        // }
        
        let detail = new FormData()
        detail.append('hash_id', this.detail.id ? this.detail.id : this.detail.hash_id)
        detail.append('title', this.detail.title)
        detail.append('content', this.detail.content)
        detail.append('is_published', publishNow)
        detail.append('is_important', this.detail.is_important)
        detail.append('published_date', this.detail.published_date)
        detail.append('tag', tag)
        detail.append('url_title', this.detail.url_title)
        detail.append('url', formattedUrl)
        detail.append('attachment', this.detail.attachment[0])

        if (this.detail.id) {

          this.putAnnouncement({ 
            data: detail, 
            token: this.authorization
          })
          .then(res => {
            publishNow === 0 ? this.draftLoadingState = false : this.publishLoadingState = false
            this.closeModal()
            this.setData(null)
            this.getAnnouncement({
              page: 1,
              tag: 'all',
              important: 0,
              token: this.authorization
            })
            this.$_successAlert(res.data.message)
          })
          .catch(err => {
            publishNow === 0 ? this.draftLoadingState = false : this.publishLoadingState = false
            this.submitted = false
            err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
          })

        } else {

          this.createAnnouncement({ 
            data: detail, 
            token: this.authorization
          })
          .then(res => {
            publishNow === 0 ? this.draftLoadingState = false : this.publishLoadingState = false
            this.closeModal()
            this.setData(null)
            this.getAnnouncement({
              page: 1,
              tag: 'all',
              important: 0,
              token: this.authorization
            })
            this.$_successAlert(res.data.message)
          })
          .catch(err => {
            publishNow === 0 ? this.draftLoadingState = false : this.publishLoadingState = false
            this.submitted = false
            err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
          })

        }
      }
    },
    removeAnnouncement(id) {
      this.deleteAnnouncement({
        id,
        token: this.authorization
      })
      .then(res => {
        this.closeModal()
        this.setData(null)
        this.getAnnouncement({
          page: 1,
          tag: this.params.tag,
          important: this.params.important,
          token: this.authorization
        })
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  validations: {
    detail: {
      title: {required},
      tag: {required},
      content: {required},
      published_date: {required}
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

.pointer
  cursor: pointer

.text
  
  &-small
    font-size: 13px

  &-med
    font-size: 14px

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.tags

  &-selector
    min-height: 28px
    border-radius: 5px
    background-color: #1B55E2

  &-text
    font-size: 0.8rem
    padding: 1px 10px
    color: #FFFFFF

.v-select::v-deep .vs__dropdown-toggle
  // height: 28px
  max-height: auto
  border-radius: 0 5px 5px 0

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF
    
.v-select::v-deep .vs__selected-options
  font-size: 0.8rem
  // display: flex
  // flex-direction: row
  // flex-wrap: nowrap

.v-select::v-deep .vs__search
  margin: auto

.optional-group
  margin-top: 10px
  border: 1px solid #DFDFDF
  border-radius: 5px

  &__title
    display: table  
    margin-top: -13px

  .dropbox
    outline: 2px dashed grey
    outline-offset: -10px
    background: lightcyan
    color: dimgray
    padding: 10px
    min-height: 200px
    position: relative
    cursor: pointer

    &:hover
      background: lightblue
    
    p
      font-size: 1.2em
      text-align: center
      padding: 65px 0

  .input-file
    opacity: 0
    width: 100%
    height: 200px
    position: absolute
    cursor: pointer

.file-button
  border: none
  text-transform: none
  text-decoration: none
  background-color: #F2F2F2
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)

  &__clear
    background: none
    border: none

    &:hover
      transform: scale(1.1)

    svg
      color: #f44336 !important

  svg
    color: #707070

  span
    font-size: 0.8rem
    color: #009688

.download-button
  border: none
  text-transform: none
  text-decoration: none
  background-color: #F2F2F2
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.1s
  transition-property: box-shadow, transform

  svg
    color: #707070

  span
    font-size: 0.8rem
    color: #009688
  
  &:hover
    background-color: #F0EFEC
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5)
    transform: scale(1.1)
</style>