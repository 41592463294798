<template>
  <div class="widget-four">

    <div class="widget-heading">
      <h4 class="text-purple-custom font-weight-bold">Karyawan Ulang Tahun</h4>
    </div>

    <div class="widget-content">
      <div class="vistorsBrowser birth-date-container">

        <div v-if="!employeeBirthDate">
          <div 
            v-for="item in 3" 
            :key="item.id" 
            class="w-100 mb-2 d-flex flex-row align-items-center justify-content-between"
          >
            <PuSkeleton 
              circle 
              width="50px" 
              height="50px"
            />
            <PuSkeleton class="w-25"/>
            <PuSkeleton class="w-25"/>
            <PuSkeleton class="w-25"/>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-center">
            <PuSkeleton 
              circle 
              height="32px" 
              width="32px"
            />
            <PuSkeleton 
              circle 
              height="32px" 
              width="32px"
            />
            <PuSkeleton 
              circle 
              height="32px" 
              width="32px"
            />
            <PuSkeleton 
              circle 
              height="32px" 
              width="32px"
            />
            <PuSkeleton 
              circle 
              height="32px" 
              width="32px"
            />
          </div>
        </div>

        <div 
          v-else-if="employeeBirthDate && employeeBirthDate.data.length" 
          class="d-flex flex-column justify-content-between"
        >
          <div>
            <div 
              v-for="employee in employeeBirthDate.data" 
              :key="employee.id" 
              class="mb-4"
            >
              <div 
                v-if="compact" 
                class="d-flex flex-row align-items-center justify-content-between birth-date"
              >
                <div class="d-flex flex-row">
                  <div class="birth-date__user">
                    <img 
                      :src="employee.photo_url" 
                      alt="Foto profil" 
                      class="mx-2 small-pic"
                    >
                  </div>
                  <div class="mx-2 d-flex flex-column">
                    <span class="mx-2 font-weight-bold">{{ employee.name }}</span>
                    <span class="mx-2 birth-date__date">{{ employee.birth_date }}</span>
                  </div>
                </div>
                <span 
                  class="birth-date__day" 
                  :class="employee.keterangan == 'today' ? 'birth-date__day--today' : 'birth-date__day--tomorrow'"
                >
                  {{ employee.keterangan == 'today' ? 'Hari ini' : 'Besok' }}
                </span>
              </div>
              <div v-else>
                <div class="d-flex d-xl-none flex-row align-items-center justify-content-between birth-date">
                  <div class="d-flex flex-row">
                    <div class="birth-date__user">
                      <img 
                        :src="employee.photo_url" 
                        alt="Foto profil" 
                        class="mx-2 small-pic"
                      >
                    </div>
                    <div class="mx-2 d-flex flex-column">
                      <span class="mx-2 font-weight-bold">{{ employee.name }}</span>
                      <span class="mx-2 birth-date__date">{{ employee.birth_date }}</span>
                    </div>
                  </div>
                  <span 
                    class="mx-2 birth-date__day" 
                    :class="employee.keterangan == 'today' ? 'birth-date__day--today' : 'birth-date__day--tomorrow'"
                  >
                    {{ employee.keterangan == 'today' ? 'Hari ini' : 'Besok' }}
                  </span>
                </div>
                <div class="w-100 px-3 d-none d-xl-flex flex-row align-items-center birth-date">
                  <div class="w-50 birth-date__user d-flex flex-row align-items-center">
                    <img 
                      :src="employee.photo_url" 
                      alt="Foto profil" 
                      class="mx-2 small-pic"
                    >
                    <span class="mx-2">{{ employee.name }}</span>
                  </div>
                  <div class="w-50 d-flex flex-row align-items-center justify-content-between">
                    <span class="mx-2 birth-date__date">{{ employee.birth_date }}</span>
                    <span 
                      class="mx-2 birth-date__day" 
                      :class="employee.keterangan == 'today' ? 'birth-date__day--today' : 'birth-date__day--tomorrow'"
                    >
                      {{ employee.keterangan == 'today' ? 'Hari ini' : 'Besok' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BasePagination
            v-if="employeeBirthDate.recordsTotal > 3"
            :firstPage="employeeBirthDatePagination.firstPage"
            :previousPage="employeeBirthDatePagination.previousPage"
            :currentPage="employeeBirthDatePagination.currentPage"
            :nextPage="employeeBirthDatePagination.nextPage"
            :lastPage="employeeBirthDatePagination.lastPage"
            :totalRecords="employeeBirthDate.recordsTotal"
            @first-page="birthDateList(employeeBirthDatePagination.firstPage)"
            @previous-page="birthDateList(employeeBirthDatePagination.previousPage)"
            @next-page="birthDateList(employeeBirthDatePagination.nextPage)"
            @last-page="birthDateList(employeeBirthDatePagination.lastPage)"
          />
        </div>

        <div v-else-if="employeeBirthDate && !employeeBirthDate.data.length">
          <img 
            src="@/assets/svg/no-crowd.svg" 
            alt="No birthdate" 
            class="w-100 mb-2" 
            style="height: 200px;"
          >
          <p class="px-4 text-black font-weight-bold">Tidak ada karyawan ulang tahun dalam jangka waktu dekat.</p>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import BasePagination from '@/components/base/BasePagination'

export default {
  name: 'DashboardBirthDate',
  components: {
    BasePagination
  },
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('baseComponents', ['employeeBirthDate', 'employeeBirthDatePagination'])
  },
  created() {
    this.getEmployeeBirthDate({
      page: 1,
      token: this.authorization
    })
  },
  methods: {
    ...mapActions('baseComponents', ['getEmployeeBirthDate']),
    birthDateList(page) {
      this.getEmployeeBirthDate({
        page: page,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.birth-date-container
  min-height: 240px

.small-pic
  height: 50px
  width: 50px
  object-fit: cover
  border-radius: 50%
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.3s
  transition-property: border-radius

  &:hover
    border-radius: 5px

.birth-date

  &__day
    width: 100px
    text-align: center
    border: 2px dashed black

    &--today
      color: #4CAF50
      border-color: #4CAF50
    
    &--tomorrow
      color: #FF9800
      border-color: #FF9800
</style>
