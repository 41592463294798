<template>
  <div class="vw-100 vh-100 d-flex flex-row justify-content-between">
    <AuthForgotPassword/>
    <AuthPicture/>
  </div>
</template>

<script>
import AuthForgotPassword from '@/components/auth/AuthForgotPassword'
import AuthPicture from '@/components/auth/AuthPicture'

export default {
  name: 'ForgotPassword',
  components: {
    AuthForgotPassword,
    AuthPicture
  }
}
</script>

<style lang="sass" scoped>

</style>