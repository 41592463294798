<template>
  <div class="widget-four">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h4 class="text-purple-custom font-weight-bold">{{ title }}</h4>
      <v-popover 
        offset="5" 
        placement="left-start"
      >
        <button class="badge-custom">
          <svg 
            style="width:18px;height:18px" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" 
            />
          </svg>
          <span>
            {{ selectedMonth.label }} {{ year }}
          </span>
        </button>
        <template #popover>
          <div class="d-flex flex-column">
            <button 
              v-for="month in months" 
              :key="month.id" 
              class="dropdown-button" 
              v-close-popover
              @click="changeMonth(month.label, month.value)" 
            >
              {{ month.label }}
            </button>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="widget-content custom-height">
      <div
        class="custom-height my-2 d-flex justify-content-between"
        :class="flexAlignment === 'vertical' ? 'flex-column align-items-xl-start' : 'flex-column flex-xl-row align-items-xl-center'"
      >
        <div class="d-flex flex-row align-items-center">
          <slot name="firstItem"></slot>
        </div>
        <div class="d-flex flex-row align-items-center">
          <slot name="secondItem"></slot>
        </div>
        <div class="d-flex flex-row align-items-center">
          <slot name="thirdItem"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseNotValidAttendance',
  props: {
    title: String,
    date: String,
    flexAlignment: String
  },
  data() {
    return {
      dropdown: false,
      year: new Date().toLocaleDateString('id-ID', { year: 'numeric' }),
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      },
      months: [
        {
          label: 'Januari',
          value: '1'
        },
        {
          label: 'Februari',
          value: '2'
        },
        {
          label: 'Maret',
          value: '3'
        },
        {
          label: 'April',
          value: '4'
        },
        {
          label: 'Mei',
          value: '5'
        },
        {
          label: 'Juni',
          value: '6'
        },
        {
          label: 'Juli',
          value: '7'
        },
        {
          label: 'Agustus',
          value: '8'
        },
        {
          label: 'September',
          value: '9'
        },
        {
          label: 'Oktober',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'Desember',
          value: '12'
        },
      ]
    }
  },
  methods: {
    changeMonth(label, value) {
      this.selectedMonth = {
        label: label,
        value: value
      }
      this.dropdown = false
      this.$emit('change-month', value)
    }
  }
}
</script>

<style lang="sass" scoped>
.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff

.custom-height
  height: 90%

.cursor-pointer
  cursor: pointer
</style>