<template>
  <div class="header mb-5 pt-5 w-md-100 w-50">
    <img src="@/assets/img/bpp/datangg-logo-plain.png" alt="Logo Datangg" class="header__img w-100">
    <h1 class="header__title w-100">#BerdikariMembangunNKRI</h1>
  </div>
</template>

<script>
export default {
  name: 'AuthHeader'
}
</script>

<style lang="sass" scoped>
.header

  &__title
    font-size: 1.3rem
    color: #707070

  &__img
    width: 300px

@media screen and (max-width: 575px) 
  .header

    &__title
      font-size: 1.1rem
</style>