<template>
  <div class="h-100 d-xl-block d-none">
    <img src="@/assets/img/bpp/login-side.png" alt="" class="h-100">
  </div>
</template>

<script>
export default {
  name: 'AuthPicture'
}
</script>

<style lang="sass" scoped>
</style>