<template>
  <div class="widget widget-five custom-widget">
    <div class="widget-content">
      <div 
        class="header" 
        :class="headerStyle === 'main' ? 'custom-header--main' : 'custom-header--secondary'"
      >
        <div class="header-body">
          <h5 
            class="mb-1 mt-3" 
            :class="headerStyle === 'main' ? 'text-white' : 'text-black'"
          >
            Menunggu Persetujuan
          </h5>
          <p 
            class="meta-date" 
            :class="headerStyle === 'main' ? 'text-white' : ''"
          >
            {{ date }}
          </p>
        </div>
      </div>

      <div class="h-100 w-content d-flex flex-row align-items-start justify-content-around">
        <div v-if="attendance">
          <h6 v-if="headerStyle === 'main'">Kehadiran</h6>
          <div>
            <p 
              v-if="attendanceWaitingApproval >= 0" 
              class="task-left text-black"
            >
              {{ attendanceWaitingApproval }}
            </p>
            <p 
              v-else 
              class="mb-3"
            >
              <PuSkeleton 
                circle 
                height="100px" 
                width="100px"
              />
            </p>
          </div>
          <p 
            v-if="additionalContent" 
            class="task-completed"
          >
            <span v-if="attendanceApproved >= 0">{{ attendanceApproved }} Selesai</span>
            <PuSkeleton 
              v-else 
              width="100px"
            />
          </p>
          <p v-if="additionalContent">
            <span 
              v-if="attendanceRejected >= 0" 
              class="task-hight-priority"
            > 
              <span>{{ attendanceRejected }} Di tolak</span> pengajuannya 
            </span>
            <PuSkeleton 
              v-else 
              width="200px"
            />
          </p>
        </div>
        <div v-if="permission">
          <h6 v-if="headerStyle === 'main'">Izin</h6>
          <div>
            <p 
              v-if="permissionWaitingApproval >= 0" 
              class="task-left text-black"
            >
              {{ permissionWaitingApproval }}
            </p>
            <p 
              v-else 
              class="mb-3"
            >
              <PuSkeleton 
                circle 
                height="100px" 
                width="100px"
              />
            </p>
          </div>
          <p 
            v-if="additionalContent" 
            class="task-completed"
          >
            <span v-if="permissionApproved >= 0">{{ permissionApproved }} Selesai</span>
            <PuSkeleton 
              v-else 
              width="100px"
            />
          </p>
          <p v-if="additionalContent">
            <span 
              v-if="permissionRejected >= 0" 
              class="task-hight-priority"
            > 
              <span>{{ permissionRejected }} Di tolak</span> pengajuannya 
            </span>
            <PuSkeleton 
              v-else 
              width="200px"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseWaitingApproval',
  props: {
    attendance: Boolean,
    permission: Boolean,
    headerStyle: String,
    date: {
      type: String,
      default: new Date().toLocaleDateString('id-ID', { year: 'numeric', month: 'long' })
    },
    attendanceWaitingApproval: Number,
    attendanceApproved: Number,
    attendanceRejected: Number,
    permissionWaitingApproval: Number,
    permissionApproved: Number,
    permissionRejected: Number,
    additionalContent: Boolean
  }
}
</script>

<style lang="sass" scoped>
.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1976D2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.custom-widget
  padding: 0
  border-radius: 6px

.task-left
  height: 100px !important
  width: 100px !important
  padding: 25px !important

  &--custom
    background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important
</style>