<template>
  <div class="note-inner-content d-flex flex-column justify-content-between">

    <!-- Note content -->
    <div class="note-content">
      <div class="note-title d-flex flex-row justify-content-between">
        <p class="mb-0 d-block text-truncate">{{ title }}</p>
        <svg 
          v-if="published === 0 && userAccess.allow_announcement_update" 
          style="width:20px;height:20px" 
          viewBox="0 0 24 24" 
          class="ml-1"
          @click.stop="$emit('edit-announcement')" 
        >
          <path 
            fill="currentColor" 
            d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" 
          />
        </svg>
      </div>
      <p class="meta-time">
        <span 
          v-if="published === 0" 
          class="font-italic"
        >
          Draft
        </span>
        <span v-else>{{ date }}</span>
      </p>
      <div class="note-description-content">
        <div v-html="content" class="note-description"></div>
      </div>
    </div>
    <!-- Note content -->

    <!-- Note action and indicator -->
    <div class="w-100 note-action">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
          <div v-if="userAccess.allow_announcement_update">
            <svg 
              v-if="important === 0" 
              style="width:24px;height:24px" 
              viewBox="0 0 24 24" 
              class="m-0"
              @click.stop="$emit('toggle-important')" 
            >
              <path 
                fill="#515365" 
                d="M6.5,17H15L18.5,12L15,7H6.5L10,12L6.5,17M15,19H3L7.5,12L3,5H15C15.69,5 16.23,5.3 16.64,5.86L21,12L16.64,18.14C16.23,18.7 15.69,19 15,19Z" 
              />
            </svg>
            <svg 
              v-else-if="important === 1" 
              style="width:24px;height:24px" 
              viewBox="0 0 24 24" 
              class="m-0"
              @click.stop="$emit('toggle-important')" 
            >
              <path 
                fill="#e2a03f" 
                d="M3.5,19L8.34,12L3.5,5H14.5C15.17,5 15.72,5.3 16.13,5.86L20.5,12L16.13,18.14C15.72,18.7 15.17,19 14.5,19H3.5Z" 
              />
            </svg>
          </div>
          <v-popover 
            v-if="userAccess.allow_announcement_delete"
            offset="5" 
            placement="top" 
            :open="deletePopover"
          >
            <svg 
              @click.stop="deletePopover = !deletePopover" 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              stroke-width="2" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              class="feather feather-trash-2 delete-note"
            >
              <polyline points="3 6 5 6 21 6" />
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
              <line x1="10" y1="11" x2="10" y2="17" />
              <line x1="14" y1="11" x2="14" y2="17" />
            </svg>
            <template #popover>
              <p class="font-weight-bold">Hapus pengumuman?</p>
              <div class="d-flex">
                <BaseButton 
                  class="button button--cancel mr-2" 
                  text="Batal" 
                  v-close-popover
                />
                <BaseButton 
                  class="button button--danger" 
                  text="Hapus" 
                  v-close-popover
                  @button-click="$emit('delete-item')"
                />
              </div>
            </template>
          </v-popover>
        </div>
        <div class="d-flex flex-row">
          <div 
            v-if="tags === 'all'" 
            class="tags tags--all"
          ></div>
          <div 
            v-else-if="selectedTags[0].value" 
            class="tags tags--office"
          ></div>
          <div 
            v-else-if="selectedTags[1].value" 
            class="tags tags--pool"
          ></div>
          <div 
            v-else-if="selectedTags[2].value" 
            class="tags tags--site"
          ></div>
          <div 
            v-else-if="selectedTags[3].value" 
            class="tags tags--tb"
          ></div>
        </div>
      </div>
    </div>
    <!-- Note action and indicator -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'AnnouncementItem',
  components: {
    BaseButton
  },
  props: {
    title: String,
    date: String,
    content: String,
    tags: String,
    important: Number,
    published: Number
  },
  data() {
    return {
      deletePopover: false
    }
  },
  computed: {
    ...mapGetters('auth', ['userAccess']),
    selectedTags() {
      return [
        {
          name: 'office',
          value: this.tags.includes('office')
        },
        {
          name: 'pool',
          value: this.tags.includes('pool')
        },
        {
          name: 'site',
          value: this.tags.includes('site')
        },
        {
          name: 'tug_boat',
          value: this.tags.includes('tug_boat')
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.fav-note

  &--true
    fill: #e2a03f !important

  &--false
    fill: none !important

.tags
  margin: 0 2px
  height: 12px
  width: 12px
  border: 2.5px solid
  border-radius: 50%

  &--all
    border-color: #02BFBF
    background-color: #CEFCFC

  &--office
    border-color: #00A416
    background-color: #CEFCD3

  &--pool
    border-color: #FF0000
    background-color: #FFA7A7

  &--site
    border-color: #9CA522
    background-color: #F4F8BE

  &--tb
    border-color: #2800FF
    background-color: #C3B8FF
</style>
