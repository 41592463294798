<template>
  <div class="vw-100 vh-100 d-flex flex-row justify-content-between">
    <AuthLogin/>
    <AuthPicture/>
  </div>
</template>

<script>
import AuthLogin from '@/components/auth/AuthLogin'
import AuthPicture from '@/components/auth/AuthPicture'

export default {
  name: 'Login',
  components: {
    AuthLogin,
    AuthPicture
  }
}
</script>

<style lang="sass" scoped>

</style>