const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: true,
  strokeOpacity: 1,
  strokeWeight: 1,
  strokeColor: "#FF0000",
};

const mapSettings = {
  clickableIcons: false,
  panControlOptions: false,
  gestureHandling: "cooperative",
  zoomControlOptions: {
    style: "SMALL"
  },
  zoom: 17,
  minZoom: 2,
  maxZoom: 20,
};

export { mapSettings, LINE_PATH_CONFIG };
