<template>
  <div class="footer text-center px-3 pt-3 mb-3">
    <span>© 2020 All Rights Reserved. PT Berdikari Pondasi Perkasa.</span> 
  </div>
</template>

<script>
export default {
  name: 'AuthFooter'
}
</script>

<style lang="sass" scoped>
.footer

  margin-top: auto

</style>