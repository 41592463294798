<template>
  <div class="custom-search">
    <input 
      id="table-search" 
      type="text" 
      name="table-search" 
      placeholder="Cari..." 
      class="custom-search__input" 
      :value="globalSearch" 
      @input="searchData($event.target.value)"
      @keypress.enter="$emit('search-function')">
    <button 
      class="custom-search__button"
      @click="$emit('search-function')" 
    >
      <svg 
        style="width:24px;height:24px" 
        viewBox="0 0 24 24"
      >
        <path 
          fill="currentColor" 
          d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" 
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseCustomSearch',
  props: {
    globalSearch: String
  },
  methods: {
    searchData(globalSearch) {
      this.$emit('input', globalSearch)
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-search
  padding: 5px
  display: flex
  flex-direction: row
  border: 1px solid #BDBDBD
  border-radius: 25px

  &__input
    border: none
    width: 125px

  &__button
    padding: 3px
    height: 25px
    width: 25px
    display: flex
    align-items: center
    background-color: #1B55E2
    color: #ffffff
    border: none
    border-radius: 50%
  
  &:hover
    border-color: #409AFF

  &:focus-within
    border-color: #409AFF

.button-custom
  border-color: #009688
  background: none
  color: #009688
  border-radius: 5px
</style>