<template>
    <div class="page-header d-flex flex-column">
      <div class="breadcrumb-custom">

        <span 
          class="custom__item--first" 
          :class="{ 'text-small custom__item--first-simplified': simplified }"
        >
          {{ firstBreadcrumb }}
        </span>

        <span 
          v-if="!thirdBreadcrumb" 
          class="custom__item--second" 
          :class="thirdBreadcrumb ? 'custom__item--disabled' : ''"
        >
          <b><span v-if="secondBreadcrumb">• </span>{{ secondBreadcrumb }}</b>
        </span>

        <router-link 
          v-else-if="thirdBreadcrumb" 
          :to="secondPath" 
          class="custom__item--second" 
          :class="thirdBreadcrumb ? 'custom__item--disabled' : ''"
        >
          <b>• {{ secondBreadcrumb }}</b>
        </router-link>
        <span 
          v-if="thirdBreadcrumb" 
          class="custom__item--third"
        >
          <b>• {{ thirdBreadcrumb }}</b>
        </span>
        
      </div>
      <hr class="page-header__divider w-100">
    </div>
</template>

<script>
export default {
  name: 'BaseContentHeader',
  props: {
    firstBreadcrumb: String,
    secondPath: String,
    secondBreadcrumb: String,
    thirdBreadcrumb: String,
    simplified: Boolean
  }
}
</script>

<style lang="sass" scoped>
.text-small
  font-weight: bold
  font-size: 16px !important
  
.page-header 
  display: flex
  padding: 0
  margin-bottom: 16px
  margin-top: 30px

  &__divider
    margin: 0
    border: 1px solid #707070


.breadcrumb-custom
  position: relative
  bottom: -3px
  width: 100%

  .custom__item

    &--first
      font-size: 32px
      border-bottom: 3px solid #2196F3

    &--first-simplified
      font-size: 32px
      border-bottom: 3px solid #000000

    &--second
      margin-left: 10px
      color: #2196F3
      
    &--third
      margin-left: 10px
      color: #2196F3
      
    &--disabled
      margin-left: 10px
      color: #9E9E9E
      
@media(max-width: 575px) 
  .page-header
    display: block
    
    .page-title 
      margin-bottom: 20px
      border: none
      padding-right: 0
      margin-right: 0
</style>