<template>
  <div class="d-flex flex-row align-items-center">
    <span class="mr-2">Baris:</span>
    <span>
      <v-select 
        class="w-custom" 
        :clearable="false" 
        :options="perPage" 
        :value="limitValue" 
        @input="limitResults($event)"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#888EA8" 
              stroke-width="2" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              class="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </span>
        </template>
      </v-select>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseLimitSelection',
  props: {
    limitValue: Number
  },
  data() {
    return {
      perPage: [10, 25, 50, 100]
    }
  },
  methods: {
    limitResults(limitValue) {
      this.$emit('input', limitValue)
    }
  }
}
</script>

<style lang="sass" scoped>
.w-custom
  width: 95px
</style>