<template>
  <div class="google-map" ref="googleMap">
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map"/>
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'

export default {
  name: 'MapsLoader',
  data() {
    return {
      google: null,
      map: null
    }
  },
  props: {
    mapConfig: Object,
    apiKey: String
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
    }
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap()
  }
}
</script>

<style lang="sass" scoped>
.google-map 
  width: 100%
  min-height: 100%
</style>