<template>
  <div class="widget-four d-flex flex-column justify-content-between">
    <div 
      v-if="headerAlignment === 'main'" 
      class="widget-heading d-flex justify-content-between"
    >
      <div>
        <img 
          :src="require(`../../assets/svg/${icon}`)" 
          alt="Widget Icon"
        >
        <h4 :class="`mt-2 ${customStyleText}`">{{ title }}</h4>
      </div>
      <v-popover 
        offset="5" 
        placement="left-start" 
        class="align-self-end align-self-md-baseline"
      >
        <button class="badge-custom">
          <svg 
            style="width:18px;height:18px" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" 
            />
          </svg>
          <span>
            {{ selectedMonth.label }} {{ year }}
          </span>
        </button>
        <template #popover>
          <div class="d-flex flex-column">
            <button 
              v-for="month in months" 
              :key="month.id" 
              class="dropdown-button" 
              v-close-popover
              @click="changeMonth(month.label, month.value)" 
            >
              {{ month.label }}
            </button>
          </div>
        </template>
      </v-popover>
    </div>
    <div 
      v-else-if="headerAlignment === 'simple'" 
      class="widget-heading d-flex justify-content-between"
    >
      <div class="widget-heading d-flex">
        <div>
          <img 
            :src="require(`../../assets/svg/${icon}`)" 
            alt="Widget Icon"
          >
        </div>
        <div class="ml-3 text-purple-custom">
          <h5 :class="`mb-0 ${customStyleText}`">{{ title }}</h5>
          <p 
            class="text-secondary" 
            style="font-size: 13px;"
          >
          {{ monthName() }} {{ new Date().getFullYear() }}
          </p>
        </div>
      </div>
    </div>
    <div class="widget-content">
      <div class="vistorsBrowser">
        <div class="browser-list">
          <div class="w-browser-details">
            <div class="w-browser-info">
              <h6 :class="customStyleText">{{ desc }}</h6>
              <slot name="dataTotal"></slot>
            </div>
            <div class="w-browser-stats">
              <div class="progress">
                <slot name="dataPercentage"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseProgress',
  props: {
    icon: String,
    title: String,
    desc: String,
    customStyleText: String,
    headerAlignment: String
  },
  data() {
    return {
      dropdown: false,
      year: new Date().toLocaleDateString('id-ID', { year: 'numeric' }),
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      },
      months: [
        {
          label: 'Januari',
          value: '1'
        },
        {
          label: 'Februari',
          value: '2'
        },
        {
          label: 'Maret',
          value: '3'
        },
        {
          label: 'April',
          value: '4'
        },
        {
          label: 'Mei',
          value: '5'
        },
        {
          label: 'Juni',
          value: '6'
        },
        {
          label: 'Juli',
          value: '7'
        },
        {
          label: 'Agustus',
          value: '8'
        },
        {
          label: 'September',
          value: '9'
        },
        {
          label: 'Oktober',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'Desember',
          value: '12'
        },
      ]
    }
  },
  methods: {
    changeMonth(label, value) {
      this.selectedMonth = {
        label: label,
        value: value
      }
      this.dropdown = false
      this.$emit('change-month', value)
    },
    monthName() {
      let monthName = this.months.find(month => month.value == new Date().getMonth() + 1)
      return monthName.label
    }
  }
};
</script>

<style lang="sass" scoped>
.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff

.bg-purple-custom
  background-color: #5C1AC3

.text-purple-custom
  color: #5C1AC3 !important

.bg-turqoise-custom
  background-color: #009688

.text-turqoise-custom
  color: #009688 !important

.bg-purple-custom2
  background-color: #9C27B0

.text-purple-custom2
  color: #9C27B0 !important
</style>