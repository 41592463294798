<template>
  <div class="position-fixed d-flex flex-column floating-button-container">
    <button @click="moveToTop">
      <svg 
        style="width:24px;height:24px" 
        viewBox="0 0 24 24"
      >
        <path 
          fill="#ffffff" 
          d="M12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M17,14L12,9L7,14H17Z" 
        />
      </svg>
    </button>
    <button @click="moveToBottom">
      <svg 
        style="width:24px;height:24px" 
        viewBox="0 0 24 24"
      >
        <path 
          fill="#ffffff" 
          d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M7,10L12,15L17,10H7Z" 
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseFloatingButtons',
  methods: {
    moveToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    moveToBottom() {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="sass" scoped>
.floating-button-container
  bottom: 40px
  right: 20px
  
  button
    opacity: .25
    padding: 10px
    background-color: #1B55E2
    border: 0

    &:hover
      opacity: 1

    &:nth-child(1)
      border-radius: 10px 10px 0 0

    &:nth-child(2)
      border-radius: 0 0 10px 10px
</style>