<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="false"
    :smallModal="true">

    <template #body>
      <div class="w-100 d-flex flex-column">
        <div class="steps"></div>
        <div class="form">

          <!-- First step -->
          <div v-if="steps === 1" class="form__item  align-items-center justify-content-center">
            <div class="steps">
              <div 
                class="steps__number"
                :class="{ 'steps__number--process': steps === 1, 'steps__number--done': steps > 1}">1</div>
              <div 
                class="steps__line"
                :class="{ 'steps__line--waiting': steps < 2, 'steps__line--done': steps > 1}"></div>
              <div 
                class="steps__number"
                :class="{ 'steps__number--waiting': steps < 2, 'steps__number--process': steps === 2, 'steps__number--done': steps > 2}">2</div>
              <div 
                class="steps__line"
                :class="{ 'steps__line--waiting': steps < 3, 'steps__line--done': steps === 3}"></div>
              <div 
                class="steps__number"
                :class="{ 'steps__number--waiting': steps < 3, 'steps__number--process': steps === 3}">3</div>
            </div>
            <form action="">
              <div class="d-flex flex-column align-items-center">
                <label for="username">Username</label>
                <input 
                  type="text" 
                  class="mt-3 custom-input"
                  :class="{'custom-input--error': submitted && !$v.username.required}"
                  v-model="username">
                <span v-if="submitted && !$v.username.required" class="text-danger font-weight-bold">Username tidak boleh kosong!</span>
              </div>
              <div class="mt-3 d-flex justify-content-center">
                <BaseButton 
                  class="mr-3 button--primary"
                  text="Selanjutnya ->"
                  :loading="firstStepLoading"
                  @button-click="firstStepCheck"/>
                <BaseButton 
                  class="button--cancel" 
                  text="Batal"
                  @button-click="closeModal"/> 
              </div>
            </form>
          </div>
          <!-- First step -->

          <!-- Second step -->
          <div v-else-if="steps === 2" class="form__item  align-items-center justify-content-center">
            <div class="steps">
              <div 
                class="steps__number"
                :class="{ 'steps__number--process': steps === 1, 'steps__number--done': steps > 1}">1</div>
              <div 
                class="steps__line"
                :class="{ 'steps__line--waiting': steps < 2, 'steps__line--done': steps > 1}"></div>
              <div 
                class="steps__number"
                :class="{ 'steps__number--waiting': steps < 2, 'steps__number--process': steps === 2, 'steps__number--done': steps > 2}">2</div>
              <div 
                class="steps__line"
                :class="{ 'steps__line--waiting': steps < 3, 'steps__line--done': steps === 3}"></div>
              <div 
                class="steps__number"
                :class="{ 'steps__number--waiting': steps < 3, 'steps__number--process': steps === 3}">3</div>
            </div>
            <form action="">
              <div class="mb-2 d-flex flex-column align-items-center">
                <label for="password">Password</label>
                <input
                  type="password"
                  class="mt-3 custom-input" 
                  :class="{'custom-input--error': submitted && !$v.password.required || submitted && !$v.password.minLength}"
                  v-model="password"> 
                <span class="text-danger font-weight-bold" v-if="submitted && !$v.password.required">Password harus diisi</span>
                <span class="text-danger font-weight-bold" v-if="submitted && !$v.password.minLength">Password setidaknya memiliki 6 karakter</span>
              </div>
              <div class="mt-3 d-flex justify-content-center">
                <BaseButton 
                  class="mr-3 button--primary" 
                  text="Selanjutnya ->"
                  :loading="secondStepLoading"
                  @button-click="secondStepCheck"/> 
                <BaseButton 
                  class="button--cancel" 
                  text="Batal"
                  @button-click="closeModal"/> 
              </div>
            </form>
          </div>
          <!-- Second step -->

          <!-- Third step -->
          <div v-else-if="steps === 3" class="form__item  align-items-center justify-content-center">
            <div class="steps">
              <div 
                class="steps__number"
                :class="{ 'steps__number--process': steps === 1, 'steps__number--done': steps > 1}">1</div>
              <div 
                class="steps__line"
                :class="{ 'steps__line--waiting': steps < 2, 'steps__line--done': steps > 1}"></div>
              <div 
                class="steps__number"
                :class="{ 'steps__number--waiting': steps < 2, 'steps__number--process': steps === 2, 'steps__number--done': steps > 2}">2</div>
              <div 
                class="steps__line"
                :class="{ 'steps__line--waiting': steps < 3, 'steps__line--done': steps === 3}"></div>
              <div 
                class="steps__number"
                :class="{ 'steps__number--waiting': steps < 3, 'steps__number--process': steps === 3}">3</div>
            </div>
            <form action="">
              <div class="d-flex flex-column align-items-center">
                <label for="dropdown">Hak Akses</label>
                <v-select 
                  class="w-75"
                  style="background-color: #ffffff"
                  label="name"
                  :options="userLevel"
                  v-model="accessLevel">
                  <template #no-options>
                    Data tidak ditemukan
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="#6E7BF5" d="M 7 10 L 12 19 L 17 10 H 7 Z" />
                      </svg>
                    </span>
                  </template>
                </v-select>
                <span class="text-danger font-weight-bold" v-if="submitted && !$v.accessLevel.required">Hak Akses harus diisi!</span>
              </div>
              <div class="mt-3 d-flex justify-content-center">
                <BaseButton 
                  class="mr-3 button--primary" 
                  text="Selanjutnya ->"
                  :loading="thirdStepLoading"
                  @button-click="thirdStepCheck"/> 
                <BaseButton 
                  class="button--cancel" 
                  text="Batal"
                  @button-click="closeModal"/> 
              </div>
            </form>
          </div>
          <!-- Third step -->

          <!-- Final step -->
          <div v-else-if="steps === 4" class="form__item">
            <h3 class="text-center">Selesai!</h3>
            <table class="w-50 table table-borderless">
              <tbody>
                <tr>
                  <th class="py-1" scope="row">Username</th>
                  <td class="py-1 text-black">: {{ username }}</td>
                </tr>
                <tr>
                  <th class="py-1" scope="row">Password</th>
                  <td class="py-1 text-black">: {{ `*`.repeat(password.length) }}</td>
                </tr>
                <tr>
                  <th class="py-1" scope="row">Hak Akses</th>
                  <td class="py-1 text-black">: {{ accessLevelName }}</td>
                </tr>
              </tbody>
            </table>
            <div class="w-100 text-center">
              <BaseButton 
                class="button--primary" 
                text="Selesai"
                @button-click="finalStep"/> 
            </div>
          </div>
          <!-- Final step -->
          
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'EmployeePersonaliaModal',
  data() {
    return {
      steps: 1,
      userLevel: null,
      username: '',
      password: '',
      accessLevel: '',
      accessLevelName: '',
      personaliaDetail : {},
      firstStepLoading: false,
      secondStepLoading: false,
      thirdStepLoading: false,
      submitted: false
    }
  },
  validations: {
    username: {required},
    password: {required, minLength: minLength(6)},
    accessLevel: {required}
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapMutations('employee', ['togglePersonaliaModal']),
    ...mapActions('employee', ['addEmployeePersonalia']),
    ...mapActions('sysAdmUserLevel', ['getUserLevel']),
    firstStepCheck() {
      this.firstStepLoading = true
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.username.required) {

        this.firstStepLoading = false
        return

      } else {

        setTimeout(() => {
          this.firstStepLoading = false
          this.steps = 2
          this.submitted = false
        }, 250)

      }
    },
    secondStepCheck() {
      this.secondStepLoading = true
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.password.required || !this.$v.password.minLength) {

        this.secondStepLoading = false
        return

      } else {

        setTimeout(() => {
          this.secondStepLoading = false
          this.steps = 3
          this.submitted = false
        }, 250);

      }
    },
    thirdStepCheck() {
      this.thirdStepLoading = true
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.accessLevel.required) {

        this.thirdStepLoading = false
        return

      } else {

        this.addEmployeePersonalia({
          hash_id: this.detail.hash_id,
          username: this.username,
          password: this.password,
          confirm_password: this.password,
          level_id: this.accessLevel.id,
          token: this.authorization
        })
        .then(() => {
          this.thirdStepLoading = false
          this.steps = 4
          this.submitted = false
          this.accessLevelName = this.accessLevel.name
        })
        .catch(err => {
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    },
    userLevelList() {
      this.getUserLevel(this.authorization)
      .then(res => this.userLevel = res.data.data)
    },
    finalStep() {
      this.TOGGLE_MODAL()
      this.togglePersonaliaModal() 
      this.steps = 1
    },
    closeModal() {
      this.TOGGLE_MODAL()
      this.togglePersonaliaModal()
      this.steps = 1
    }
  },
  mounted() {
    this.getUserLevel(this.authorization)
    this.userLevelList()
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapGetters('auth', ['authorization']),
    ...mapGetters('employee', ['detail'])
  }
}
</script>

<style lang="sass" scoped>
label
  font-size: 24px
  font-weight: 500
  color: #000000

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.steps
  margin-bottom: 10px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  color: #ffffff

  &__number
    height: 30px
    width: 30px
    border-radius: 50%
    line-height: 30px
    text-align: center

    &--process
      background-color: #0ABCD8
    
    &--done
      background-color: #6E7BF5

    &--waiting
      background-color: #EEEEEE

  &__line
    height: 5px
    width: 25px

    &--done
      background-color: #6E7BF5

    &--waiting
      background-color: #EEEEEE

select 
  appearance: none
  background-color: transparent
  border: none
  padding: 0 1em 0 0
  margin: 0
  width: 100%
  font-family: inherit
  font-size: inherit
  cursor: inherit
  line-height: inherit
  z-index: 1

  &::-ms-expand 
    display: none
  

  outline: none


.select 
  display: grid
  grid-template-areas: "select"
  align-items: center
  position: relative

  select,
  &::after 
    grid-area: select


  width: 300px

  border: 1px solid #707070
  border-radius: 5px
  padding: 5px
  cursor: pointer
  line-height: 1.1

  background-color: #fff
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%)

  &:not(.select--multiple)::after 
    content: ""
    justify-self: end
    width: 0.8em
    height: 0.5em
    background-color: #6E7BF5
    clip-path: polygon(100% 0%, 0 0%, 50% 100%)
  


select:focus + .focus 
  position: absolute
  top: -1px
  left: -1px
  right: -1px
  bottom: -1px
  border: 2px solid var(--select-focus)
  border-radius: inherit


.select--disabled 
  cursor: not-allowed
  background-color: #eee
  background-image: linear-gradient(to top, #ddd, #eee 33%)


label 
  font-size: 1.125rem
  font-weight: 500


.select + label 
  margin-top: 2rem
</style>