<template>
  <div class="wrapper w-100 d-flex flex-column justify-content-between align-items-center">
    <div class="main w-75">
      <AuthHeader/>
      <AuthFormHeader desc="Masukkan email Anda untuk reset password"/>
      <form action="" @submit.prevent="forgotPassword">

        <!-- Email input -->
        <div class="input d-flex flex-column">
          <label for="email">Email</label>
          <input
            type="email"
            :class="$v.email.$error ? 'input__field input__field--error' : 'input__field'"
            v-model="email"
            autofocus>
          <span class="input__text--error font-weight-bold" v-if="submitted && !$v.email.required">Email harus diisi</span>
          <span class="input__text--error font-weight-bold" v-if="submitted && !$v.email.email">Email tidak valid</span>
        </div>
        <!-- Email input -->

        <!-- Reset password page anchor -->
        <div class="mb-4 d-flex flex-row justify-content-xl-end">
          <router-link to="/reset-password">Sudah memiliki kode verifikasi?</router-link>
        </div>
        <!-- Reset password page anchor -->

        <div class="d-flex flex-row align-items-center">
          <button 
            type="submit"
            class="py-2 px-3 button button--fill"
            :disabled="loadingState"
            :style="loadingState ? 'cursor: not-allowed;':''">
            <span v-if="!loadingState">Kirim</span>
            <div class="mx-1 loader" v-if="loadingState"></div>
          </button>
          <router-link
            to="/login"
            type="submit"
            class="ml-3 py-2 px-3 button button--border">
            Batalkan
          </router-link>
        </div>

      </form>
    </div>
    <AuthFooter/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import AuthHeader from '@/components/auth/AuthHeader'
import AuthFormHeader from '@/components/auth/AuthFormHeader'
import AuthFooter from '@/components/auth/AuthFooter'

export default {
  name: 'AuthForsgotPassword',
  data () {
    return {
      email: '',
      submitted: false,
      loadingState: false
    }
  },
  components: {
    AuthHeader,
    AuthFormHeader,
    AuthFooter
  },
  validations: {
    email: { required, email }
  },
  methods: {
    ...mapActions('auth', ['submitForgotPassword']),
    forgotPassword() {
      this.loadingState = true
      this.submitted = true
      sessionStorage.userEmail = this.email
      this.$v.$touch()

      if (this.$v.$invalid) {
        
        this.loadingState = false
        return

      } else {
        
        let email = this.email
        let data = {email}

        this.submitForgotPassword(data)
        .then(() => {
          this.loadingState = false
          this.$router.push('/reset-password')
        })
        .catch(err => {
          this.loadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-label
  color: #000000
  margin: 0 5px 10px 0

label
  color: #000000

.input
  margin: 20px 0

  &__field
    height: 66px
    width: 100%
    padding: 0 20px
    border: none
    border-radius: 3px
    background-color: #F3F6F9

    &:focus
      border: 3px solid #1B55E2
  
    &:hover
      background-color: #D5E5F5

    &--error
      border: 3px solid #EE2A2A

  &__text--error
    color: #EE2A2A

.button
  transition: 0.2s
  text-transform: uppercase

  &--fill
    border: none
    border-radius: 7px
    background-color: #1B55E2
    color: #FFFFFF

    &:hover
      background-color: #1244BC
      box-shadow: 0 15px 20px -10px #1b55e2

  &--border
    border: 2px solid #1B55E2
    border-radius: 7px
    background-color: #FFFFFF
    color: #1B55E2

    &:hover
      background-color: #F6F6F6
      box-shadow: 0 15px 20px -10px #1b55e2
      text-decoration: none

.main
  margin-top: auto

.loader 
  font-size: 10px
  text-indent: -9999em
  width: 25px
  height: 25px
  border-radius: 50%
  background: #ffffff
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%)
  position: relative
  -webkit-animation: load3 1.4s infinite linear
  animation: load3 1.4s infinite linear
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)

.loader:before 
  width: 50%
  height: 50%
  background: #ffffff
  border-radius: 100% 0 0 0
  position: absolute
  top: 0
  left: 0
  content: ''

.loader:after 
  background: #1B55E2
  width: 75%
  height: 75%
  border-radius: 50%
  content: ''
  margin: auto
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0

@-webkit-keyframes load3 
  0% 
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  
  100% 
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
  

@keyframes load3 
  0% 
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  
  100% 
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
  


.wrapper
  background: linear-gradient(90deg, rgba(209, 227, 255, 0.9), rgba(209, 227, 255, 0.9)), url(../../assets/img/bpp/login-side.png) no-repeat fixed center
  background-size: cover

@media (min-width: 1200px) 
  .wrapper
    background: #ffffff
</style>