<template>
  <div class="vw-100 vh-100 d-flex flex-row justify-content-between">
    <AuthResetPassword/>
    <AuthPicture/>
  </div>
</template>

<script>
import AuthResetPassword from '@/components/auth/AuthResetPassword'
import AuthPicture from '@/components/auth/AuthPicture'

export default {
  name: 'ResetPassword',
  components: {
    AuthResetPassword,
    AuthPicture
  }
}
</script>

<style lang="sass" scoped>

</style>