<template>
  <div class="main__container d-flex flex-column">
    <span class="main__desc">{{desc}}</span>
  </div>
</template>

<script>
export default {
  name: 'AuthFormHeader',
  props: ['title', 'desc']
}
</script>

<style lang="sass" scoped>
.main

  &__container
    width: 100%

  &__desc
    line-height: 30px
    color: #000000
</style>