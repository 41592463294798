<template>
  <div 
    class="fixed-top" 
    :class="sidebarOpen ? 'header-container--open' : 'header-container--closed'"
  >
    <header 
      class="header navbar navbar-expand-sm" 
      :class="sidebarOpen ? '' : 'expand-header'"
    >
      <a 
        href="javascript:void(0);" 
        class="sidebarCollapse" 
        data-placement="bottom"
        @click="TOGGLE_SIDEBAR(); TOGGLE_SIDEBAR_MOBILE();" 
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          stroke-width="2" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          class="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"/>
          <line x1="3" y1="6" x2="21" y2="6"/>
          <line x1="3" y1="18" x2="21" y2="18"/>
        </svg>
      </a>

      <ul class="navbar-item flex-row ml-auto">
        <li 
          class="nav-item dropdown user-profile-dropdown order-lg-0 order-1"
        >
          <a 
            id="userProfileDropdown"
            href="#"
            role="button"
            class="nav-link dropdown-toggle user" 
            data-toggle="dropdown"
            aria-haspopup="true" 
            aria-expanded="false"
            style="cursor: pointer;"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              stroke-width="2" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              class="feather feather-user-check"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
              <circle cx="8.5" cy="7" r="4"/>
              <polyline points="17 11 19 13 23 9"/>
            </svg>
          </a>
          <div 
            class="dropdown-menu position-absolute e-animated e-fadeInUp"
            aria-labelledby="userProfileDropdown"
          >
            <div class="user-profile-section">                            
              <div class="media mx-auto">
                <div class="media-body">
                  <h5 class="ml-2">{{ userProfile.fullname }}</h5>
                </div>
              </div>
            </div>
            <div class="dropdown-item">
              <router-link to="/">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                  <circle cx="12" cy="7" r="4"/>
                </svg>
                <span>My Profile</span>
              </router-link>
            </div>
            <div class="dropdown-item">
              <router-link 
                to="/lock-screen"
                @click.native="lock" 
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-lock"
                >
                  <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"/>
                </svg>
                <span>Lock Screen</span>
              </router-link>
            </div>
            <div class="dropdown-item">
              <router-link
                to=""
                @click.native="changePassword"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-unlock"
                >
                  <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
                  <path d="M7 11V7a5 5 0 0 1 9.9-1"/>
                </svg>
                <span>Ubah Kata Sandi</span>
              </router-link>
            </div>
            <div class="dropdown-item">
              <router-link 
                @click.native="logout" 
                to="/login"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                  <polyline points="16 17 21 12 16 7"/>
                  <line x1="21" y1="12" x2="9" y2="12"/>
                </svg>
                <span>Log Out</span>
              </router-link>
            </div>

          </div>
        </li>
      </ul>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'BaseHeader',
  data() {
    return {
      profileOpen: false,
    }
  },
  computed: {
    ...mapState('styling', ['sidebarOpen']),
    ...mapGetters('auth', ['userProfile'])
  },
  methods: {
    ...mapMutations('auth', ['authLogout', 'authLock']),
    ...mapMutations('styling', ['TOGGLE_SIDEBAR', 'TOGGLE_SIDEBAR_MOBILE', 'toggleNewModal']),
    logout() {
      this.authLogout()
      this.$router.push('/login')
    },
    lock() {
      this.authLock()
      this.$router.push('/lock-screen')
    },
    closeProfile() {
      this.profileOpen = false
    },
    changePassword() {
      this.toggleNewModal(true)
    },
  }
}
</script>

<style lang="sass" scoped>
header
  padding: 0

.header-container

  &--closed
    padding: 10px 16px 0 16px
    background: #f1f2f3
  
  &--open
    padding: 10px 16px 0 16px
    background: #f1f2f3

@media (min-width: 992px) 
  .header-container
    
    &--closed
      padding: 10px 16px 0 80px
      background: #f1f2f3
    
    &--open
      padding: 10px 16px 0 48px
      background: #f1f2f3
</style>