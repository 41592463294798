<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>
      <div class="w-100">

        <!-- Top section -->
        <div class="modal-main__top w-100 d-flex flex-column-reverse flex-sm-row justify-content-between">
          <div class="top__left w-75 my-1 d-flex flex-column flex-lg-row align-items-lg-start justify-content-between">
            <div>
              <div class="d-flex flex-column mr-4">
                <p class="mb-1">ID Karyawan</p>
                <p class="text-black font-weight-bold">{{ detail.employee_number_formatted }}</p>
              </div>
              <div class="d-flex flex-column mr-4">
                <p class="mb-1">Nama</p>
                <p class="text-black font-weight-bold">{{ detail.name }}</p>
              </div>
              <div class="d-flex flex-column mr-4">
                <p class="mb-1">Email</p>
                <p class="text-black font-weight-bold">{{ detail.email }}</p>
              </div>
            </div>
            <div>
              <div class="d-flex flex-column">
                <p class="mb-1">Tanggal Bergabung</p>
                <p class="text-black font-weight-bold">{{ $_convertDate(detail.joined_date) }}</p>
              </div>
              <div class="d-flex flex-column">
                <p class="mb-1">Lokasi Kehadiran</p>
                <p class="text-black font-weight-bold">{{ detail.attendance_location_type }}</p>
              </div>
              <div class="d-flex flex-column">
                <p class="mb-1">Shift Pattern</p>
                <p class="text-black font-weight-bold">[{{ detail.shift_pattern_code}}] {{ detail.shift_pattern_name }}</p>
              </div>
            </div>
          </div>
          <div class="top__right w-25 mx-auto my-1 d-flex flex-column align-items-center align-items-lg-end">
            <v-popover offset="5" placement="left-end" trigger="hover">
              <img :src="detail.photo_url" alt="Foto karyawan">
              <template slot="popover">
                <img :src="detail.photo_url" alt="" style="width: 100%; border-radius: 5px;"/>
              </template>
            </v-popover>
          </div>
        </div>
        <!-- Top section -->

        <!-- Middle section -->
        <div class="modal-main__middle w-100 my-3 d-flex flex-row">
          <div @click="tabsToggle('manPowerInfo')" class="w-50 text-center title">
            <span :class="tabs.manPowerInfo ? 'title__text title__text--active' : 'title__text'">
              <b class="d-none d-md-inline">Info Man Power</b>
              <b class="d-inline d-md-none">Info MP</b>
            </span>
            <hr :class="tabs.manPowerInfo ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div @click="tabsToggle('manPowerLoc')" class="w-50 text-center title">
            <span :class="tabs.manPowerLoc ? 'title__text title__text--active' : 'title__text'">
              <b class="d-none d-md-inline">Lokasi Man Power</b>
              <b class="d-inline d-md-none">Lokasi MP</b>
            </span>
            <hr :class="tabs.manPowerLoc ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div @click="tabsToggle('leaveDetail')" class="w-50 text-center title">
            <span :class="tabs.leaveDetail ? 'title__text title__text--active' : 'title__text'">
              <b>Detail Cuti</b>
            </span>
            <hr :class="tabs.leaveDetail ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
        </div>
        <!-- Middle section -->

        <!-- Bottom section -->
        <div class="modal-main__bottom w-100">
          <div v-if="tabs.manPowerInfo">
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Tipe</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.man_power_type }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Man Power</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.man_power_name }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Supervisor</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.supervisor_employee_name }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Tim</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.man_power_team_name }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Grup</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.man_power_group_name }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Kepala Grup</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.group_head_employee_name }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Zona Waktu</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.man_power_timezone_name }}</p>
            </div>
          </div>
          <div v-else-if="tabs.manPowerLoc" class="location" style="height: 400px;">
            <MapsLoader
              :mapConfig="mapConfig"
              :apiKey="apiKey">
              <template slot-scope="{ google, map }">
                <MapsMarker
                  v-for="marker in markers"
                  :key="marker.id"
                  :marker="marker"
                  :specificMarker="specificMarker"
                  :google="google"
                  :map="map"/>
              </template>
            </MapsLoader>
          </div>
          <div v-else-if="tabs.leaveDetail">
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Saldo Cuti</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.cuti_saldo }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Cuti Terpakai</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.cuti_terpakai }}</p>
            </div>
            <div class="w-100 d-flex flex-column flex-lg-row">
              <p class="w-25 mb-0 mb-lg-3">Hutang Cuti</p>
              <p class="w-75 mb-lg-3 text-black font-weight-bold">{{ detail.cuti_hutang }}</p>
            </div>
          </div>
        </div>
        <!-- Bottom section -->

      </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-sm-items-center">
          <div class="d-flex flex-column flex-sm-row align-items-start align-sm-items-center">
            <div
              v-if="userAccess.allow_disabled_attendance"
              class="custom-control custom-checkbox mr-3"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                id="disable-attendance-toggle"
                v-model="detail.attendance_disabled"
                @change="toggleAttendanceDisabled(detail.employee_number_formatted, detail.attendance_disabled)"
                :true-value="1" 
                :false-value="0" 
              >
              <label
                class="custom-control-label"
                style="font-size: 16px;"
                for="disable-attendance-toggle"
              >
                Nonaktifkan Kehadiran
              </label>
            </div>
            <div
              v-if="userAccess.allow_force_approval_permission"
              class="custom-control custom-checkbox mr-3"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                id="force-approval-toggle"
                v-model="detail.force_approval_permission"
                @change="toggleForceApproval(detail.employee_number_formatted, detail.force_approval_permission)"
                :true-value="1" 
                :false-value="0" 
              >
              <label
                class="custom-control-label"
                style="font-size: 16px;"
                for="force-approval-toggle"
              >
                Izin selalu ke <i>Is Owner</i>
              </label>
            </div>
          </div>

          <BaseButton
            v-if="manPowerInfo && detail.is_personalia !== 1 && userAccess.allow_user_login_create" 
            @button-click="setPersonalia"
            class="button--success-bordered"
            text="Jadikan Personalia"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { mapSettings } from '@/constants/mapSettings'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'
import MapsLoader from '@/components/google-maps/MapsLoader'
import MapsMarker from '@/components/google-maps/MapsMarker'

export default {
  name: 'EmployeeMainModal',
  data() {
    return {
      manPowerInfo: true,
      manPowerLoc: false,
      tabs: {
        manPowerInfo: true,
        manPowerLoc: false,
        leaveDetail: false
      }
    }
  },
  components: {
    BaseModal,
    BaseButton,
    MapsLoader,
    MapsMarker
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapMutations('employee', ['toggleMainModal', 'togglePersonaliaModal', 'activateTableSkeleton', 'setData']),
    ...mapActions('employee', ['getEmployee', 'patchEmployeeAttendance', 'patchForceApproval']),
    closeModal() {
      this.manPowerInfo = true
      this.manPowerLoc = false
      this.TOGGLE_MODAL()
      this.toggleMainModal()
    },
    setPersonalia() {
      this.toggleMainModal()
      this.togglePersonaliaModal()
    },
    toggleAttendanceDisabled(employeeId, attendanceDisabled) {
      this.patchEmployeeAttendance({
        employee_number_formatted: employeeId,
        disabled: attendanceDisabled
      })
      .then(res => {
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.detail.attendance_disabled = attendanceDisabled === 0 ? 1 : 0
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    toggleForceApproval(employeeId, attendanceForce) {
      this.patchForceApproval({
        employee_number_formatted: employeeId,
        force: attendanceForce
      })
      .then(res => {
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.detail.force_approval_permission = attendanceForce === 0 ? 1 : 0
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    tabsToggle(item) {
      const tabsObject = this.tabs
      Object.keys(tabsObject).forEach(key => {
        if (key === item) {
          tabsObject[item] = true
        } else {
          tabsObject[key] = false
        }
      })
    }
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('employee', ['detail', 'tableParams']),
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter
      }
    },
    mapCenter() {
      return this.markers[0].position
    },
    apiKey() {
      return process.env.VUE_APP_MAPS_KEY
    },
    markers() {
      return [
        { 
          id: 'a', 
          position: { 
            lat: this.detail.man_power_latitude, 
            lng: this.detail.man_power_longitude 
          },
          icon: {
            url: require('../../assets/img/marker-man-power.png')
          }
        }
      ]
    },
    specificMarker() {
      return {
        position: {
          lat: this.detail.man_power_latitude,
          lng: this.detail.man_power_longitude,
        },
        radius: this.detail.man_power_max_radius
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.small-pic
  height: 50px
  width: 50px
  object-fit: cover
  border-radius: 50%
  
label
  color: #707070
  font-size: 13px

input
  padding: 0 5px
  border: 1px solid #707070
  border-radius: 5px

.modal

  .modal-dialog
    max-width: 500px !important

.modal-main

  &__top

    .top

      &__left
      
      &__right

        img
          height: 100px
          width: 100px
          object-fit: cover
          border-radius: 50%

  &__middle

    .title
    
      &:hover
        background-color: rgba(46, 61, 97, 0.1)
        cursor: pointer

      &__text

        &--active
          color: #0D2A70

      &__divider
        margin: 0

        &--active
          border-color: #0D2A70
</style>