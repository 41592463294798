<template>
  <div 
    id="composeMailModal" 
    tabindex="-1" 
    role="dialog" 
    :class="classState ? 'modal fade show' : 'modal fade'" 
    :style="styleState ? 'display: block;' : 'display: none;'" 
    :aria-modal="aria ? 'true' : 'false'" 
    :aria-hidden="aria ? 'false' : 'true'"
  >
    <div 
      role="document"
      class="modal-dialog modal-dialog-centered" 
      :class="smallModal ? 'modal-small' : bigModal ? 'modal-big' : ''" 
    >
      <div class="modal-content">
        <div class="modal-body">
          <svg
            v-if="closeButton"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x close"
            data-dismiss="modal"
            @click="$emit('close-modal')"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
          <div class="modal-main w-100 d-flex flex-column">
            <slot name="body"/>
          </div>
        </div>
        <div class="modal-footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    classState: Boolean,
    styleState: Boolean,
    aria: Boolean,
    smallModal: Boolean,
    bigModal: Boolean,
    closeButton: Boolean
  }
}
</script>

<style lang="sass" scoped>
.modal-dialog
  max-width: 750px !important

.modal-small
  max-width: 500px !important

.modal-big
  max-width: 90% !important
</style>
