<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader firstBreadcrumb="Pengumuman"/>
        <div 
          id="cancel-row"
          class="row app-notes layout-top-spacing" 
        >
          <div class="col-lg-12">
            <AnnouncementContent/>
            <AnnouncementModal/>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import AnnouncementContent from '@/components/announcement/AnnouncementContent'
import AnnouncementModal from '@/components/announcement/AnnouncementModal'

export default {
  name: "Announcement",
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    AnnouncementModal,
    AnnouncementContent
  },
  computed: {
    ...mapState('styling', ['modal'])
  }
};
</script>
